import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ClimateRequest } from "../model/ClimateModel";
import { CurrentState } from "../store/current-state";
import { CommonState } from "../store/reducer";
import { ServicesCommon } from "./services-common.service";
import { SurveyCategory, SurveyQuestion } from "../model/SurveyModel";
import { ENVIRONMENT_TOKEN } from "../../environment/token-variables";


@Injectable()
export class ClimateService {

  private readonly CLIMATE_URL = `/private/climatesmart/`;

  constructor(
    private currentState: CurrentState<CommonState>,
    protected http: HttpClient,
    private servicesCommon: ServicesCommon,
    @Inject(ENVIRONMENT_TOKEN) private readonly environment
  ) {
  }


  submitReport(request: ClimateRequest) {
    const url = this.CLIMATE_URL + "pasture";
    return this.http.post<any>(
      this.environment.backend + url,
      request,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }
}
