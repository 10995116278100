import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ConvertCLUToRegularBoundaryAction, LoadBoundariesFailedAction } from '..';
import { Boundary, MappingRequest } from '../../../model/MappingsModel';
import { MappingsService } from '../../../services/mappings.service';
import { AuthActionTypes } from '../../authentication/authentication.actions';
import { CommonFeBaseEffects } from '../../common-fe-base.effects';
import { CurrentState } from '../../current-state';
import { CommonState } from '../../reducer';
import * as boundariesActions from './boundaries.actions';
import {
  BoundaryActionTypes,
  CreateBoundariesFromParentFailedAction,
  CreateBoundariesFromParentSuccessAction,
  CreateBoundaryFailedAction,
  CreateBoundarySuccessAction,
  DeleteBoundaryFailedAction,
  DeleteBoundarySuccessAction,
  LoadUserBoundariesFailedAction,
  LoadUserBoundariesSuccessAction,
  MassUpdateBoundariesAction,
  MergePolygonsAction,
  MergePolygonsFailedAction,
  UnMergeBoundaryAction,
  UnMergeBoundaryFailedAction,
  UpdateBoundaryFailedAction,
  UpdateBoundarySuccessAction
} from './boundaries.actions';
import { LivestockActionTypes } from "../../livestock";
import { Animal } from '../../../model/LivestockModel';

@Injectable()
export class BoundaryEffects extends CommonFeBaseEffects {

  loadUserBoundaries: Observable<Action>;

  createBoundary: Observable<Action>;

  createBoundaries: Observable<Action>;

  updateBoundary: Observable<Action>;

  deleteBoundary: Observable<Action>;

  mergePolygons: Observable<Action>;

  unMergePolygons: Observable<Action>;

  loadBoundaries: Observable<Action>;

  convertCLUBoundaryToRegular: Observable<Action>;

  constructor(
    protected currentState: CurrentState<CommonState>,
    private mappingsService: MappingsService,
    private action$: Actions
  ) {
    super();

    this.loadUserBoundaries = createEffect(() => this.action$.pipe(
      ofType(
        BoundaryActionTypes.LOAD_USER_BOUNDARIES,
        BoundaryActionTypes.CREATE_BOUNDARY_SUCCESS,
        AuthActionTypes.LOGIN_SUCCESS,
        LivestockActionTypes.UPDATE_ANIMAL_SUCCESS),
      map((action: boundariesActions.LoadUserBoundariesAction) => action.payload),
      switchMap((action: any) => {
        let userId = action;
        if (!!(action as Boundary).id || (action as Animal[]).length) {
          userId = this.currentState.snapshot.user.id;
        }
        return this.mappingsService.getAllBoundaries(userId).pipe(
          map(response => {
            return new LoadUserBoundariesSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadUserBoundariesFailedAction(error));
          })
        );
      })
    ));

    this.loadBoundaries = createEffect(() => this.action$.pipe(
      ofType(BoundaryActionTypes.LOAD_BOUNDARIES),
      map((action: boundariesActions.LoadBoundariesAction) => action.payload),
      mergeMap(request => {
        return this.mappingsService.getBoundaries(request.boundaryIdsString, request.userId).pipe(
          map(response => {
            const ids = request.boundaryIdsString.split(',');
            return new MassUpdateBoundariesAction({event: null, newBoundaries: response, removedBoundaryIds: ids});
          }),
          catchError(error => {
            return of(new LoadBoundariesFailedAction(error));
          })
        );
      })
    ));

    this.createBoundary = createEffect(() => this.action$.pipe(
      ofType(BoundaryActionTypes.CREATE_BOUNDARY),
      map((action: boundariesActions.CreateBoundaryAction) => action.payload),
      mergeMap(({request}) => {
        return this.mappingsService.createNewBoundary(request).pipe(
          map(response => {
            return new CreateBoundarySuccessAction(response);
          }),
          catchError(error => {
            return of(new CreateBoundaryFailedAction(error));
          })
        );
      })
    ));

    this.convertCLUBoundaryToRegular = createEffect(() => this.action$.pipe(
      ofType(BoundaryActionTypes.CONVERT_CLU_TO_REGULAR_BOUNDARY),
      map((action: boundariesActions.ConvertCLUToRegularBoundaryAction) => action.payload),
      switchMap(boundary => {
        return this.mappingsService.convertCLUBoundaryToBoundary(boundary).pipe(
          map(response => {
            return new UpdateBoundarySuccessAction(response);
          }),
          catchError(error => {
            return of(new UpdateBoundaryFailedAction(error));
          })
        );
      })
    ));


    this.createBoundaries = createEffect(() => this.action$.pipe(
      ofType(BoundaryActionTypes.CREATE_BOUNDARIES_FROM_PARENT),
      map((action: boundariesActions.CreateBoundariesFromParentAction) => action.payload),
      mergeMap(({request}) => {
        return this.mappingsService.createNewBoundariesFromParent(request).pipe(
          map(response => {
            return new CreateBoundariesFromParentSuccessAction(response);
          }),
          catchError(error => {
            return of(new CreateBoundariesFromParentFailedAction(error));
          })
        );
      })
    ));

    this.updateBoundary = createEffect(() => this.action$.pipe(
      ofType(BoundaryActionTypes.UPDATE_BOUNDARY),
      map((action: boundariesActions.UpdateBoundaryAction) => action.payload),
      mergeMap((request) => {
        return this.mappingsService.updateBoundary(request).pipe(
          map(response => {
            return new UpdateBoundarySuccessAction(response);
          }),
          catchError(error => {
            return of(new UpdateBoundaryFailedAction(error));
          })
        );
      })
    ));

    this.deleteBoundary = createEffect(() => this.action$.pipe(
      ofType(BoundaryActionTypes.DELETE_BOUNDARY),
      map((action: boundariesActions.DeleteBoundaryAction) => action.payload),
      mergeMap((boundary) => {
        return this.mappingsService.deleteBoundary(boundary.id).pipe(
          map(response => {
            return new DeleteBoundarySuccessAction(boundary);
          }),
          catchError(error => {
            return of(new DeleteBoundaryFailedAction(error));
          })
        );
      })
    ));

    this.mergePolygons = createEffect(() => this.action$.pipe(ofType(BoundaryActionTypes.MERGE_POLYGONS),
      map((action: MergePolygonsAction) => {
        return action.payload;
      })
      , switchMap((request: MappingRequest[]) => {
        return this.mappingsService.mergePolygons(request).pipe(map(newBoundary => {
          const removed = request.filter(r => !!r.boundaryId).map(r => r.boundaryId);
          const payload = {
            event: 'merge-boundaries',
            newBoundaries: [newBoundary], removedBoundaryIds: removed
          };
          return new MassUpdateBoundariesAction(payload);
        }), catchError(error => {
          console.error('Error during mergePolygons: ');
          console.error(error);
          return of(new MergePolygonsFailedAction(error));
        }));
      })));

    this.unMergePolygons = createEffect(() => this.action$.pipe(ofType(BoundaryActionTypes.UN_MERGE_BOUNDARY),
      map((action: UnMergeBoundaryAction) => {
        return action.payload;
      })
      , switchMap((request: MappingRequest) => {
        return this.mappingsService.unMergeBoundary(request).pipe(map(newBoundaries => {
          const payload = {
            event: 'unmerge-boundaries', newBoundaries: newBoundaries, removedBoundaryIds: [request.boundaryId]
          };
          return new MassUpdateBoundariesAction(payload);
        }), catchError(error => {
          console.error('Error during mergePolygons: ');
          console.error(error);
          return of(new UnMergeBoundaryFailedAction(error));
        }));
      })));
  }
}

