import { Action } from '@ngrx/store';
import { type } from "../types";
import { SurveyCategory, SurveyQuestion } from "../../model/SurveyModel";


export const SurveyActionTypes = {
  SET_SURVEY_QUESTIONS: type('[app] [Survey] Set survey questions'),
  LOAD_SURVEY_QUESTION: type('[app] [Survey] Load survey questions'),
  LOAD_SURVEY_CATEGORIES: type('[app] [Survey] Load survey categories.'),
  SET_SURVEY_CATEGORIES: type('[app] [Survey] Set survey categories.'),
  SET_SELECTED_SURVEY_CATEGORY: type('[app] [Survey] Set selected survey category.'),
  SET_SURVEY_SELECTED_RANCH: type('[app] [Survey] Set selected ranch for survey.'),
};

export class SetSurveyQuestionAction implements Action {
  type = SurveyActionTypes.SET_SURVEY_QUESTIONS;

  constructor(public payload: SurveyQuestion[]) {
  }
}

export class LoadSurveyQuestionsAction implements Action {
  type = SurveyActionTypes.LOAD_SURVEY_QUESTION;

  constructor(public payload: { userId: number, ranchId: number }) {
  }
}

export class LoadSurveyCategoriesAction implements Action {
  type = SurveyActionTypes.LOAD_SURVEY_CATEGORIES;

  constructor() {
  }
}


export class SetSelectedSurveyCategoryAction implements Action {
  type = SurveyActionTypes.SET_SELECTED_SURVEY_CATEGORY;

  constructor(public payload: { categoryName: string }) {
  }
}

export class SetSurveyCategoriesAction implements Action {
  type = SurveyActionTypes.SET_SURVEY_CATEGORIES;

  constructor(public payload: SurveyCategory[]) {
  }
}

export class SetSurveySelectedRanch implements Action {
  type = SurveyActionTypes.SET_SURVEY_SELECTED_RANCH;

  constructor(public payload: number) {
  }
}

export declare type SurveyActionTypes =
  SetSurveyQuestionAction
  | LoadSurveyQuestionsAction
  | SetSelectedSurveyCategoryAction
  | SetSurveyCategoriesAction
  | LoadSurveyCategoriesAction
  | SetSurveySelectedRanch;
