import { Injectable, Injector } from '@angular/core';
import { environment } from "../../environments/environment";
import { CommonConstants, GoogleAnalyticsService } from 'common-lib';
import { Analytics, isSupported, logEvent, setUserId } from '@angular/fire/analytics';
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsPortalService implements GoogleAnalyticsService {
  private an: Analytics;

  constructor(private injector: Injector, private router: Router) {
    isSupported().then((r) => {
      if (r) {
        this.an = this.injector.get(Analytics);
        this.listenForUrlChanges();
        console.log('test from INSIDE FB analytics service: ', this.an);
      }
    });
  }


  public screenEntered(pageName: string) {
    if (this.isEnabledAndInitialised()) {
      this.setScreenName(pageName);
    }
  }

  public setUserId(userId) {
    if (this.isEnabledAndInitialised()) {
      setUserId(this.an, userId)
      const ev = {
        name: 'login',
        params: {
          userId: userId
        }
      };
      logEvent(this.an, 'login', ev)
    }
  }


  public userRegistered(userId) {
    if (this.isEnabledAndInitialised()) {
      const ev = {
        name: 'sign_up',
        params: {
          userId: userId
        }
      }
      logEvent(this.an, 'sign_up', ev)
    }
  }

  public trackUserInteraction(action: string, label: string) {
    if (this.isEnabledAndInitialised()) {
      const params = {action: action, label: label};
      logEvent(this.an, CommonConstants.GA.CATEGORY.USER_INTERACTION, params)
    }
  }

  public trackServiceCall(action: string, label: string) {
    if (this.isEnabledAndInitialised()) {
      const params = {action: action, label: label};
      logEvent(this.an, CommonConstants.GA.CATEGORY.SERVICE_CALL, params)
    }
  }

  private isEnabledAndInitialised(): boolean {
    return (environment.analyticsEnabled && !!this.an);
  }

  private setScreenName(screenName) {
    let ev = {
      firebase_screen: screenName,
      firebase_screen_class: screenName
    }
    logEvent(this.an, 'screen_view', ev);
  }

  private listenForUrlChanges() {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((e: RouterEvent) => {
      this.setScreenName(e.url);
    });
  }
}
