
import { PortalAppState } from './reducer';
import { environment } from '../../environments/environment';
import { CommonFeBaseEffects, CurrentState, PageItem, CommonConstants } from "common-lib";

export class PortalBaseEffects extends CommonFeBaseEffects {

  constructor(protected currentState: CurrentState<PortalAppState>) {
    super();
  }

  protected filterEntryPages(userDetail) {
    const isAblaToManageRanch = this.isAbleToManageRanch(userDetail);
    let allEntryPages = <Array<PageItem>>[
      PageItem.ofComponent('PAGE_ITEM.HOME', 'home', false, null, null, false),
      PageItem.ofComponent('PAGE_ITEM.PRF', 'prf', false, 'assets/imgs/prf-module.jpg', CommonConstants.SYSTEM_FUNCTIONS.prf_access),
      PageItem.ofComponent('PAGE_ITEM.ADV_PRF', 'advanced-prf', false, 'assets/imgs/advanced_prf_logo.png', CommonConstants.SYSTEM_FUNCTIONS.adv_prf_access),
      
      // PageItem.ofComponent('PAGE_ITEM.ANNUAL_FORAGE', 'annual-forage', false, 'assets/imgs/annual-forage-module.jpg', CommonConstants.SYSTEM_FUNCTIONS.annual_forage_access),
      PageItem.ofComponent('PAGE_ITEM.LRP', 'lrp', false, 'assets/imgs/lrp_logo.png', CommonConstants.SYSTEM_FUNCTIONS.lrp_access),
      PageItem.ofComponent('PAGE_ITEM.LIVESTOCK', 'livestock', false, 'assets/imgs/livestock_logo.jpg', CommonConstants.SYSTEM_FUNCTIONS.herd_management_access),
      PageItem.ofComponent('PAGE_ITEM.TASKS', 'tasks', false, 'assets/imgs/tasks_logo.jpg', CommonConstants.SYSTEM_FUNCTIONS.tasks_access),
      PageItem.ofComponent('PAGE_ITEM.FINANCE', 'finance', false, 'assets/imgs/finance_logo.jpg', CommonConstants.SYSTEM_FUNCTIONS.finance_management_access),
      // PageItem.ofComponent('PAGE_ITEM.LEASE', 'home', false, 'assets/imgs/lease_logo.jpg', CommonConstants.SYSTEM_FUNCTIONS.lease_management_access),
      // PageItem.ofComponent('PAGE_ITEM.REPORTING', 'reporting', false, 'assets/imgs/reporting.png', CommonConstants.SYSTEM_FUNCTIONS.reporting_access),
      // PageItem.ofComponent('PAGE_ITEM.GAUGE', 'gaugedetails', false, 'assets/imgs/weather_station_logo.jpg', CommonConstants.SYSTEM_FUNCTIONS.rain_gauge_access),
      // PageItem.ofComponent('PAGE_ITEM.ASSET_MONITORING', 'asset-monitoring', false, 'assets/imgs/asset_monitoring_logo.jpg', CommonConstants.SYSTEM_FUNCTIONS.water_monitoring_access),
      PageItem.ofComponent('PAGE_ITEM.MAPS', 'mapping', false, 'assets/imgs/mapping_logo.jpg', CommonConstants.SYSTEM_FUNCTIONS.mapping_access),
      // PageItem.ofComponent('PAGE_ITEM.WATER_MONITORING', 'water-monitoring', false, 'assets/imgs/water_monitoring_logo.jpg', CommonConstants.SYSTEM_FUNCTIONS.water_monitoring_access),
      PageItem.ofComponent('PAGE_ITEM.ADMIN', 'admin', false, 'assets/imgs/admin_logo.jpg', CommonConstants.SYSTEM_FUNCTIONS.admin_access, false),
      PageItem.ofComponent('PAGE_ITEM.MANAGE_RANCH', 'ranches', false, 'assets/imgs/admin_logo.jpg', CommonConstants.SYSTEM_FUNCTIONS.general_manager, false, isAblaToManageRanch),
      PageItem.ofComponent('PAGE_ITEM.SETTINGS', 'settings', false, 'assets/imgs/admin_logo.jpg', CommonConstants.SYSTEM_FUNCTIONS.settings_access, false),
      PageItem.ofComponent('PAGE_ITEM.SUPPORT', 'support', false, 'assets/imgs/admin_logo.jpg', CommonConstants.SYSTEM_FUNCTIONS.settings_access, false),

    ];
    let enabledEntryPages: PageItem[] = [];
    allEntryPages.forEach((entryPage: PageItem) => {
      const requiredSystemfunction = entryPage.requiredSystemfunction;
      const displayInMenu = entryPage.displayInMenu;
      if (!requiredSystemfunction || displayInMenu) {
        enabledEntryPages.push(entryPage);
      } else if (userDetail && userDetail.aclsOfUser) {
        userDetail.aclsOfUser.forEach(aclOfUser => {
          let sysFunctionOfUser = aclOfUser.sysfunctionName;
          let actionOfUser = aclOfUser.actionName;
          if (actionOfUser.toLowerCase() == 'access'
            && requiredSystemfunction.toLowerCase() == sysFunctionOfUser.toLowerCase()
            && aclOfUser.active) {
            let indexOf = enabledEntryPages.indexOf(entryPage);
            if (indexOf < 0) {
              enabledEntryPages.push(entryPage);
            }
          }
        });
      }
    });
    if (!environment.production) {
      const pageItem = PageItem.ofComponent('DEV', 'dev', true, 'assets/imgs/admin_logo.jpg', null, false)
      enabledEntryPages.push(pageItem);
    }
    return enabledEntryPages;
  }

  isAbleToManageRanch(userDetail) {
    if (!userDetail) {
      return false;
    }
    console.log('isAbleToManageRanch');
    console.log(userDetail);
    const hasRanch = !!userDetail && !!userDetail.ranchesOfUser && userDetail.ranchesOfUser.length > 0;
    const aclsOfUser = userDetail.aclsOfUser;
    const filteredAcls = aclsOfUser.filter(acl => {
      return acl.actionName.toLowerCase() == 'access' && acl.sysfunctionName.toLowerCase() == "general_manager"
    });
    const isGeneralManager = filteredAcls.length > 0;
    console.log('hasRanch: ' + hasRanch + ' isGeneralManager: ' + isGeneralManager);
    return isGeneralManager || hasRanch;
  }
}
