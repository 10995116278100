import { ActionReducerMap, combineReducers } from '@ngrx/store';
import * as fromGauges from './gauges/gauges.reducer';
import * as fromErrors from './errors/errors.reducer';
import * as fromUser from './user/user.reducer';
import * as fromAdmin from './admin/admin.reducer';
import * as fromRanch from './ranches/ranches.reducer';
import * as fromTasks from './tasks/tasks.reducer';
import * as fromPRF from './prf/prf.reducer';
import * as fromEquipments from './equipments/equipments.reducer';
import * as  fromImages from './images/images.reducer';
import * as  fromMarkers from './mappings/markers/marker.reducer';
import * as  fromBoundaries from './mappings/boundaries/boundaries.reducer';
import * as  fromOfflineMaps from './mappings/offline-maps/offline-maps.reducer';
import * as  fromProperties from './mappings/properties/map-properties.reducer';
import * as  fromMapPreselection from './mappings/preselection/map-preselection.reducer';
import * as fromLivestock from './livestock/livestock.reducer';
import * as fromLRP from './lrp/lrp.reducer'
import * as fromAttachment from './attachments/attachments.reducer';
import * as fromNotification from './notifications/notifications.reducer';
import * as fromGpsEarTags from './gpseartags/gpseartags.reducer';
import * as fromFinance from './finance/finance.reducer';
import * as fromSurvey from './survey/survey.reducer';
import * as fromDev from './dev/dev.reducer';
import * as fromTutorial from './tutorial/tutorial.reducer';


export interface MappingState {
  boundaries: fromBoundaries.BoundariesState;
  markers: fromMarkers.MarkerState;
  offlineMaps: fromOfflineMaps.OfflineMapsState;
  preselection: fromMapPreselection.MapPreselectionState;
  properties: fromProperties.MapPropertiesState
};


export interface CommonState {
  admin: fromAdmin.AdminState;
  tutorials: fromTutorial.TutorialState,
  attachments: fromAttachment.AttachmentsState;
  survey: fromSurvey.SurveyState;
  dev: fromDev.DevState,
  errors: fromErrors.ErrorsState;
  gauges: fromGauges.GaugesState;
  user: fromUser.UserState;
  ranches: fromRanch.RanchesState;
  tasks: fromTasks.TasksState;
  prf: fromPRF.PRFState;
  equipments: fromEquipments.EquipmentState;
  images: fromImages.ImagesState;
  mappings: MappingState;
  livestock: fromLivestock.LivestockState;
  lrp: fromLRP.LRPState
  notifications: fromNotification.NotificationsState,
  gpsEarTags: fromGpsEarTags.GPSEarTagState,
  finance: fromFinance.FinanceState
}


export const reducers: ActionReducerMap<CommonState> = {
  admin: fromAdmin.adminReducer,
  tutorials: fromTutorial.reducer,
  attachments: fromAttachment.attachmentsReducer,
  survey: fromSurvey.reducer,
  dev: fromDev.reducer,
  errors: fromErrors.reducer,
  gauges: fromGauges.reducer,
  user: fromUser.userReducer,
  ranches: fromRanch.ranchesReducer,
  tasks: fromTasks.reducer,
  prf: fromPRF.reducer,
  equipments: fromEquipments.reducer,
  images: fromImages.reducer,
  mappings: combineReducers<MappingState>({
    markers: fromMarkers.markerReducer,
    boundaries: fromBoundaries.boundariesReducer,
    offlineMaps: fromOfflineMaps.offlineMapsReducer,
    preselection: fromMapPreselection.mapPreselectionReducer,
    properties: fromProperties.mapPropertiesReducer
  }),
  livestock: fromLivestock.reducer,
  lrp: fromLRP.reducer,
  notifications: fromNotification.reducer,
  gpsEarTags: fromGpsEarTags.reducer,
  finance: fromFinance.reducer
};
