import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageItem } from "common-lib";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  @Input('availableEntryPages') availableEntryPages: PageItem[];
  @Input('userLoggedIn') userLoggedIn: boolean;
  @Output('onLogoutClicked') onLogoutClicked = new EventEmitter();

  constructor(public translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    console.log(this.availableEntryPages)
  }

  logout() {
    this.onLogoutClicked.emit();
  }
}
