export const CommonConstants = {

  GA: {
    CATEGORY: {
      SERVICE_CALL: 'Service call',
      USER_INTERACTION: 'User interaction',
    },
    LABEL: {
      GAUGE: 'Gauge',
      USER_CRUD: 'User CRUD',
      PRF: 'PRF',
      MENU: 'Menu',
      SUPPORT: 'Support',
      RANCHES: 'Ranches',
      TASKS: 'Tasks',
      OFFLINE: 'Offline',
      LIVESTOCK: 'Livestock'
    }
  },
  // HTTP_TIMEOUT_MS: 90000,
  defaultLanguage: 'en',
  emailRegexp: new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"),
  phoneRegexp: new RegExp("^[ 0-9-+()]*$"),
  dollarCurrencyRegexp: new RegExp("/\d(?=(\d{3})+\.)/g"),
  DTN_SERVICE_ERROR_KEYS: {
    businessError: 'ERROR.DTN_SERVICE_BUSINESS_ERROR',
    httpError: 'ERROR.DTN_SERVICE_ERROR',
  },

  GAUGE_CONSTANTS: {
    dailyObservationDataType: 'DailyObservationExtended',
    hourlyObservationDataType: 'HourlyObservation',
    currentWeatherDataType: 'HourlyLatestObservation',
    quickLookTodayObservationType: 'HourlyObservation',
    quickLookMonthObservationType: 'DailyObservationExtended',
    quickLookYearObservationType: 'DailyObservationExtended',
    quickLook7DaysObservationType: 'DailyObservationExtended',
    dateFormat: 'YYYY-MM-DD HH:mm',
    fullMonthDisplayFormat: 'MMMM YYYY',
    monthDayDisplayFormat: 'MM/DD',
    fullMonthDayYearDisplayFormat: 'MMMM DD, YYYY',
    timeOnly: 'h:mm A',
    directionDegreeMapping: new Map([
      ["N", ['0-30']],
      ["NE", ["31-60"]],
      ["E", ["61-120"]],
      ["SE", ["121-150"]],
      ["S", ["151-210"]],
      ["SW", ["211-240"]],
      ["W", ["241-300"]],
      ["NW", ["301-330"]]
    ])
  },

  GAUGE_MAPS: {

    markerStyle: {
      image: {
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        scale: 0.2,
        opacity: 1,
        src: 'assets/imgs/marker_black.png',
      }
    },
    selectedMarkerStyle: {
      image: /** @type {olx.style.IconOptions} */ {
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        opacity: 0.75,
        scale: 0.1,
        src: 'assets/imgs/marker_blue.png',
      }
    }
  },

  MAPPING: {
    WORLD_IMAGE_URL: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/',
    WORLD_BOUNDARIES_PLACES: 'https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/',
    WORLD_TRANSPORTATION: 'https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Transportation/MapServer/'
  },

  PARCEL: {
    data_server: 'https://map.myaginnovations.com/cgi-bin/mapserv',
    PARCEL_MAX_USAGE: 10,
    PARCEL_TIER_2_USAGE_SYSTEM_FUNCTION: 'tier_2_parcel_layer_access',
    PARCEL_TIER_3_USAGE_SYSTEM_FUNCTION: 'tier_3_parcel_layer_access',
  },

  PRF_CONSTANTS: {
    interValKeys: [
      {id: 0, label: "LABEL.JANFEB"},
      {id: 1, label: "LABEL.FEBMAR"},
      {id: 2, label: "LABEL.MARAPR"},
      {id: 3, label: "LABEL.APRMAY"},
      {id: 4, label: "LABEL.MAYJUN"},
      {id: 5, label: "LABEL.JUNJUL"},
      {id: 6, label: "LABEL.JULAUG"},
      {id: 7, label: "LABEL.AUGSEP"},
      {id: 8, label: "LABEL.SEPOCT"},
      {id: 9, label: "LABEL.OCTNOV"},
      {id: 10, label: "LABEL.NOVDEC"},
    ],
    allInterval: {
      id: 11, label: "LABEL.ALL_INTERVAL"
    }
  },

  ANNUAL_FORAGE_CONSTANTS: {
    interValKeys: [
      {id: 0, label: "LABEL.JANFEB"},
      {id: 1, label: "LABEL.FEBMAR"},
      {id: 2, label: "LABEL.MARAPR"},
      {id: 3, label: "LABEL.APRMAY"},
      {id: 4, label: "LABEL.MAYJUN"},
      {id: 5, label: "LABEL.JUNJUL"},
      {id: 6, label: "LABEL.JULAUG"},
      {id: 7, label: "LABEL.AUGSEP"},
      {id: 8, label: "LABEL.SEPOCT"},
      {id: 9, label: "LABEL.OCTNOV"},
      {id: 10, label: "LABEL.NOVDEC"},
      {id: 11, label: "LABEL.DECJAN"}
    ],
    allInterval: {
      id: 12, label: "LABEL.ALL_INTERVAL"
    },
    growingSeasonIntervals: [
      [8, 9, 10, 11, 0, 1],
      [9, 10, 11, 0, 1, 2],
      [10, 11, 0, 1, 2, 3],
      [11, 0, 1, 2, 3, 4],
      [0, 1, 2, 3, 4, 5],
      [1, 2, 3, 4, 5, 6],
      [2, 3, 4, 5, 6, 7],
      [3, 4, 5, 6, 7, 8],
      [4, 5, 6, 7, 8, 9],
      [5, 6, 7, 8, 9, 10],
      [6, 7, 8, 9, 10, 11],
      [7, 8, 9, 10, 11, 0],
    ],
    ard: [
      "Sep 5",
      "Oct 5",
      "Nov 5",
      "Dec 5",
      "Jan 5",
      "Feb 5",
      "Mar 5",
      "Apr 5",
      "May 5",
      "Jun 5",
      "July 5",
      "Aug 5"
    ]
  },

  SYSTEM_FUNCTIONS: {
    herd_management_access: 'herd_management_access',
    prf_access: 'prf_access',
    adv_prf_access: 'adv_prf_access',
    users_admin_access: 'users_admin_access',
    annual_forage_access: 'annual_forage_access',
    admin_access: 'admin_access',
    rain_gauge_access: 'rain_gauges_access',
    mapping_access: 'mapping_access',
    recreation_access: 'recreation_access',
    tasks_access: 'tasks_access',
    weather_access: 'weather_access',
    marketplace_access: 'marketplace_access',
    user_management_access: 'user_management_access',
    settings_access: 'settings_access',
    water_monitoring_access: 'water_monitoring_access',
    general_manager: 'general_manager',
    reporting_access: 'reporting_access',
    lease_management_access: 'lease_management_access',
    finance_management_access: 'finance_management_access',
    climate_smart_access: 'climate_smart_access',
    lrp_access: 'lrp_access',
    lrp_daily_report_access: 'lrp_daily_report_access',
    lrp_endorsement_requests_access:'lrp_endorsement_requests_access',
    prf_association: 'prf_association',
    aap_association: 'aap_association',
    lrp_association: 'lrp_association',
    add_prf_roles: 'add_prf_roles',
    admin_area_create_user: 'admin_area_create_user'
  },
  TASK_CONSTANTS: {
    defaultOrdering: {
      title: 'Date ' + getUpArrow(),
      id: 'due_date_asc',
    },
    orderings: [
      {
        title: 'Date ' + getUpArrow(),
        id: 'due_date_asc',
      },
      {
        title: 'Date ' + getDownArrow(),
        id: 'due_date_desc',

      },
      {
        title: 'Title ' + getUpArrow(),
        id: 'title_asc',
      },
      {
        title: 'Title ' + getDownArrow(),
        id: 'title_desc',
      }
    ]
  },
  APP_UPDATE_CHECK_URL_SUFFIX: '/assets/mobile/version.json',
  SERVICE_ERRORS: {
    CORDOVA_NOT_AVAILABLE_ERROR: 'cordova_not_available',
    STORAGE_NATIVE_WRITE_FAILED: 1,
    STORAGE_ITEM_NOT_FOUND: 2,
    STORAGE_NULL_REFERENCE: 3,
    STORAGE_UNDEFINED_TYPE: 4,
    STORAGE_JSON_ERROR: 5,
    STORAGE_WRONG_PARAMETER: 6,
    AUTH: {
      WRONG_CREDS: -1,
      NO_CONNECTION: 0,
    }
  }

};

function getDownArrow() {
  return '&#8595';
}

function getUpArrow() {
  return '&#8593;';

}
