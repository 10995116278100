<mat-toolbar>

  <button type="button" mat-icon-button (click)="sideMenuToggle()">
    <mat-icon aria-hidden="false" aria-label="Side menu toggle" fontIcon="menu"></mat-icon>
  </button>

  <span class="menu-spacer"></span>

  <div class="logo" (click)="toHome()">
    <img src="assets/imgs/skull.png">
    <p>PASTURE PRO</p>
  </div>

  <span class="menu-spacer"></span>

  <!-- <button type="button" mat-icon-button (click)="logout()">
    <mat-icon aria-hidden="false" aria-label="Logout" fontIcon="logout"></mat-icon>
  </button> -->
  
</mat-toolbar>
