import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from "@angular/common/http";
import { DevState } from 'common-lib';


export const environment = {
  name: 'Test',
  backend: 'https://test.pastureproapp.com:20043/pasturePro/api',
  prf_backend: 'https://test.pastureproapp.com:20043/pastureProPrf/api',
  auth_server: 'https://test.pastureproapp.com/pasturePro/api',
  files_root: 'https://test.pastureproapp.com/',
  parcel_data_server: 'https://map.myaginnovations.com/cgi-bin/mapserv',
  rainfall_map_server: 'https://map.myaginnovations.com/cgi-bin/mapserv',
  production: false,
  showEnvInfo: true,
  analyticsEnabled: true,
  firebaseConfig: {
    apiKey: "AIzaSyBqJbeL9ki9-A07lPez_d9xCShe8gdzYYQ",
    authDomain: "test-portal-73bb4.firebaseapp.com",
    projectId: "test-portal-73bb4",
    storageBucket: "test-portal-73bb4.appspot.com",
    messagingSenderId: "791893151506",
    appId: "1:791893151506:web:08aaa6845b1cf51bb52dcb",
    measurementId: "G-4PMWGYDSTW"
  }
};

export const devSettings: DevState = {
  autoLogin: false,
  autoLoginUsername: '',
  autoLoginPassword: '',
  mockFavoriteStations: false,
  mockFirstUsage: false,
  showErrorCause: false,
  mockTexasLocation: false,
  forcedParcelTierBuy: false,
  unlimitedParcelUsage: false,
  hasInternetConnection: true,
  withStorageCalculation: false,
  positionTracking: false,
  quickEnableInternet: false,
  showId: false,
  disableHXGNLayer: false
};

// export const devSettings: DevState = {
//   autoLogin: false,
//   autoLoginUsername: '',
//   autoLoginPassword: '',
//   mockFavoriteStations: false,
//   mockFirstUsage: false,
//   showErrorCause: false
// };
export const PACKAGE_NAME = 'com.myaginnovations.pasturepro';
export const GOOGLE_ANALYTICS_KEY = 'UA-133248593-1';
export const ENV_INFO = 'TEST\n' + environment.backend;

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
