import { ActionWithPayload } from "../types";
import { SurveyActionTypes } from "./survey.actions";
import { SurveyCategory, SurveyQuestion } from "../../model/SurveyModel";


export interface SurveyState {
  questions: SurveyQuestion[];
  surveyCategories: SurveyCategory[];
  selectedSurveyCategory: string;
  selectedRanch: number;
}


export const surveyInitialState: SurveyState = {
  questions: [],
  surveyCategories: [],
  selectedSurveyCategory: null,
  selectedRanch: null
};


export function reducer(state = surveyInitialState, action: SurveyActionTypes): SurveyState {
  switch (action.type) {
    case SurveyActionTypes.SET_SURVEY_QUESTIONS:
      return {
        ...state,
        questions: (action as ActionWithPayload<any>).payload,
      };
    case SurveyActionTypes.SET_SELECTED_SURVEY_CATEGORY:
      return {
        ...state,
        selectedSurveyCategory: (action as ActionWithPayload<any>).payload.categoryName,
      };
    case SurveyActionTypes.SET_SURVEY_CATEGORIES:
      return {
        ...state,
        surveyCategories: (action as ActionWithPayload<any>).payload,
      };
    case SurveyActionTypes.SET_SURVEY_SELECTED_RANCH:
      return {
        ...state,
        selectedRanch: (action as ActionWithPayload<any>).payload,
      };
    default:
      return state;
  }
}
