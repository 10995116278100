import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { UserService } from "../../services/user.service";
import { CommonFeBaseEffects } from "../common-fe-base.effects";
import { RanchService } from "../../services/ranch.service";
import { CurrentState } from "../current-state";
import { CommonState } from "../reducer";
import {
  AdminActionTypes,
  CreateRoleAction,
  CreateRoleSuccessAction,
  DeleteRoleAction,
  DeleteRoleSuccessAction,
  LoadActionsSuccessAction,
  LoadAssetsOfUserActionSuccess,
  LoadFilteredUsersSuccessAction,
  LoadInterruptionMessagesSuccessAction,
  LoadRanchesOfSelectedUserActionSuccess,
  LoadRolesSuccessAction,
  LoadSysfunctionsSuccessAction,
  LoadUserNextPageAction,
  LoadUserNextPageSuccessAction,
  LoadUsersOfSelectedRanchesForAdminSuccessAction,
  SelectAdminRanchAction,
  SelectRoleAction,
  SelectRoleEnrichedAction,
  SelectUserAction,
  SelectUserEnrichedAction,
  SelectUserLoadedAction,
  SetUserSearchString,
  SuperDuperLoginAction,
  SuperDuperLoginSuccessAction
} from "./admin.actions";
import { MessageEntity, UserDetail } from "../../model";
import { RanchesActionTypes } from "../ranches/ranches.actions";
import { AdminService } from "../../services/admin.service";


@Injectable()
export class AdminEffects extends CommonFeBaseEffects {

  loadRoles: Observable<Action>;

  loadUsersNextPage: Observable<Action>;

  loadFilteredUsers: Observable<Action>;

  // loadUsersIncrementally: Observable<Action>;

  loadActions: Observable<Action>;

  loadSysfunctions: Observable<Action>;

  userSelected: Observable<Action>;

  loadRanchesOfUser: Observable<Action>;

  loadUsersOfRanch: Observable<Action>;

  clearUsersOfRanch: Observable<Action>;

  userSelectedLoaded: Observable<Action>;

  loadAssetsOfUser: Observable<Action>;

  enrichSelectedRole: Observable<Action>;

  loadRoleById: Observable<Action>;

  createNewRole: Observable<Action>;

  // createNewUser: Observable<Action>;

  deleteRole: Observable<Action>;

  loadMostRecentMessage: Observable<Action>;

  superLogin: Observable<Action>;

  constructor(private userService: UserService,
              private ranchService: RanchService,
              private actions: Actions,
              private adminService: AdminService,
              protected currentState: CurrentState<CommonState>) {
    super();

    this.loadUsersNextPage = createEffect(() => this.actions.pipe(
      ofType(AdminActionTypes.LOAD_USERS_NEXT_PAGE),
      map((action: LoadUserNextPageAction) => action.payload),
      filter(() => currentState.snapshot.admin.users.hasNextPage),
      switchMap((pagingObject: { pageSize: number, pageNumber: number }) => {
        return this.getUsers({
          pageSize: pagingObject ? pagingObject.pageSize : currentState.snapshot.admin.users.pageSize,
          pageNumber: pagingObject ? pagingObject.pageNumber : currentState.snapshot.admin.users.currentPage
        }).pipe(map((response) => {
            return new LoadUserNextPageSuccessAction(response as UserDetail[]);
          }),
          catchError(err => this.handleHttpError(err)));
    })));

    this.loadFilteredUsers = createEffect(() => this.actions.pipe(
      ofType(AdminActionTypes.SET_USER_SEARCH_STRING),
      map((action: SetUserSearchString) => action.payload),
      filter(s => {
        return !s || s.length > 1;
      }),
      switchMap((searchString: string) => {
          if (!!searchString && searchString.length > 1) {
            return this.adminService.getFilteredUsers(searchString).pipe(
              map(users => new LoadFilteredUsersSuccessAction(users)),
              catchError(err => this.handleHttpError(err))
            )
          } else {
            return of(new LoadUserNextPageAction());
          }
        }
    )));

    this.userSelected = createEffect(() => this.actions.pipe(ofType(AdminActionTypes.SELECT_USER)
      , map((action: SelectUserAction) => action.payload)
      , switchMap((userId: number) => this.adminService.getUser(userId)
        .pipe(map((user: any) => {
            // const enrichedUser = this.userService.enrichUserEntity(user);
            return new SelectUserLoadedAction(user)
          })
          , catchError(err => this.handleHttpError(err)))
    )));

    this.loadRanchesOfUser = createEffect(() => this.actions.pipe(ofType(AdminActionTypes.SELECT_USER_LOADED)
      , map((action: SelectUserLoadedAction) => action.payload)
      , switchMap((user: any) => this.ranchService.loadRanchesForUser({userId: user.id})
        .pipe(map((ranches: any) => {
            return new LoadRanchesOfSelectedUserActionSuccess(ranches)
          })
          , catchError(err => this.handleHttpError(err)))
    )));

    this.loadAssetsOfUser = createEffect(() => this.actions.pipe(ofType(AdminActionTypes.SELECT_USER_LOADED)
      , map((action: SelectUserLoadedAction) => action.payload)
      , switchMap((user: any) => this.adminService.loadAssetsForUser(user.id + '')
        .pipe(map((assets: any) => {
            return new LoadAssetsOfUserActionSuccess(assets)
          })
          , catchError(err => this.handleHttpError(err)))
    )));

    this.loadUsersOfRanch = createEffect(() => this.actions.pipe(ofType(AdminActionTypes.SELECT_RANCH, RanchesActionTypes.ASSIGN_NEW_USER_TO_RANCH_SUCCESS)
      , map((action: SelectAdminRanchAction) => action.payload)
      , filter(payload => (!!payload && payload.length > 0 && !!payload[0]))
      , switchMap((ranchId: any) => this.ranchService.loadUsersForRanch(ranchId)
        .pipe(map((users: any) => {
            return new LoadUsersOfSelectedRanchesForAdminSuccessAction(users)
          })
          , catchError(err => this.handleHttpError(err)))
    )));

    this.clearUsersOfRanch = createEffect(() => this.actions.pipe(ofType(AdminActionTypes.SELECT_RANCH)
      , map((action: SelectAdminRanchAction) => action.payload)
      , filter(payload => !payload)
      , switchMap(() => {
        return new Observable<Action>((observer) => {
          observer.next(new LoadUsersOfSelectedRanchesForAdminSuccessAction([]));
          observer.complete();
        });
    })));

    this.loadRoleById = createEffect(() => this.actions.pipe(ofType(AdminActionTypes.SELECT_ROLE)
      , map((action: SelectRoleAction) => action.payload)
      , switchMap((roleId: number) => this.adminService.loadRoleById(roleId)
        .pipe(map((role: any) => {
            // const enrichedUser = this.userService.enrichUserEntity(loginResponse);
            const enrichedRole = this.userService.mapACLsOfRoles([role]);
            return new SelectRoleEnrichedAction(enrichedRole);
          })
          , catchError(err => this.handleHttpError(err)))
    )));

    this.loadMostRecentMessage = createEffect(() => this.actions.pipe(ofType(AdminActionTypes.LOAD_INTERRUPTION_MESSAGES)
      , switchMap(() => this.adminService.loadMostRecentMessage()
        .pipe(map((message: MessageEntity) => {
            return new LoadInterruptionMessagesSuccessAction(message);
          })
          , catchError(err => this.handleHttpError(err)))
    )));

    this.enrichSelectedRole = createEffect(() => this.actions.pipe(ofType(AdminActionTypes.SELECT_ROLE_SUCCESS)
      , map((action: SelectRoleAction) => action.payload)
      , map(role => {
        const enrichedRole = this.userService.mapACLsOfRoles([role]);
        return new SelectRoleEnrichedAction(enrichedRole);
    })));

    this.userSelectedLoaded = createEffect(() => this.actions.pipe(ofType(AdminActionTypes.SELECT_USER_LOADED)
      , map((action: SelectUserLoadedAction) => action.payload)
      , map(user => {
        const enrichedUser = this.enrichUser(user);
        return new SelectUserEnrichedAction(enrichedUser);
    })));

    this.loadRoles = createEffect(() => this.actions.pipe(ofType(AdminActionTypes.CREATE_NEW_ROLE_SUCCESS, AdminActionTypes.DELETE_ROLE_SUCCESS, AdminActionTypes.LOAD_ROLES)
      , switchMap(loadAction => {
        return this.getAllRoles()
          .pipe(map((loginResponse: Object) => {
              return new LoadRolesSuccessAction(loginResponse)
            })
            , catchError(err => this.handleHttpError(err)))
    })));

    this.createNewRole = createEffect(() => this.actions.pipe(ofType(AdminActionTypes.CREATE_NEW_ROLE)
      , map((action: CreateRoleAction) => action.payload)
      , switchMap((newRoleRequest: { title, name, description }) => this.adminService.createNewRole(newRoleRequest)
        .pipe(map((role: any) => {
            return new CreateRoleSuccessAction(role);
          })
          , catchError(err => this.handleHttpError(err))))
    ));

    this.deleteRole = createEffect(() => this.actions.pipe(ofType(AdminActionTypes.DELETE_ROLE)
      , map((action: DeleteRoleAction) => action.payload)
      , switchMap((roleId: { name, description }) => this.adminService.deleteRole(roleId)
        .pipe(map((role: any) => {
            return new DeleteRoleSuccessAction(role);
          })
          , catchError(err => this.handleHttpError(err))))
    ));

    this.loadActions = createEffect(() => this.actions.pipe(ofType(AdminActionTypes.LOAD_ACTIONS)
      , switchMap(loadAction => {
        return this.getAllActions()
          .pipe(map((loginResponse: Object) => {
              return new LoadActionsSuccessAction(loginResponse)
            })
            , catchError(err => this.handleHttpError(err)))
    })));

    this.loadSysfunctions = createEffect(() => this.actions.pipe(ofType(AdminActionTypes.LOAD_SYSFUNCTIONS)
      , switchMap(loadAction => {
        return this.getAllSysfunctions()
          .pipe(map((loginResponse: Object) => {
              return new LoadSysfunctionsSuccessAction(loginResponse)
            })
            , catchError(err => this.handleHttpError(err)))
    })));

    this.superLogin = createEffect(() => this.actions.pipe(ofType(AdminActionTypes.SUPER_DUPER_LOGIN)
      , map((action: SuperDuperLoginAction) => action.payload)
      , switchMap((userId: string | number) => this.adminService.superLogin(userId)
        .pipe(map((response: any) => {
            return new SuperDuperLoginSuccessAction(response);
          })
          , catchError(err => this.handleHttpError(err))))
    ));
  }


  getUsers(pagingObject) {
    return this.adminService.loadAllUsers(pagingObject);
  }

  enrichUser(user) {
    const enrichUserEntity = this.userService.enrichUserEntity(user);
    return enrichUserEntity;
  }

  getAllRoles() {
    return this.adminService.loadAllRoles();
  }

  getAllActions() {
    return this.adminService.loadAllActions();
  }

  getAllSysfunctions() {
    return this.adminService.loadAllSysfunctions();
  }


  addRoleToUser(request) {
    return this.adminService.addRoleToUser(request);
  }
}


