import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Cesium from 'cesium';
import { Ion } from 'cesium';

if (environment.production) {
  enableProdMode();
}
(window as Record<string, any>)['CESIUM_BASE_URL'] = '/assets/cesium/';
(window as Record<string, any>)['Cesium'] = Cesium;
Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1MjJkN2FmNi03N2QxLTQwNmMtYTFkNy02MzJjNDhhNDZlNmEiLCJpZCI6MjI1NzAyLCJpYXQiOjE3MTk4NDY4Mzd9.s6bftkpzB_s5elwULwtj0_mWTG730vO5M7AayLrmiTE';

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
