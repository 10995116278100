import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Action, Store } from "@ngrx/store";
import { CommonState } from "../reducer";
import { CommonFeBaseEffects } from "../common-fe-base.effects";
import { TranslateService } from "@ngx-translate/core";
import { CurrentState } from "../current-state";
import { Observable } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import {
  LoadUserPushSubscriptionsAction,
  NotificationsActionTypes,
  SetMessageTypesAction,
  SetUserSubscriptionsAction
} from "./notifications.actions";
import { NotificationsService } from "../../services/notifications.service";
import { UserActionTypes } from "../user";

@Injectable()
export class NotificationsEffects extends CommonFeBaseEffects {

  loadTypes: Observable<Action>;

  loadPushSubscriptions: Observable<Action>;

  constructor(
    private translateService: TranslateService,
    private store: Store<CommonState>,
    protected currentState: CurrentState<CommonState>,
    private actions: Actions, private notificationService: NotificationsService) {
    super();


    this.loadTypes = createEffect(() => this.actions.pipe(
      ofType(NotificationsActionTypes.LOAD_ALL_TYPES, UserActionTypes.LOAD_USER_SUCCESS),
      switchMap((payload) => this.notificationService.getTypes().pipe(
        map(types => new SetMessageTypesAction(types)),
        catchError(this.handleHttpError)
      ))));

    this.loadPushSubscriptions = createEffect(() => this.actions.pipe(
      ofType(NotificationsActionTypes.LOAD_USER_PUSH_SUBSCRIPTIONS),
      switchMap((action: LoadUserPushSubscriptionsAction) => {
          return this.notificationService.getPushSubscriptions(action.payload.userId, action.payload.token, action.payload.serial).pipe(
            map(subs => new SetUserSubscriptionsAction(subs)),
            catchError(this.handleHttpError)
          )
        }
      )));
  }
}
