import { AfterViewInit, Component } from '@angular/core';
import { Observable } from "rxjs";
import {
  ClearErrorMessage,
  CurrentState,
  ErrorsState,
  LoginAction,
  LogoutAction,
  PageItem,
  RanchOfUser,
  SetDevSettinsAction,
  SetSelectedRanchIdAction
} from "common-lib";
import { PortalAppState } from "./store/reducer";
import { Store } from "@ngrx/store";
import { TranslateService } from '@ngx-translate/core';
import { ApplicationConstants } from "./app.constants";
import { CheckSessionAction, InitEnabledPagesAction } from "./store/user/portal-user.actions";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { Router } from '@angular/router';
import { devSettings } from '../environments/environment';
import * as _ from "lodash";
import { DialogComponent } from "./components/dialog/dialog.component";
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'pastureProPortal';
  availableEntryPages: Observable<PageItem[]>;
  loggedInObs: Observable<boolean>;

  ranchesOfUser: Observable<RanchOfUser[]>;
  selectedRanch: Observable<number>;

  showSideNav = false;
  
  constructor(
    protected store: Store<PortalAppState>,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private currentState: CurrentState<PortalAppState>,
    protected router: Router
  ) {
    this.initializeApp();
  }
  ngAfterViewInit(): void {
    this.showSideNav = true;
  }

  private initializeApp() {
    this.store.dispatch(new InitEnabledPagesAction());
    this.translateService.use(ApplicationConstants.defaultLanguage);
    this.availableEntryPages = this.store.select(state => state.uiState.availableEntryPages);

    this.loggedInObs = this.store.select(state => state.user.isLoggedIn);
    this.loggedInObs.pipe(distinctUntilChanged()).subscribe(loggedIn => {
      console.log('Logged in obs fired: ' + loggedIn);
      this.store.dispatch(new SetDevSettinsAction(devSettings));
      if (this.currentState.snapshot.dev.autoLogin) {
        let loginData = {
          userName: this.currentState.snapshot.dev.autoLoginUsername,
          password: this.currentState.snapshot.dev.autoLoginPassword,
          userId: null, autoLogin: false
        }
        let loginAction = new LoginAction({...loginData, autoLogin: true});
        this.store.dispatch(loginAction)
      } else if (!loggedIn) {
        this.store.dispatch(new CheckSessionAction());
      }
    })
    this.setupErrorHandling();

    this.ranchesOfUser = this.store.select(state=> state.ranches.ranchesOfUser);
    this.selectedRanch = this.store.select(state=> state.ranches.selectedRanchId);
  }

  sideMenuToggle() {
    this.showSideNav = !this.showSideNav;
  }

  logoutUser(event) {
    this.store.dispatch(new LogoutAction());
    this.router.navigateByUrl('/login');
  }

  private setupErrorHandling() {
    this.store.select(state => state.errors).pipe(
      distinctUntilChanged(_.isEqual),
      filter(error => !!error.key)).subscribe((error: ErrorsState) => {
      let title = this.translateService.instant('ERROR.DIALOG_TITLE');
      if (!!error.messageTitle) {
        title = this.translateService.instant(error.messageTitle);
      }
      let errorMessage;
      if (error.translatedMessage) {
        errorMessage = error.translatedMessage;
      } else {
        if (error.key) {
          let msg = this.translateService.instant(error.key.toString());
          if (msg == error.key.toString()) {
            if (error.defaultMessage) {
              msg = error.defaultMessage;
            } else {
              msg = this.translateService.instant('ERROR.GENERIC_ERROR');
            }
          }
          if (msg) {
            errorMessage = msg;
          }
        } else if (error.defaultMessage) {
          errorMessage = error.defaultMessage;
        } else if (error.statusCode == 500) {
          let msg = this.translateService.instant('ERROR.GENERIC_INTERNAL_BE_ERROR');
          errorMessage = msg;
        } else {
          let msg = this.translateService.instant('ERROR.GENERIC_ERROR');
          errorMessage = msg;
        }
      }

      if (error.additionalInfo && this.currentState.snapshot.dev.showErrorCause) {
        errorMessage += '\nAdditionalInfo: ' + error.additionalInfo;
      }

      if (error.rootCause && this.currentState.snapshot.dev.showErrorCause) {
        errorMessage += '\nCause: ' + error.rootCause;
      }

      this.dialog.closeAll();

      const dialogRef = this.dialog.open(DialogComponent, {
        panelClass: "error-dialog",
        height: '300px',
        width: '300px',
        data: {
          header: title,
          content: errorMessage,
          primaryBtnLabel: this.translateService.instant('BUTTON.CLOSE')
        },
      });
      dialogRef.afterClosed().toPromise().then(result => {
        this.store.dispatch(new ClearErrorMessage());
      })
    })
  }

  ranchChanged(ranchId) {
    this.store.dispatch(new SetSelectedRanchIdAction(ranchId));
  }


  getCompany() {
    if (this.router.url.includes('/prf/')) {
      return 'hargrove'
    } else  if (this.router.url.includes('/advanced-prf/')) {
      return 'aap'
    }
  }
}
