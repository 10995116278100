import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { PortalAppState } from "../../store/reducer";
import { Store } from "@ngrx/store";
import { ClearErrorMessage, ErrorsState, LoginAction } from "common-lib";
import { distinctUntilChanged, filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from '../../components/dialog/dialog.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'login-page',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage implements OnInit,OnDestroy {

  form: UntypedFormGroup;
  error: any;

  errorSub: Subscription;

  constructor(
    protected store: Store<PortalAppState>,
    public dialog: MatDialog,
    private translateService: TranslateService,
  ) {
    let defaultName = '';
    let defaultPassword = '';
    this.form = new UntypedFormGroup({
      userName: new UntypedFormControl(defaultName, {
        validators: [Validators.required],
        updateOn: "submit"
      }),
      password: new UntypedFormControl(defaultPassword, {
        validators: [Validators.required],
        updateOn: "submit"
      })
    });
  }

  ngOnInit() {

    this.errorSub = this.store
    .select((state: PortalAppState) => state.errors).pipe(
    distinctUntilChanged(_.isEqual),
    filter(error => (error.key == "ERROR.LOGIN_USER_NOT_FOUND" && !error.ignorable)))
    .subscribe((errorMessage: ErrorsState) => {
      this.showErrorDialog();
    });

  }

  submitLogin(loginForm) {
    console.log('login submit');
    // const login = {
    //   userName: loginForm.userName,
    //   password: loginForm.passive
    // };
    this.store.dispatch(new LoginAction(loginForm))
  }

  showErrorDialog() {
    let title = this.translateService.instant('ERROR.DIALOG_TITLE');
    let msg = this.translateService.instant('ERROR.LOGIN_USER_NOT_FOUND');
    let closeLabel = this.translateService.instant('BUTTON.CLOSE');

    const dialogRef = this.dialog.open(DialogComponent, {
      panelClass: "error-dialog",
      height: '300px',
      width: '300px',
      data: {
        header: title,
        content: msg,
        primaryBtnLabel: closeLabel,
    },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.store.dispatch(new ClearErrorMessage());
    });

  }

  ngOnDestroy(): void {
    this.errorSub.unsubscribe();
  }
}
