import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import * as ranch from "./ranches.actions";
import * as boundary from "../mappings/boundaries/boundaries.actions";

import {
  AssignNewUserToRanchSuccess,
  CreateNewRanchSuccessAction,
  DeleteRanchSuccessAction,
  LoadCommonUsersForUserAction,
  LoadCommonUsersForUserFailedAction,
  LoadRanchesForUserFailedAction,
  LoadRanchesForUserSuccessAction,
  LoadRanchUsersAction,
  LoadRanchUsersFailedAction,
  LoadRanchUsersSuccessAction,
  ShareBoundariesWithRanchAction,
  ShareBoundariesWithRanchFailedAction,
  ShareBoundariesWithRanchSuccessAction
} from "./ranches.actions";
import { CommonFeBaseEffects } from "../common-fe-base.effects";
import { RanchService } from "../../services/ranch.service";
import { CommonState } from "../reducer";
import { LoadUserSuccessAction, UserActionTypes } from '../user';
import {
  AdminActionTypes,
  LoadRanchesForAdminAction,
  LoadRanchesForAdminFailedAction,
  LoadRanchesForAdminSuccessAction
} from "../admin";
import { LoadSelectedTaskSuccessAction, SetAvailableAssigneesAction, TasksActionTypes } from '../tasks/tasks.actions';
import { UpdateBoundarySuccessAction } from "../mappings/boundaries/boundaries.actions";
import { CurrentState } from "../current-state";


@Injectable()
export class RanchesEffects extends CommonFeBaseEffects {

  loadRanchesForUser: Observable<Action>;

  loadUsersForRanches: Observable<Action>;

  loadRanchesForAdmin: Observable<Action>;

  loadCommonUsersForUser: Observable<Action>;

  shareBoundariesWithRanch: Observable<Action>;

  loadUsersForRanchesAfterTaskIsSelected: Observable<Action>;

  constructor(
    private store: Store<CommonState>,
    private rs: RanchService,
    private currentState: CurrentState<CommonState>,
    private actions: Actions) {
    super();


    this.loadRanchesForUser = createEffect(() => this.actions.pipe(ofType(
        // ranch.MobileAuthActionTypes.LOAD_RANCHES_FOR_USER,
        ranch.RanchesActionTypes.CREATE_NEW_RANCH_SUCCESS,
        ranch.RanchesActionTypes.DELETE_RANCH_SUCCESS,
        boundary.BoundaryActionTypes.CREATE_BOUNDARY_SUCCESS,
        boundary.BoundaryActionTypes.UPDATE_BOUNDARY_SUCCESS)
      , map((action: CreateNewRanchSuccessAction | DeleteRanchSuccessAction | UpdateBoundarySuccessAction) => {
        // console.log('loadRanchesForUser effect map');
        // console.log(action);
        return action.payload
      })
      , filter(payload => {
          // console.log('loadRanchesForUser effect filter');
          // console.log(payload);
          return !!payload.userId || !!payload.id
        }
      )
      , switchMap((userId) => {
        return this.getRanchesForUser(userId).pipe(map((c: any[]) => {
          return new LoadRanchesForUserSuccessAction(c);
        }), catchError(error => {
          console.error('Error during loading ranches for user');
          console.error(error);
          return new Observable<Action>((observer) => {
            observer.next(new LoadRanchesForUserFailedAction(error));
            observer.complete()
          });
        }))
      })));

    this.loadRanchesForAdmin = createEffect(() => this.actions.pipe(ofType(
        AdminActionTypes.LOAD_RANCHES_FOR_ADMIN,
        ranch.RanchesActionTypes.CREATE_NEW_RANCH_SUCCESS,
        ranch.RanchesActionTypes.DELETE_RANCH_SUCCESS)
      , map((action: LoadRanchesForAdminAction | CreateNewRanchSuccessAction | DeleteRanchSuccessAction) => action.payload)
      , filter(payload => (!payload || !payload.userId))
      , switchMap((payload) => {
        return this.getRanchesForAdmin().pipe(map((c: any[]) => {
          return new LoadRanchesForAdminSuccessAction(c);
        }), catchError(error => {
          console.error('Error during loading ranhces for admin');
          console.error(error);
          return new Observable<Action>((observer) => {
            observer.next(new LoadRanchesForAdminFailedAction(error));
            observer.complete()
          });
        }))
      })));

    this.loadCommonUsersForUser = createEffect(() => this.actions.pipe(ofType(
        UserActionTypes.LOAD_USER, ranch.RanchesActionTypes.LOAD_COMMON_USERS_FOR_USER)
      , map((action: LoadUserSuccessAction | LoadCommonUsersForUserAction) => action.payload)
      , switchMap((userId) => {
        return this.rs.loadCommonUsersForUser(userId).pipe(map((c: any[]) => {
          return new SetAvailableAssigneesAction(c);
        }), catchError(error => {
          console.error('Error during loading common users for user', error);
          return new Observable<Action>((observer) => {
            observer.next(new LoadCommonUsersForUserFailedAction(error));
            observer.complete()
          });
        }))
      })));

    this.loadUsersForRanches = createEffect(() => this.actions.pipe(ofType(
        ranch.RanchesActionTypes.LOAD_RANCH_USERS,
        ranch.RanchesActionTypes.ASSIGN_NEW_USER_TO_RANCH_SUCCESS)
      , map((action: LoadRanchUsersAction | AssignNewUserToRanchSuccess) => action.payload)
      , filter(payload => !!payload)
      , switchMap((ranchIds) => {
        return this.getUsersForRanch(ranchIds).pipe(map((c: any[]) => {
          return new LoadRanchUsersSuccessAction(c);
        }), catchError(error => {
          console.error('Error during loading users for ranches', error);
          return new Observable<Action>((observer) => {
            observer.next(new LoadRanchUsersFailedAction(error));
            observer.complete()
          });
        }))
      })));

    this.loadUsersForRanchesAfterTaskIsSelected = createEffect(() => this.actions.pipe(ofType(
        TasksActionTypes.LOAD_SELECT_TASK_SUCCESS)
      , map((action: LoadSelectedTaskSuccessAction) => action.payload)
      , filter(payload => !!payload && payload.ranchId)
      , switchMap((task) => {
        return this.getUsersForRanch([task.ranchId]).pipe(map((c: any[]) => {
          return new LoadRanchUsersSuccessAction(c);
        }), catchError(error => {
          console.error('Error during loading users for ranches', error);
          return new Observable<Action>((observer) => {
            observer.next(new LoadRanchUsersFailedAction(error));
            observer.complete()
          });
        }))
      })));

    this.shareBoundariesWithRanch = createEffect(() => this.actions.pipe(ofType(ranch.RanchesActionTypes.SHARE_BOUNDARIES_WITH_RANCH)
      , map((action: ShareBoundariesWithRanchAction) => action.payload)
      , switchMap((request) => {
        return this.rs.shareBoundariesWithRanch(request.boundaryIds, request.ranchId).pipe(map((c: any[]) => {
          return new ShareBoundariesWithRanchSuccessAction(c);
        }), catchError(error => {
          console.error('Error during sharing boundaries with ranch', error);
          return new Observable<Action>((observer) => {
            observer.next(new ShareBoundariesWithRanchFailedAction(error));
            observer.complete()
          });
        }))
      })));
  }

  private getUsersForRanch(ranchIds: string[]) {
    // console.log('getUsersForRanch called');
    return this.rs.loadUsersForRanch(ranchIds);
  }

  private getRanchesForUser(payload: any) {
    // console.log('getRanchesForUser called');
    let request = payload;
    if (!!payload.title || !!payload.id) {
      request = {
        userId: this.currentState.snapshot.user.id
      };
    }
    return this.rs.loadRanchesForUser(request);
  }

  private getRanchesForAdmin() {
    // console.log('getRanchesForAdmin called');
    return this.rs.loadRanches(null);
  }
}
