import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { CurrentState } from "../store/current-state";
import { CommonState } from "../store/reducer";
import { ServicesCommon } from "./services-common.service";
import { SurveyCategory, SurveyQuestion } from "../model/SurveyModel";
import { ENVIRONMENT_TOKEN } from "../../environment/token-variables";


@Injectable()
export class SurveyService {

  private readonly SURVEY_URL = `/private/survey/`;

  constructor(
    private currentState: CurrentState<CommonState>,
    protected http: HttpClient,
    private servicesCommon: ServicesCommon,
    @Inject(ENVIRONMENT_TOKEN) private readonly environment
  ) {
  }


  getSurveyQuestions(request: { userId: number, ranchId: number }) {
    let url = this.SURVEY_URL + `questions/user/${request.userId}`;
    if (!!request.ranchId) {
      url = this.SURVEY_URL + `questions/ranch/${request.ranchId}`;
    }

    return this.http.get<SurveyQuestion[]>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }


  getSurveyCategories(userId) {
    const url = this.SURVEY_URL + `questions/categories`;
    return this.http.get<SurveyCategory[]>(
      this.environment.backend + url,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  sendSurveyAnswers(userId, ranchId, answers: any) {
    let url = this.SURVEY_URL + `questions/user/${userId}`;
    if (!!ranchId) {
      url = this.SURVEY_URL + `questions/ranch/${ranchId}`;
    }
    return this.http.post<SurveyQuestion[]>(
      this.environment.backend + url,
      answers,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }
}
