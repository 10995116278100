import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from "@ngrx/store";
import 'array-flat-polyfill';
import { combineLatest, forkJoin, Observable, of } from "rxjs";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { CommonConstants } from '../../app.constants';
import {
  AFRequest,
  GridDetailsForClaims,
  PremiumAndCoverageResponse,
  PremiumSummary,
  PRFRequest, Producer
} from "../../model";
import { PrfService } from "../../services/prf.service";
import { CommonFeBaseEffects } from "../common-fe-base.effects";
import { CurrentState } from "../current-state";
import { CommonState } from "../reducer";
import {
  AFLoadDataFailedAction,
  AFLoadDataSuccessAction,
  AnnualForageActionTypes, DeleteClaimsCacheEntryAction, DeletePremiumsCacheEntryAction,
  LoadAFDataAction,
  LoadAllYearAFDataAction,
  LoadAllYearsDashboardDataAction,
  LoadAllYearsDashboardDataSuccessAction, LoadAvailableYearsActions,
  LoadClaimsAction,
  LoadClaimsFailedAction,
  LoadClaimsForCountiesSuccessAction,
  LoadClaimsForProducerAndYearSuccessAction,
  LoadClaimsSuccessAction,
  LoadCoveragePremiumsSummarySuccessAction,
  LoadDashboardClaimsSummarySuccessAction,
  LoadDashboardPremiumsSuccessAction,
  LoadDashboardPremiumsSummarySuccessAction,
  LoadMappedCoveragePremiumsAction,
  LoadMappedCoveragePremiumsFailedAction,
  LoadMappedCoveragePremiumsSuccessAction,
  LoadPRFDashboardDataAction,
  LoadPRFDashboardDataFailedAction,
  LoadPRFDashboardDataSuccessAction,
  LoadPRFPRoducersFailAction,
  LoadPRFPRoducersForNameAction,
  LoadPRFPRoducersSuccessAction,
  PRFActionTypes,
  SetAvailableAFRainfallIntervalsAction,
  SetAvailableClaimsCountyAction,
  SetAvailableCountyAction,
  SetAvailableCoverageCountyAction, SetAvailableYearsActions,
  SetNoPolicyForYearAction,
  SetSelectedAFAssociationIdsAction,
  SetSelectedAFYearAction,
  SetSelectedGrowingSeasonAction
} from "./prf.actions";


@Injectable()
export class PrfEffects extends CommonFeBaseEffects {
  private readonly CACHE_LIFE_IN_MINUTES = 1440;

  loadClaims: Observable<Action>;

  loadDashboardData: Observable<Action>;

  loadClaimsForCounties: Observable<Action>;

  createClaimsForProducerAndYear: Observable<Action>;

  loadCoveragePremiums: Observable<Action>;

  loadPremiumCoverageSummaries: Observable<Action>;

  loadPRFProducers: Observable<Action>;

  loadCoverageAvailableCounties: Observable<Action>;

  loadClaimsAvailableCounties: Observable<Action>;

  checkIfThereIsPolicyForYear: Observable<Action>;

  /**
   * Annual Forage
   */

  loadAFData: Observable<Action>;

  loadAllYearAFDataAction: Observable<Action>;

  loadAFAvailableCounties: Observable<Action>;

  changeAFAvailableIntervals: Observable<Action>;

  loadDashBoardDataAllYear: Observable<Action>;

  loadAvailableYears: Observable<Action>;

  constructor(protected currentState: CurrentState<CommonState>,
              private store: Store<CommonState>,
              private actions: Actions, private prfService: PrfService) {
    super();

    this.loadDashBoardDataAllYear = createEffect(() => this.actions.pipe(
      ofType(PRFActionTypes.LOAD_ALL_YEARS_DASHBOARD_DATA),
      map((action: LoadAllYearsDashboardDataAction) => {
        let filteredAssociationIds = action.payload.selectedAssociationIds.filter(associationId => !this.currentState.snapshot.prf.dashboard.allYearsClaims.hasOwnProperty(associationId));
        return {associationIds: filteredAssociationIds, company: action.payload.company};
      }),
      switchMap((payload: { associationIds: string[], company: 'hargrove' | 'aap' }) => {
        let associationIds = payload.associationIds;
        let company = payload.company;
        if (associationIds.length === 0) {
          return of(new LoadAllYearsDashboardDataSuccessAction({allClaims: [], allPremiums: []}));
        } else {
          const claimsObs = forkJoin(associationIds.map(associationId =>
            forkJoin(this.currentState.snapshot.prf.availableYearsPRF.map(
              year => this.prfService.getClaims({company, year, associationIds: [associationId]}))).pipe(
              map((claims: any[][]) => this.mapClaims(associationId, claims)),
              catchError(err => {
                console.error(err);
                return of({associationId: associationId, claims: null});
              })
            ))
          )
          const premiumsObs = forkJoin(associationIds.map(associationId =>
            forkJoin(this.currentState.snapshot.prf.availableYearsPRF.map(
              year => this.prfService.getPremiums({company, year, associationIds: [associationId]}))).pipe(
              map((premiums: any[][]) => this.mapPremiums(associationId, premiums)),
              catchError(err => {
                console.error(err);
                return of({associationId: associationId, premiums: null});
              })
            ))
          )
          const successAction = combineLatest([claimsObs, premiumsObs]).pipe(map(([allClaims, allPremiums]) => {
            // const allClaimsAction = new LoadAllYearsClaimsSuccessAction(allClaims);
            // const allPremiumsAction = new LoadAllYearsPremiumsSuccessAction(allPremiums)
            return new LoadAllYearsDashboardDataSuccessAction({allClaims, allPremiums});
            // return [allClaimsAction, allPremiumsAction];
          }))
          return successAction;
          // .pipe(
          //   map((allClaims) => new LoadAllYearsClaimsSuccessAction(allClaims)),
          //   catchError(this.handleHttpError));
        }
      })));

    this.loadClaims = createEffect(() => this.actions.pipe(ofType(PRFActionTypes.LOAD_CLAIMS)
      , map((action: LoadClaimsAction) => action.payload)
      , map(r => {
        return {...r};
      }), switchMap(request => {
        return this.getClaims(request)
          .pipe(map(claimsResponse => {
              // console.info("getClaims result:");
              // console.info(claimsResponse);
              return new LoadClaimsSuccessAction({company: request.company, claimsResponse});
            })
            , catchError(err => {
              console.error('Error during loading claims', err);
              return new Observable<Action>((observer) => {
                observer.next(new LoadClaimsFailedAction(err));
                observer.complete()
              });
            }));
      })));

    this.loadAvailableYears = createEffect(() => this.actions.pipe(ofType(PRFActionTypes.LOAD_AVAILABLE_YEARS)
      , map((action: LoadAvailableYearsActions) => action.payload)
      , switchMap(request => {
        return this.prfService.getLatestYears().pipe(map(response => {
            // console.info("getClaims result:");
            // console.info(claimsResponse);
            return new SetAvailableYearsActions(response);
          })
          , catchError(err => {
            console.error('Error during loading claims', err);
            return new Observable<Action>((observer) => {
              observer.next(new LoadClaimsFailedAction(err));
              observer.complete()
            });
          }));
      })));

    /*
      PRF DASHBOARD STARTS
     */
    this.loadDashboardData = createEffect(() => this.actions.pipe(ofType(PRFActionTypes.LOAD_DASHBOARD_DATA)
      , map((action: LoadPRFDashboardDataAction) => action.payload)
      , switchMap(request => {
        const claimsRequest = request.claimsRequest;
        const claimsObs = this.getClaims(claimsRequest)
          .pipe(map((claimsResponse: any[]) => {
              // console.info("getClaims result:");
              // console.info(claimsResponse);
              return claimsResponse;
            }), catchError(getClaimsError => {
              console.error('Error during get claims for dashboard');
              console.error(getClaimsError);
              throw getClaimsError;
            })
          );
        const premiumsRequest = request.premiumRequest;
        const premiumsObs = this.getPremiums(premiumsRequest)
          .pipe(map((premiumsResponse: any[]) => {
              // console.info("getPremiums result:");
              // console.info(premiumsResponse);
              let mapOfAllResponses = this.getMapOfAllRepsonses(premiumsResponse);
              return {
                selectedYear: request.premiumRequest.year,
                premiums: mapOfAllResponses,
              };
            }, catchError(getPremiumsError => {
              console.error('Error during get premiums for dashboard');
              console.error(getPremiumsError);
              throw getPremiumsError;
            }))
          );
        let combined = combineLatest([claimsObs, premiumsObs]).pipe(map(([claims, premiums]) => {
          let responseClaims = (claims as any).map(item => {
            return {...item}
          });
          let responsesForCounties: Map<String, PremiumAndCoverageResponse[]> = new Map();
          let countySummaries: PremiumSummary[] = [];
          responsesForCounties = this.getResponsesForCounties((premiums as any).premiums);
          // values = values.concat(arrayOfResponseForPolicy);
          // });
          Array.from(responsesForCounties.keys()).forEach(countyAsKey => {
            let countySummary: PremiumSummary = new PremiumSummary();
            responsesForCounties.get(countyAsKey).forEach(oneResponseForCounty => {
              countySummary.relatedResponses.push(oneResponseForCounty);
              countySummary.county = oneResponseForCounty.countyDescription;
              countySummary.coverageType = oneResponseForCounty.coverageType;
              countySummary.policyKey = oneResponseForCounty.policyKey;
              countySummary.year = oneResponseForCounty.year;
              countySummary.producer = oneResponseForCounty.producer;
              countySummary.companyCode = oneResponseForCounty.companyCode;
              countySummary.acres = oneResponseForCounty.policyAcres;
              countySummary.hayLandAcres = oneResponseForCounty.hayLandAcres;
              countySummary.grazingLandAcres = oneResponseForCounty.grazingLandAcres;
              oneResponseForCounty.guaranteeDataArray.forEach(guaranteeValue => {
                countySummary.guaranteeSummary = countySummary.guaranteeSummary + guaranteeValue;
              });
              oneResponseForCounty.premiumDataArray.forEach(premiumValue => {
                countySummary.premiumSummary = countySummary.premiumSummary + premiumValue;
              })
            });
            countySummary.profit = countySummary.guaranteeSummary - countySummary.premiumSummary;
            countySummaries.push(countySummary)
          });
          let selectedYear = this.currentState.snapshot.prf.selectedYear;
          return {
            claimSummaries: responseClaims,
            claims: claims,
            premiums: (premiums as any).premiums,
            premiumSummaries: countySummaries,
            selectedYear: selectedYear
          };
        }), mergeMap((data) => {
          let returnArray = [];
          const claimsSummarySuccess = new LoadDashboardClaimsSummarySuccessAction({
            data: data.claimSummaries,
            company: claimsRequest.company
          });
          const premiumSummarySuccess = new LoadDashboardPremiumsSummarySuccessAction({
            premiumSummaries: data.premiumSummaries,
            selectedYear: data.selectedYear,
            company: request.premiumRequest.company
          });
          const premiumSuccess = new LoadDashboardPremiumsSuccessAction({
            selectedYear: data.selectedYear,
            premiums: data.premiums,
            company: request.premiumRequest.company
          });
          const success = new LoadPRFDashboardDataSuccessAction(data);
          returnArray.push(success, premiumSuccess, claimsSummarySuccess, premiumSummarySuccess);
          return returnArray;
        })).pipe(catchError(error => {
          console.error(error);
          return new Observable<Action>((observer) => {
            observer.next(new LoadPRFDashboardDataFailedAction(error));
            observer.complete()
          });
        }));
        return combined;
      })));

    /*
      PRF DASHBOARD STARTS
     */
    this.createClaimsForProducerAndYear = createEffect(() => this.actions.pipe(ofType(PRFActionTypes.LOAD_CLAIMS_SUCCESS, PRFActionTypes.LOAD_DASHBOARD_CLAIMS_SUMMARY_SUCCESS)
      , map((action: LoadClaimsSuccessAction | LoadDashboardClaimsSummarySuccessAction) => {
        if (action instanceof LoadClaimsSuccessAction) {
          return {
            data: (action as LoadClaimsSuccessAction).payload.claimsResponse,
            company: (action as LoadClaimsSuccessAction).payload.company
          };
        }
        return action.payload;
      })
      // .map(obj => ({...obj}))
      , map((payload: any) => {
        let nowDate = new Date();
        let claimsResponse = payload.data;
        let company = payload.company;
        let mapOfClaimsForYearAndProducer: Map<string, GridDetailsForClaims[]> = new Map();
        claimsResponse.forEach((claim: GridDetailsForClaims) => {
          let key = claim.reinsuranceYear + '-' + claim.associationId + '-' + nowDate.getTime();
          const simpleKey = claim.reinsuranceYear + '-' + claim.associationId;
          let existingKeys = [];
          if (company == 'hargrove') {
            existingKeys = [].concat(Array.from(this.currentState.snapshot.prf.claims.hargrove.claimsForProducersByYears.keys()));
          }
          if (company == 'aap') {
            existingKeys = [].concat(Array.from(this.currentState.snapshot.prf.claims.aap.claimsForProducersByYears.keys()));
          }
          for (let ek of existingKeys) {
            const splitted = ek.split('-');
            const simpleExistingKey = splitted[0] + '-' + splitted[1];
            if (simpleExistingKey == simpleKey) {
              key = ek;
              break;
            }
          }
          let claimsArray = [];
          if (mapOfClaimsForYearAndProducer.get(key)) {
            claimsArray = mapOfClaimsForYearAndProducer.get(key);
          }
          claimsArray.push(claim);
          mapOfClaimsForYearAndProducer.set(key, claimsArray);
        });
        return new LoadClaimsForProducerAndYearSuccessAction({company: company, data: mapOfClaimsForYearAndProducer});
      })));

    this.loadClaimsForCounties = createEffect(() => this.actions.pipe(ofType(PRFActionTypes.LOAD_CLAIMS_SUCCESS)
      , map((action: LoadClaimsSuccessAction) => action.payload)
      , map((payload: { company: 'hargrove' | 'aap', claimsResponse: any }) => {
        let mapOfAllClaims: Map<string, GridDetailsForClaims[]> = new Map();
        let response = payload.claimsResponse.map(item => {
          return {...item}
        });
        response.forEach((claim: GridDetailsForClaims) => {
          const county = claim.county;
          const key = county.county + ' - ' + county.countyState;
          let claimsArray = [];
          if (mapOfAllClaims.get(key)) {
            claimsArray = mapOfAllClaims.get(key);
          }
          claimsArray = this.mergeClaimToClaims(claim, claimsArray);
          mapOfAllClaims.set(key, claimsArray);
        });
        return new LoadClaimsForCountiesSuccessAction(mapOfAllClaims);
      })));

    this.loadCoveragePremiums = createEffect(() => this.actions.pipe(ofType(PRFActionTypes.LOAD_COVERAGE_PREMIUMS)
      , map((action: LoadMappedCoveragePremiumsAction) => action.payload)
      , switchMap(request => {
        return this.getPremiums(request)
          .pipe(map(premiumsResponse => {
            // console.log('loadCoveragePremiums done');
            // console.log(premiumsResponse);
            let mapOfAllResponses = this.getMapOfAllRepsonses(premiumsResponse);
            return new LoadMappedCoveragePremiumsSuccessAction({
              selectedYear: request.year,
              premiums: mapOfAllResponses,
              company: request.company
            });
          }), catchError(err => {
            return new Observable<Action>((observer) => {
              observer.next(new LoadMappedCoveragePremiumsFailedAction(err));
              // observer.next(new errorActions.CreateErrorMessage(errorEntry));
              observer.complete()
            });
          }));
      })));

    this.loadPremiumCoverageSummaries = createEffect(() => this.actions.pipe(ofType(PRFActionTypes.LOAD_COVERAGE_PREMIUMS_SUCCESS)
      , map((action: LoadMappedCoveragePremiumsSuccessAction) => action.payload)
      , switchMap((payload: any) => {
        const premiumsForPolicies = payload.premiums;
        let responsesForCounties = this.getResponsesForCounties(premiumsForPolicies, false);

        return new Observable<any>((observer) => {
          const successAction = new LoadCoveragePremiumsSummarySuccessAction({
            selectedYear: payload.selectedYear,
            premiumSummaries: responsesForCounties
          });
          const counties = Array.from(responsesForCounties.keys());

          observer.next(successAction);
          observer.complete()
        });
      })));

    this.loadCoverageAvailableCounties = createEffect(() => this.actions.pipe(ofType(PRFActionTypes.LOAD_COVERAGE_PREMIUMS_SUMMARY_SUCCESS)
      , map((action: LoadCoveragePremiumsSummarySuccessAction) => {
        const premiumsForPolicies = action.payload.premiumSummaries;
        const counties = Array.from(premiumsForPolicies.keys());
        const setAvailableCountiesAction = new SetAvailableCoverageCountyAction(counties);
        return setAvailableCountiesAction;
      })));

    this.loadClaimsAvailableCounties = createEffect(() => this.actions.pipe(ofType(PRFActionTypes.LOAD_CLAIMS_FOR_COUNTIES_SUCCESS)
      , map((action: LoadClaimsForCountiesSuccessAction) => {
        const claimsMap = action.payload;
        const counties = Array.from(claimsMap.keys());
        const setAvailableCountiesAction = new SetAvailableClaimsCountyAction(counties);
        return setAvailableCountiesAction;
      })));

    this.loadPRFProducers = createEffect(() => this.actions.pipe(ofType(PRFActionTypes.LOAD_PRF_PRODUCERS)
      , map((action: LoadPRFPRoducersForNameAction) => action.payload)
      , switchMap(request => {
        return this.getRFProducers(request)
          .pipe(map((prfProducers: Producer[]) => {
              let mapped = prfProducers.map(p => {
                const taxId = p.taxId;
                const taxIdLength = taxId.length;
                let lastDigitsOfTaxId = taxId.substring(taxIdLength - 4, taxIdLength);
                const nrOfX = taxIdLength - lastDigitsOfTaxId.length;
                let maskedTaxId = "";
                for (let i = 0; i < nrOfX; i++) {
                  maskedTaxId += ("x");
                }
                maskedTaxId += (lastDigitsOfTaxId);
                return {...p, taxId: maskedTaxId}
              })
              return new LoadPRFPRoducersSuccessAction(mapped);
            })
            , catchError(err => {
              return new Observable<Action>((observer) => {
                observer.next(new LoadPRFPRoducersFailAction(err));
                observer.complete()
              });
            }));
      })));

    this.checkIfThereIsPolicyForYear = createEffect(() => this.actions.pipe(ofType(
        PRFActionTypes.LOAD_CLAIMS_SUCCESS,
        PRFActionTypes.LOAD_DASHBOARD_DATA_SUCCESS,
        PRFActionTypes.LOAD_COVERAGE_PREMIUMS_SUCCESS)
      , map((action: LoadClaimsSuccessAction | LoadMappedCoveragePremiumsSuccessAction | LoadPRFDashboardDataSuccessAction) => {
        if (!!action.payload?.claimsResponse) {
          return action.payload.claimsResponse;
        }
        return action.payload;
      })
      // .map(obj => ({...obj}))
      , map((response: any) => {
        // console.info("checkIfThereIsPolicyForYear" + response);
        let noPolicy = false;
        if (Array.isArray(response)) {
          noPolicy = !response || response.length < 1
        } else if (response && response.premiums) {
          const valuesArray = Array.from(response.premiums.values());
          const filteredArray = valuesArray.filter((array: any) => array.length > 0);
          noPolicy = !filteredArray || filteredArray.length < 1
        }
        return new SetNoPolicyForYearAction(noPolicy);
      })));

    /**
     * Annual Forage
     */
    this.loadAFData = createEffect(() => this.actions.pipe(
      ofType(AnnualForageActionTypes.LOAD_AF_DATA, AnnualForageActionTypes.SET_SELECTED_ASSOCIATION_IDS, AnnualForageActionTypes.SET_SELECTED_YEAR),
      map((action: LoadAFDataAction | SetSelectedAFAssociationIdsAction | SetSelectedAFYearAction) => action.payload),
      switchMap(request => {

        if (typeof (request) == 'string') { //SET_SELECTED_YEAR
          const year = request;
          const selectedAssociationIds = this.currentState.snapshot.prf.annualForage.selectedAssociationIds;
          if (!selectedAssociationIds.length) {
            return of(new AFLoadDataFailedAction('SelectedAFYear associationIds empty'));
          }
          return this.checkYearForAF(year, selectedAssociationIds);

        } else if (Array.isArray(request)) {
          if (request.length) {
            const selectedAssociationIds = request;
            const year = this.currentState.snapshot.prf.annualForage.selectedYear;
            return this.checkYearForAF(year, selectedAssociationIds);
          } else {
            return of(new AFLoadDataFailedAction('SelectedAFAssocationIds associationIds empty'));
          }
        } else if (request.year) { //LOAD_AF_DATA
          return this.getAFData(request);
        } else {
          return of(new AFLoadDataFailedAction("Something's wrong"));
        }
      })
    ));

    this.loadAllYearAFDataAction = createEffect(() => this.actions.pipe(
      ofType(AnnualForageActionTypes.LOAD_ALL_YEAR_AF_DATA),
      map((action: LoadAllYearAFDataAction) => action.payload),

      switchMap((associationIds: string[]) => {
        if (associationIds.length === 0) {
          return of(new AFLoadDataFailedAction('AllYear associationIds empty'));
        } else {
          return forkJoin(associationIds.map(associationId =>
            forkJoin(this.currentState.snapshot.prf.availableYearsPRF.map(
              year => this.prfService.getAnnualForageData({year, associationIds: [associationId]}))).pipe(
              map((afData: any) => {
                let flatAFData = afData.flat();
                return flatAFData;
              }),
              catchError(err => {
                console.error(err);
                return of([]);
              })
            ))
          ).pipe(
            map((AFData: any) => {
              AFData = AFData.flat();
              return new AFLoadDataSuccessAction(AFData)
            }),
            catchError(this.handleHttpError));
        }
      })
    ));

    this.loadAFAvailableCounties = createEffect(() => this.actions.pipe(
      ofType(AnnualForageActionTypes.AF_LOAD_DATA_SUCCESS),
      map((action: AFLoadDataSuccessAction) => {
        const payload = action.payload;
        const conties = [...new Set(payload.map(row => row.county?.county))];
        return new SetAvailableCountyAction(conties);
      })));

    this.changeAFAvailableIntervals = createEffect(() => this.actions.pipe(
      ofType(AnnualForageActionTypes.SET_SELECTED_GROWING_SEASON),
      map((action: SetSelectedGrowingSeasonAction) => {
        const gSeason = action.payload;
        let intervals: Array<{ id: number, label: string }> = [];
        if (gSeason > 0) {
          const allIntervals = CommonConstants.ANNUAL_FORAGE_CONSTANTS.interValKeys;
          const seasonIntervals = CommonConstants.ANNUAL_FORAGE_CONSTANTS.growingSeasonIntervals[gSeason - 1];
          seasonIntervals.forEach(val => intervals.push(allIntervals[val]));
        } else {
          intervals = [];
        }
        return new SetAvailableAFRainfallIntervalsAction(intervals);
      }),
    ));

  }

  private checkYearForAF(year, selectedAssociationIds): Observable<Action> {
    if (+year > 0) {
      return this.getAFData({year: year, associationIds: selectedAssociationIds});
    } else {
      return of(new LoadAllYearAFDataAction(selectedAssociationIds));
    }
  }

  private getAFData(afRequest: AFRequest) {
    return this.prfService.getAnnualForageData(afRequest)
      .pipe(
        map(response => {
          console.log('loadAFData')
          console.log(response);
          return new AFLoadDataSuccessAction(response);
        }),
        catchError(err => {
            console.error('Error during loading AF data', err);
            return new Observable<Action>((observer) => {
              observer.next(new AFLoadDataFailedAction(err));
              observer.complete()
            });
          }
        ));
  }


  private getMapOfAllRepsonses(premiumsResponse: any[]) {
    let mapOfAllResponses: Map<string, PremiumAndCoverageResponse[]> = new Map();
    premiumsResponse.forEach(premiumsResponse => {
      let mapOfResponses: Map<string, PremiumAndCoverageResponse[]>;
      if (premiumsResponse instanceof Map) {
        mapOfResponses = premiumsResponse;
      } else {
        mapOfResponses = new Map();
        Object.keys(premiumsResponse).forEach(key => {
          let arrayOfResponseForKey = premiumsResponse[key];
          mapOfResponses.set(key, arrayOfResponseForKey)
        });
      }
      Array.from(mapOfResponses.keys()).forEach(key => {
        mapOfAllResponses.set(key, mapOfResponses.get(key));
      })

    });
    return mapOfAllResponses;
  }

  private getResponsesForCounties(premiums: Map<string, PremiumAndCoverageResponse[]>, disticntForPolicies = true) {
    let responsesForCounties: Map<String, PremiumAndCoverageResponse[]> = new Map();
    Array.from(premiums.values()).forEach((arrayOfResponseForPolicy: PremiumAndCoverageResponse[]) => {
      arrayOfResponseForPolicy.forEach(oneResponse => {
        let fullCountyAsKey = oneResponse.countyDescription + ' - ' + oneResponse.coverageType;
        if (disticntForPolicies) {
          fullCountyAsKey = oneResponse.countyDescription + ' - ' + oneResponse.coverageType
            + ' (' + oneResponse.policyKey + ')';
        }

        if (!responsesForCounties.get(fullCountyAsKey)) {
          responsesForCounties.set(fullCountyAsKey, [oneResponse]);
        } else {
          responsesForCounties.get(fullCountyAsKey).push(oneResponse);
        }
      });
    });

    return responsesForCounties;
  }

  getClaims(request: PRFRequest): Observable<any> {
    let notLoaded = [];
    let matchingKeys = [];
    let cachedEntries: GridDetailsForClaims[] = [];
    const currentTimeStamp = new Date().getTime();
    request.associationIds.forEach(associationId => {
      let keyFoundAndValid = false;
      let keys = [];
      if (request.company == 'hargrove') {
        keys = Array.from(this.currentState.snapshot.prf.claims.hargrove.claimsForProducersByYears.keys());
      }
      if (request.company == 'aap') {
        keys = Array.from(this.currentState.snapshot.prf.claims.aap.claimsForProducersByYears.keys());
      }
      keys.forEach((key: any) => {
        const strings = key.split('-');
        if (strings && strings.length > 1) {
          const yearMatch = strings[0] == request.year;
          const associationIdMatch = strings[1] == associationId;
          const timeStamp = strings[2];
          let diffsInMinutes = (currentTimeStamp - timeStamp) / 1000 / 60;
          const cacheOlderThen24Hours = diffsInMinutes > this.CACHE_LIFE_IN_MINUTES;
          if (cacheOlderThen24Hours) {
            this.store.dispatch(new DeleteClaimsCacheEntryAction({key, company: request.company}));
          } else if (yearMatch && associationIdMatch) {
            keyFoundAndValid = true;
            matchingKeys.push(key);
          }
        }
      });
      if (!keyFoundAndValid) {
        notLoaded.push(associationId);
      }
    });
    matchingKeys.forEach(key => {
      let cachedArrayForKey = [];
      if (request.company == 'hargrove') {
        cachedArrayForKey = this.currentState.snapshot.prf.claims.hargrove.claimsForProducersByYears.get(key);
      }
      if (request.company == 'aap') {
        cachedArrayForKey = this.currentState.snapshot.prf.claims.aap.claimsForProducersByYears.get(key);
      }
      cachedEntries = cachedEntries.concat(cachedArrayForKey);
    });

    let cachedEntriesCopy = cachedEntries.map(obj => ({...obj}));

    let cachedEntriesObs = new Observable<GridDetailsForClaims[]>((observer) => {
      observer.next(cachedEntriesCopy);
      observer.complete()
    });
    //everything is already loaded.
    if (!notLoaded || notLoaded.length < 1) {
      return cachedEntriesObs.pipe(map(prs => {
        return prs;
      }));
    }

    let newRequest = {...request};
    newRequest.associationIds = notLoaded;
    const claimsObs = this.prfService.getClaims(newRequest);
    // console.info('Not cached: ');
    // console.info(notLoaded);
    // console.info('Cached: ');
    // console.info(matchingKeys);

    if (cachedEntriesCopy && cachedEntriesCopy.length > 0) {
      return combineLatest(claimsObs, cachedEntriesObs).pipe(map(([loadedPrs, cachedPrs]) => {
        return (loadedPrs as any).concat(cachedPrs);
      }));
    }
    return claimsObs.pipe(map(response => {
      return response;
    }));
  }

  getPremiums(request: PRFRequest): Observable<any> {
    let notLoaded = [];
    let matchingKeys = [];
    let cachedEntries: Map<string, PremiumAndCoverageResponse[]> = new Map();
    let premiumsMap;
    const currentTimeStamp = new Date().getTime();
    request.associationIds.forEach(associationId => {
      let keyFoundAndValid = false;
      if (request.company == 'hargrove') {
        premiumsMap = this.currentState.snapshot.prf.premiums.hargrove.premiumsMap;
      }
      if (request.company == 'aap') {
        premiumsMap = this.currentState.snapshot.prf.premiums.aap.premiumsMap;
      }
      Array.from(premiumsMap.keys()).forEach((key: any) => {
        const strings = key.split('-');
        if (strings && strings.length > 1) {
          const yearMatch = strings[0] == request.year;
          const associationIdMatch = strings[1] == associationId;
          const timeStamp = strings[3];
          let diffsInMinutes = (currentTimeStamp - timeStamp) / 1000 / 60;
          const cacheOlderThen24Hours = diffsInMinutes > this.CACHE_LIFE_IN_MINUTES;
          if (cacheOlderThen24Hours) {
            this.store.dispatch(new DeletePremiumsCacheEntryAction({key, company: request.company}));
          } else if (yearMatch && associationIdMatch) {
            keyFoundAndValid = true;
            matchingKeys.push(key);
          }
        }
      });
      if (!keyFoundAndValid) {
        notLoaded.push(associationId);
      }
    });
    matchingKeys.forEach(key => {
      cachedEntries.set(key, premiumsMap.get(key));
    });

    let cachedEntriesObs = new Observable<any>((observer) => {
      observer.next(cachedEntries);
      observer.complete()
    });
    //everything is already loaded.
    if (!notLoaded || notLoaded.length < 1) {
      return cachedEntriesObs.pipe(map(prs => {
        return [prs]
      }));
    }

    let newRequest = {...request};
    newRequest.associationIds = notLoaded;
    const premiumsObs = this.prfService.getPremiums(newRequest);
    if (cachedEntries && Array.from(cachedEntries.keys()).length > 0) {
      return combineLatest(premiumsObs, cachedEntriesObs).pipe(map(([loadedPrs, cachedPrs]) => {
        return loadedPrs.concat(cachedPrs);
        // return [loadedPrs, cachedPrs];
      }));
    }
    return premiumsObs.pipe(map(prs => {
      return prs
    }));
  }

  getRFProducers(request: PRFRequest) {
    return this.prfService.getPRFProducers(request);
  }

  private mergeClaimToClaims(claim: GridDetailsForClaims, claimsArray: any[]) {
    let newClaim = JSON.parse(JSON.stringify(claim));
    let existingClaimsForGridId = claimsArray.filter((c: GridDetailsForClaims) => c.gridId == newClaim.gridId && c.policyKey == newClaim.policyKey);
    if (!existingClaimsForGridId || existingClaimsForGridId.length < 1) {
      claimsArray.push(newClaim);
      return claimsArray;
    }
    claimsArray.forEach((currentClaim: GridDetailsForClaims) => {
      if (currentClaim.gridId == newClaim.gridId
        && currentClaim.associationId == newClaim.associationId
        && currentClaim.policyKey == newClaim.policyKey
      ) {
        for (let i = 0; i < currentClaim.claimValues.length; i++) {
          let currentValue = currentClaim.claimValues[i];
          let newValue = newClaim.claimValues[i];
          if (currentValue >= 0 && newValue >= 0) {
            currentClaim.claimValues[i] = currentValue + newValue
          }
          if (newValue >= 0 && currentValue < 0) {
            currentClaim.claimValues[i] = newValue;
          }
          currentClaim.predicted[i] = currentClaim.predicted[i] || newClaim.predicted[i];
          if (currentClaim.coverageLevel != newClaim.coverageLevel) {
            currentClaim.coverageLevel = -1;
          }
          const newClaimFinalIndex = newClaim.finalIndexes[i];
          if (currentClaim.finalIndexes[i] < 0 || !currentClaim.finalIndexes[i] && !!newClaimFinalIndex && newClaimFinalIndex >= 0) {
            currentClaim.finalIndexes[i] = newClaimFinalIndex;
          }
        }
      }
    });
    return claimsArray;
  }

  private mapClaims(associationId: string, claims: any[]) {
    const claimsData: any = {associationId: associationId, policies: {}};
    claims.forEach((yearClaims: {
      policyNumber: string, reinsuranceYear: string, claimValues: number[], predicted: boolean[],
      policyKey: number, county: { county: string, coverageType: string, countyCode: string }
    }[]) => {
      yearClaims.forEach(policyYearClaim => {
        if (!claimsData.policies[policyYearClaim.policyNumber]) {
          const county = policyYearClaim.county.county;
          const countyCode = policyYearClaim.county.countyCode;
          claimsData.policies[policyYearClaim.policyNumber] = {years: {}, county, countyCode};
        }
        const year = policyYearClaim.reinsuranceYear;
        if (this.hasYearData(+year)) {
          if (!claimsData.policies[policyYearClaim.policyNumber].years[year]) {
            claimsData.policies[policyYearClaim.policyNumber].years[year] = {};
          }
          const coverageType = policyYearClaim.county.coverageType;
          const key = `${policyYearClaim.policyKey}-${coverageType}`;
          if (!claimsData.policies[policyYearClaim.policyNumber].years[year][key]) {
            claimsData.policies[policyYearClaim.policyNumber].years[year][key] = {
              totalClaims: 0,
              coverageType,
              isPredicted: false
            };
          }
          const yearClaimsData = this.getCurrentYearRange(policyYearClaim.claimValues, +year);
          const totalClaims = yearClaimsData.reduce((sum, curr) => sum + Math.max(curr, 0), 0) +
            claimsData.policies[policyYearClaim.policyNumber].years[year][key].totalClaims;
          const yearPredictedData = this.getCurrentYearRange(policyYearClaim.predicted, +year);
          const isPredicted = claimsData.policies[policyYearClaim.policyNumber].years[year][key].isPredicted ||
            !!yearPredictedData.find(predicted => predicted);
          claimsData.policies[policyYearClaim.policyNumber].years[year][key] = {
            ...claimsData.policies[policyYearClaim.policyNumber].years[year][key], totalClaims, isPredicted
          };
        }
      });
    });
    return claimsData;
  }

  private getCurrentYearRange(array: any[], year: number) {
    const currentDate = new Date();
    if (currentDate.getFullYear() > +year) {
      return array;
    }
    return array.slice(0, currentDate.getMonth() - 1);
  }

  private hasYearData(year: number) {
    const currentDate = new Date();
    return (year < currentDate.getFullYear() || currentDate.getMonth() > 1);
  }

  private mapPremiums(associationId: string, premiums: any[]) {
    const premiumData: any = {associationId: associationId, policies: {}};

    premiums.forEach((yearPremium: any[]) => {
      yearPremium.forEach((policyYearPremiumObj: object) => {
        const policyYearGridPremiumItems:
          { policyKey: number, year: number, premiumDataArray: number[], coverageType: string }[][] =
          Object.values(policyYearPremiumObj);
        policyYearGridPremiumItems.forEach(policyYearGridPremiumItem => {

          policyYearGridPremiumItem.forEach(gridItem => {
            const policyKey = gridItem.policyKey;
            const policyYear = gridItem.year;
            const coverageType = gridItem.coverageType;
            if (!premiumData.policies[policyYear]) {
              premiumData.policies[policyYear] = {};
            }
            const key = `${policyKey}-${coverageType}`;
            if (!premiumData.policies[policyYear][key]) {
              premiumData.policies[policyYear][key] = {totalPremium: 0};
            }
            const yearPremiums = gridItem.premiumDataArray;
            const total = yearPremiums.reduce((sum, curr) => sum + curr, 0);
            premiumData.policies[policyYear][key].totalPremium += total;
          });
        });
      });
    });
    return premiumData;
  }

}
