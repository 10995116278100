import { Injectable } from '@angular/core';
import { Vector as VectorLayer } from 'ol/layer.js';
import { Vector as VectorSource } from 'ol/source.js';
import { Draw, Modify } from 'ol/interaction';
import { Collection } from 'ol';
import { Style, Fill, Stroke } from 'ol/style.js';
import { MapOperationService } from './map-operation.service';
import { Feature } from 'ol';
import { LayerBase } from './layer-base';
import { MapBubbleService } from './map-bubble-service';
import { BoundarySplitService } from './boundary-split.service';
import { WKTGeometryConverter } from './wkt-converter.service';
import { MeasurementService } from './measurement.service';
import { Boundary } from '../../model';
import { DrawingService } from './drawing.service';
import { BoundaryAddService } from './boundary-add.service';
import { ColorService } from '../color.service';
import { SumBoundaryAreaService } from './sum-boundary-area.service';
import { TranslateService } from '@ngx-translate/core';
import { SimpleGeometry } from 'ol/geom';


const projectionCode = 'EPSG:3857';
const PASTURE_LAYER = 'boundary';
const PASTURE_LAYER_MAX_RESOLUTION = Infinity;


@Injectable({
  providedIn: 'root'
})
export class PastureBoundaryLayerService extends LayerBase {
  protected source: VectorSource;
  protected drawInteraction = null;
  protected boundaries: Boundary[];

  protected modify: Modify;
  protected featureCollection: Collection<any>;

  protected overlayConfig = {
    align: 'center' as 'center',
    class: 'pasture-boundary-popup'
  };

  hasInternetConnection;

  constructor(
    protected mapOperationsService: MapOperationService,
    protected mapBubbleService: MapBubbleService,
    protected boundarySplitService: BoundarySplitService,
    protected wktGeometryConverter: WKTGeometryConverter,
    protected measurementService: MeasurementService,
    protected drawingService: DrawingService,
    protected boundaryAddService: BoundaryAddService,
    protected colorService: ColorService,
    protected sumBoundaryAreaService: SumBoundaryAreaService,
    protected translateService: TranslateService,

  ) {
    super(mapOperationsService, mapBubbleService);
    this.setLayerName();
    this.setMaxResolution();
    boundarySplitService.split$.subscribe(this.handleBoundarySplit);
  }

  setInternetConnection(has) {
    this.hasInternetConnection = has;
  }

  setMaxResolution() {
    this.maxResolution = PASTURE_LAYER_MAX_RESOLUTION;
  }

  setLayerName() {
    this.layerName = PASTURE_LAYER;
  }

  addPastureBoundaryLayer(isVisible = true) {
    this.source = new VectorSource({
    });
    this.layer = new VectorLayer({
      source: this.source,
      style: (feature, resolution) => {
        return this.getFeatureStyle(feature);
      },
    });
    this.addLayer();
    this.setLayerVisibility(isVisible);
  }

  updateLayer = (boundaries: Boundary[]) => {
    this.source.clear();
    this.boundaries = this.wktGeometryConverter.filterByGeometries(['POLYGON', 'MULTIPOLYGON'], boundaries);
    boundaries.forEach(b => this.addBoundary(b));
  }

  show = (show: boolean) => {
    if (show) {
      this.setLayerVisibility(true);
    } else {
      this.deSelectAll();
      this.sumBoundaryAreaService.displaySum([]);
      this.setLayerVisibility(false);
    }
  }

  addBoundary(b: Boundary) {
    const geometry = this.wktGeometryConverter.toGeometry(b.geometry);
    if (geometry) {
      const feature = new Feature();
      feature.setGeometry(geometry);
      feature.setProperties({featureData: b});
      this.addFeature(feature);
    }
  }

  addFeature(f: Feature) {
    this.source.addFeature(f);
  }

  getFeatureStyle(feature, isSelected = false, isRedraw = false) {
    const data = (feature.getProperties() && (feature.getProperties() as any).featureData ? (feature.getProperties() as any).featureData : null);

    const defaultStroke = (data && data.color) ? this.colorService.hex2RGB(data.color) :
      this.colorService.hex2RGB(this.colorService.DeafultBoundaryColor);
    const redrawStroke = this.colorService.hex2RGB('#ffffff');
    const fill = (data && data.color) ? this.colorService.hex2RGBA(data.color, .4) :
      this.colorService.hex2RGBA(this.colorService.DeafultBoundaryColor, .4);
    const redrawFill = this.colorService.hex2RGBA('#ffffff', .4);
    return new Style({
      // scale: 0.9,
      fill: new Fill({
        color: isSelected ? fill : (isRedraw ? redrawFill : 'transparent')
      }),
      stroke: new Stroke({
        color: isRedraw ? redrawStroke : defaultStroke,
        width: 2
      }),
    })

  }

  startSplit(feature: Feature) {
    const data = (feature.getProperties() as any).featureData;
    const boundary: Boundary = data;
    this.boundarySplitService.split(this.source, boundary);
  }

  private handleBoundarySplit = ({added, removed}) => {
    this.mapOperationsService.fireEvent('split-boundary', {
      layerName: this.layerName,
      featureData: {added, removed}
    });
  }

  handleFeatureSelection(event) {
    super.handleFeatureSelection(event);
    if (this.modify && this.modify.getActive()) {
      const feature = event.eventData.selectedFeature;
      feature.setStyle(this.getFeatureStyle(feature, false, true));
    }
    this.sumBoundaryAreaService.displaySum(this.selectedFeatures);
  }

  createBoundaryFromParcel(parcel: Feature) {
    const data = {
      boundaries: this.boundaryAddService.createBoundaryFromParcel(parcel, this.boundaries),
      parcel
    };
    this.mapOperationsService.fireEvent('add-feature', {
      layerName: this.layerName,
      featureData: data
    }, null);
  }

  startDrawBoundary(isPortal = false) {

    if (isPortal) {
      let drawInteraction = new Draw({
        type: 'Polygon',
        source: this.source,
        style: {
          'stroke-color': this.colorService.hex2RGB(this.colorService.DeafultBoundaryColor),
          'stroke-width': 1.5,
          'fill-color': this.colorService.hex2RGBA(this.colorService.DeafultBoundaryColor, .4),
          'circle-radius': 6,
          'circle-fill-color': this.colorService.hex2RGB(this.colorService.DeafultBoundaryColor),
        },
      });
  
      drawInteraction.on('drawend', (e) => {
        const newWKTPolygon = this.wktGeometryConverter.polygonToWKT(e.feature.getGeometry() as SimpleGeometry);
        this.mapOperationsService.fireEvent('add-feature', {
          layerName: this.layerName,
          featureData: {newPolygons: [newWKTPolygon]}
        }, null);
        this.mapOperationsService.map.removeInteraction(drawInteraction);
      })
      this.mapOperationsService.map.addInteraction(drawInteraction);

    } else {
      this.drawingService.startDrawPolygon({
        onDrawFinish: (coords) => {
          if (coords) {
            this.recalculateBoundaries(coords);
          }
          this.finishDraw();
        },
        lineColor: this.colorService.hex2RGB(this.colorService.DeafultBoundaryColor),
        pointColor: this.colorService.hex2RGB(this.colorService.DeafultBoundaryColor),
        fillColor: this.colorService.hex2RGBA(this.colorService.DeafultBoundaryColor, .4),
        finishButtonTitle: this.translateService.instant('MAPPING.FINISH_BOUNDARY')
      });
    }
  }

  startRedraw(feature: Feature, isTrace = null) {

    if (!this.layer.getVisible()) {
      this.setLayerVisibility(true);
    }

    this.startCreateBoundary();

    this.featureCollection = new Collection([feature], {});
    this.modify = new Modify({
      features: this.featureCollection,
      // deleteCondition: (event: MapBrowserEvent) => event.type == 'contextmenu',
    });

    this.mapOperationsService.map.addInteraction(this.modify);
    this.appendCtrls(this.mapOperationsService.targetId, isTrace)
  }

  finishRedraw = () => {
    console.log('finishRedraw')
    const editedFeature = this.featureCollection.item(0);
    let boundaryData = {...(editedFeature.getProperties() as any).featureData};
    const wktFromFeature = this.wktGeometryConverter.featureToWKT(editedFeature);
    boundaryData.geometry = wktFromFeature;
    this.mapOperationsService.fireEvent('redraw-boundary', {
      boundaryData: boundaryData
    });

    this.stopRedraw();
  }

  
  finishTracingRedraw = () => {
    const editedFeature = this.featureCollection.item(0);
    const wktFromFeature = this.wktGeometryConverter.featureToWKT(editedFeature);

    this.mapOperationsService.fireEvent('tracing', {
      boundary: {newPolygons: [wktFromFeature]}
    });
    this.stopRedraw();
  }

  private appendCtrls(mapId: string, isTrace) {
    const mapElement = document.getElementById(mapId);
    const parent = document.createElement('div');
    mapElement.append(parent);
    parent.setAttribute('id', 'redraw-poly-button');
    let btn = document.createElement('button');
    btn.className = 'edit-poly';
    btn.innerHTML = "Finish Redraw";
    btn.setAttribute('id', 'redraw-poly');
    if (isTrace) {
      btn.onclick = this.finishTracingRedraw;
    } else {
      btn.onclick = this.finishRedraw;
    }
    parent.append(btn);

    btn = document.createElement('button');
    btn.className = 'edit-poly';
    btn.innerHTML = "Cancel";
    btn.setAttribute('id', 'redraw-cancel');
    btn.onclick = this.cancelRedraw;
    parent.append(btn);

  }

  private stopRedraw() {
    this.modify.setActive(false);
    this.mapOperationsService.map.removeInteraction(this.modify);
    this.finishDraw();
  }

  cancelRedraw = () => {
    this.stopRedraw();
    this.updateLayer(this.boundaries);
  }

  recalculateBoundaries(coords) {
    const data = this.boundaryAddService.addBoundary(coords);
    this.mapOperationsService.fireEvent('add-feature', {
      layerName: this.layerName,
      featureData: data
    }, null);
  }

  finishDraw() {
    this.drawingService.finishDraw();
    this.mapOperationsService.freezeMap(false);
  }

  private startCreateBoundary() {
    this.mapOperationsService.freezeMap(true);
    setTimeout(() => this.mapOperationsService.freezeMap(false), 30000);
  }

  protected baseStyle = (feature) => {
    return this.getFeatureStyle(feature, false);
  }
  protected selectedStyle = (feature) => {
    return this.getFeatureStyle(feature, true);
  }

  overlayHtml = (feature: Feature) => {
    if (feature) {
      const data: Boundary = (feature.getProperties() as any).featureData;
      if (data) {
        return `<div>
          <div class="highlight upper">${data.title}</div>
          <div>(${this.measurementService.getBoundaryArea(feature)})</div>
          <div class="boundary-description">${data.description}</div>
          <div class="toolbar-row">
            <a class="edit-feature edit">${this.settingsBtn}</a>
            ${this.getSplitButton()}
            <a class="edit-feature task">${this.createTaskBtn}</a>
            <a class="edit-feature redraw">${this.redrawBtn}</a>
            ${this.getUnmergedButton(data)}
          </div>
          `;
      }
    }
    return null;
  }

  getUnmergedButton(data) {
    // console.log(data);
    let a = '';
    if (this.hasInternetConnection) {
      if (data.merged) {
        a = `<a class="edit-feature unmerge">
              ${this.unMergeBtn}
            </a>`
      }
    }
    return a;
  }

  getSplitButton() {
    let a = ``;
    if (this.hasInternetConnection) {
      a = `<a class="edit-feature split">
            ${this.splitBtn}
        </a>`
    }
    return a;
  }

  private redrawBtn = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M64 368v80h80l235.727-235.729-79.999-79.998L64 368zm377.602-217.602c8.531-8.531 8.531-21.334 0-29.865l-50.135-50.135c-8.531-8.531-21.334-8.531-29.865 0l-39.468 39.469 79.999 79.998 39.469-39.467z"></path>
    </svg>`;

  private splitBtn = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="s-ion-icon">
    <path d="M203.1 158.3c5.2-11.2 8.1-23.5 8.1-36.7 0-49.5-40.1-89.6-89.6-89.6S32 72.1 32 121.6s40.1 89.6 89.6 89.6c13.2 0 25.5-2.9 36.7-8.1l52.9 52.9-52.9 52.9c-11.2-5.2-23.5-8.1-36.7-8.1-49.5 0-89.6 40.1-89.6 89.6S72.1 480 121.6 480s89.6-40.1 89.6-89.6c0-13.2-2.9-25.5-8.1-36.7l52.9-52.9 156.8 156.8H480v-22.4L203.1 158.3zm-81.5 8.1c-24.6 0-44.8-19.9-44.8-44.8S97 76.8 121.6 76.8s44.8 19.9 44.8 44.8-20.2 44.8-44.8 44.8zm0 268.8c-24.6 0-44.8-19.9-44.8-44.8s20.2-44.8 44.8-44.8 44.8 19.9 44.8 44.8-20.2 44.8-44.8 44.8zm134.4-168c-6.3 0-11.2-4.9-11.2-11.2 0-6.3 4.9-11.2 11.2-11.2 6.3 0 11.2 4.9 11.2 11.2 0 6.3-4.9 11.2-11.2 11.2zM412.8 54.4L278.4 188.8l44.8 44.8L480 76.8V54.4h-67.2z"></path></svg>`;

  private unMergeBtn = `<svg viewBox="0 0 512 512" class="s-ion-icon" xmlns="http://www.w3.org/2000/svg" version="1.1">
    <path stroke="null" id="svg_1" d="m459.69575,248.59752q0,43.99637 -16.98531,84.04435t-45.66543,69.09687t-68.2197,46.2526t-82.97743,17.20371q-47.89301,0 -91.05242,-20.44703t-73.51021,-57.67473q-1.94913,-2.82028 -1.80991,-6.34563t2.36681,-5.78157l38.14734,-38.91987q2.78448,-2.53825 6.96119,-2.53825q4.45516,0.56406 6.4043,3.38434q20.32669,26.79266 49.84215,41.45812t62.65074,14.66546q28.95857,0 55.27188,-11.42214t45.52621,-30.88207t30.49003,-46.11158t11.27713,-55.98256t-11.27713,-55.98256t-30.49003,-46.11158t-45.52621,-30.88207t-55.27188,-11.42214q-27.28788,0 -52.34818,10.01199t-44.55164,28.62584l38.14734,38.91987q8.63188,8.46084 3.89827,19.45993q-4.73361,11.28112 -16.42842,11.28112l-124.74459,0q-7.23964,0 -12.53015,-5.35853t-5.29051,-12.69126l0,-126.34856q0,-11.84518 11.13791,-16.63965q10.85946,-4.79448 19.21289,3.94839l36.19821,36.38162q29.79391,-28.48483 68.08048,-44.13739t79.21839,-15.65256q43.43785,0 82.97743,17.20371t68.2197,46.2526t45.66543,69.09687t16.98531,84.04435z"/>
    </svg>`;

  private createTaskBtn = `<svg viewBox="25 20 250 250" class="s-ion-icon">
    <circle style="fill-opacity:1" id="circle92" r="19.5" cy="231.198" cx="152" class="st2" />
    <polygon style="fill-opacity:1" transform="translate(-747,-146.05232)" id="polygon86" points="926.39,205.5 899,345.4 871.61,205.5" class="st2" />
    </svg>`;

}
