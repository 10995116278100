<div id="main-container">

  <app-top-menu (sideMenuToggleClicked)="sideMenuToggle()" (onLogoutClicked)="logoutUser($event)" [class]="getCompany()"></app-top-menu>
  
  <mat-drawer-container class="" autosize>
  
    <mat-drawer #drawer class="sidenav" mode="side" [opened]="showSideNav">
      <side-menu
        [userLoggedIn]="loggedInObs | async"
        [availableEntryPages]="availableEntryPages|async"
        [ranchesOfUser]="ranchesOfUser|async"
        [selectedRanch]="selectedRanch|async"
        (onLogoutClicked)="logoutUser($event)"
        (ranchChanged)="ranchChanged($event)"
        ></side-menu>
    </mat-drawer>

    <router-outlet></router-outlet>

  </mat-drawer-container>

</div>




