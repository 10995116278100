import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { CommonConstants } from "../app.constants";
import { CommonState } from "../store/reducer";
import { ENVIRONMENT_TOKEN, GOOGLE_ANALYTICS_TOKEN } from "../../environment/token-variables";
import { CurrentState } from "../store/current-state";
import { AFRequest, AnnualForageResponse, PRFRequest } from "../model";
import { forkJoin, of, Observable } from "rxjs";
import 'array-flat-polyfill';
import { catchError, map } from "rxjs/operators";


@Injectable()
export class PrfService {

  constructor(private currentState: CurrentState<CommonState>, protected http: HttpClient,
              @Inject(ENVIRONMENT_TOKEN) private readonly environment,
              @Inject(GOOGLE_ANALYTICS_TOKEN) protected ga
  ) {
  }

  //TODO once the API is clarified make this service more simple by:
  // - remove the param creation method. request is enough by default (should be)
  // - check if we really need the separate methods for the different modes (if modes still exists)

  public getClaims(request: PRFRequest) {
    const company = request.company;
    const path = `/private/prf/${company}/claims/`;
    return this.prepareHttpCalls(request, path, 'getClaims');
  }

  public getLatestYears() {
    const path = `/private/prf/years/latest`;
    const url = this.environment.prf_backend + path;

    const options = {
      headers: this.prepareHttpHeaders()
    };
    this.ga.trackServiceCall('getPRFProducers', CommonConstants.GA.LABEL.PRF);
    return this.http.get(url, options);
  }

  public getPremiums(request: PRFRequest) {
    const company = request.company;
    const path = `/private/prf/${company}/premiums/`;
    return this.prepareHttpCalls(request, path, 'getPremiums');
  }

  public getPRFProducers(request: PRFRequest) {
    const company = request.company;
    const path = `/private/prf/${company}/producers/`;
    const url = this.environment.prf_backend + path + request.primaryName;

    const options = {
      headers: this.prepareHttpHeaders()
    };
    this.ga.trackServiceCall('getPRFProducers', CommonConstants.GA.LABEL.PRF);
    return this.http.get(url, options);
  }


  public getNOAAALatestUpdateDate() {
    const path = '/private/prf/rainfall/noaa/update/latestdate';
    const url = this.environment.prf_backend + path;
    let httpHeaders = this.prepareHttpHeaders();
    const options = {
      headers: httpHeaders
    };
    this.ga.trackServiceCall('getNOAAALatestUpdateDate', CommonConstants.GA.LABEL.PRF);
    return this.http.get(url, options).pipe(map(response => {
      return (response as any).latestUpdateDate;
    }));
  }

  public isClosedInterval(intervalIndex, isAF = false, afYear = null) {
    let closedInterval = false;
    let selectedYear;
    if (isAF) {
      selectedYear = afYear;
    } else {
      selectedYear = this.currentState.snapshot.prf.selectedYear;
    }
    const currentYear = new Date().getFullYear();
    const currentInterval = getCurrentInterval(); // {id: 0, label: "LABEL.JANFEB"},
    //all the intervals are closed when there won't be any new rain fallen.
    if (+selectedYear < currentYear || intervalIndex < currentInterval.id) {
      closedInterval = true;
    }
    return closedInterval;
  }

  public getAnnualForageData(request: AFRequest): Observable<AnnualForageResponse[]> {
    const path = `/private/prf/af/`;
    return this.prepareHttpCalls(request, path, 'getAnnualForageData');
  }

  private prepareHttpCalls(request: PRFRequest | AFRequest, path: string, serviceFunctionName: string): Observable<any> {
    let httpCalls: Observable<any>[] = [];
    const options = {
      headers: this.prepareHttpHeaders()
    };
    const associationIds = request.associationIds;
    let len = associationIds.length;
    for (let i = 0; i < len; i++) {
      const associationId = associationIds[i];
      const url = this.environment.prf_backend + path + request.year + '/prfassociations/' + associationId;
      this.ga.trackServiceCall(serviceFunctionName, CommonConstants.GA.LABEL.PRF);
      let obs = this.http.get(url, options).pipe(catchError(error => {
        console.error(`PrfService#${serviceFunctionName} http error:`);
        console.error(error);
        return of({});
      }));
      httpCalls.push(obs);
    }

    return forkJoin(httpCalls).pipe(map(responses => {
      const flatResponse = (responses as any).flat();
      return flatResponse;
    }));

  }

  private prepareHttpHeaders(contentType: string = 'application/json'): HttpHeaders {
    const token = this.currentState.snapshot.user.token;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', contentType);
    headers = headers.append('Authorization', token);
    headers = headers.append('Auth-server', this.environment.auth_server);
    return headers;
  }

  public createIntervalOrder(row: AnnualForageResponse) {
    let orders = [];

    row.yearsForGS.forEach((gsYears, i) => {
      let order = [];
      gsYears.forEach((y, i) => {
        if (y > -1 && y < +row.year) {
          order.push(i);
        }
      });
      gsYears.forEach((y, i) => {
        if (y > -1 && y == +row.year) {
          order.push(i);
        }
      });

      orders.push(order)
    })

    return orders;
  }

  public producerHasDataInGS(producer: AnnualForageResponse[], gs: number): boolean {
    return producer.some(element => element.yearsForGS[gs - 1].reduce((a, b) => a + b) > 0);
  }
}

export function getCurrentInterval() {
  const nowDate = new Date();
  let currentMonth = nowDate.getMonth();
  //we need the interval for current month where the month is the ending month of the interval
  if (currentMonth > 0) {
    currentMonth--;
  }
  return CommonConstants.PRF_CONSTANTS.interValKeys[currentMonth];
}

export function getLatestIntervalForCurrentMonth() {
  const nowDate = new Date();
  const currentMonth = nowDate.getMonth();
  return CommonConstants.PRF_CONSTANTS.interValKeys[currentMonth];
}

export function getAvailableInterval() {
  // return CommonConstants.PRF_CONSTANTS.interValKeys;
  return [CommonConstants.PRF_CONSTANTS.allInterval].concat(CommonConstants.PRF_CONSTANTS.interValKeys);
}
