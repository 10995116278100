<div class="menu">
    <a mat-button [routerLink]="entryPage.url" *ngFor="let entryPage of availableEntryPages">
      {{ entryPage.title | translate }}
    </a>

    <a *ngIf="userLoggedIn" mat-button (click)="logout()">
      Logout
    </a>
    <a *ngIf="!userLoggedIn" mat-button [routerLink]="'/login'">
      Login
    </a>
</div>



