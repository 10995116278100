import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { CommonFeBaseEffects } from "../common-fe-base.effects";
import { ENVIRONMENT_TOKEN } from "../../../environment/token-variables";
import { CurrentState } from "../current-state";
import { CommonState } from "../reducer";
import {
  AddNewExpenseAction,
  AddNewExpenseFailedAction,
  AddNewExpenseSuccessAction,
  AddNewItemAction,
  AddNewItemFailedAction,
  AddNewItemSuccessAction,
  DeleteExpenseAction,
  DeleteExpenseFailedAction,
  DeleteExpenseSuccessAction,
  DeleteItemAction,
  DeleteItemFailedAction,
  DeleteItemSuccessAction,
  FinanceActionTypes,
  LoadAllExpenseAction,
  LoadAllExpenseFailedAction,
  LoadAllExpenseSuccessAction,
  LoadAllItemAction,
  LoadAllItemFailedAction,
  LoadAllItemSuccessAction,
  LoadCategoriesFailedAction,
  LoadCategoriesSuccessAction,
  LoadItemByIdFailedAction,
  LoadItemByIdSuccessAction,
  LoadItemTypesFailedAction,
  LoadItemTypesSuccessAction,
  LoadRecurringOptionsFailedAction,
  LoadRecurringOptionsSuccessAction,
  UpdateExpenseAction,
  UpdateExpenseFailedAction,
  UpdateExpenseSuccessAction,
  UpdateItemAction,
  UpdateItemFailedAction,
  UpdateItemSuccessAction
} from "./finance.actions";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { FinanceService } from "../../services/finance.service";
import { LivestockService } from "../../services/livestock.service";
import { RanchesActionTypes, SetSelectedRanchIdAction } from '../ranches/ranches.actions';
import { ItemEntity } from "../../model";

@Injectable()
export class FinanceEffects extends CommonFeBaseEffects {

  loadItemTypes: Observable<Action>;

  loadAllItems: Observable<Action>;

  loadAllProfitLoss: Observable<Action>;

  createItem: Observable<Action>;

  updateItem: Observable<Action>;

  deleteItem: Observable<Action>;

  createProfitLoss: Observable<Action>;

  updateProfitLoss: Observable<Action>;

  loadRecurringOptions: Observable<Action>;

  deleteExpense: Observable<Action>;

  loadItemById: Observable<Action>;

  loadCategories: Observable<Action>;

  constructor(
    private actions: Actions,
    protected currentState: CurrentState<CommonState>,
    private financeService: FinanceService,
    private livestockService: LivestockService,
    @Inject(ENVIRONMENT_TOKEN) private readonly environment,
  ) {
    super();


    this.loadCategories = createEffect(() => this.actions.pipe(
      ofType(FinanceActionTypes.LOAD_CATEGORIES),
      switchMap(() => {
        return this.financeService.getCategories().pipe(
          map(response => {
            return new LoadCategoriesSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadCategoriesFailedAction(error));
          })
        );
      })
    ));

    this.loadItemTypes = createEffect(() => this.actions.pipe(
      ofType(FinanceActionTypes.LOAD_ITEM_TYPES),
      switchMap(() => {
        return this.financeService.getItemTypes().pipe(
          map(response => {
            return new LoadItemTypesSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadItemTypesFailedAction(error));
          })
        );
      })
    ));

    this.loadAllItems = createEffect(() => this.actions.pipe(
      ofType(FinanceActionTypes.LOAD_ALL_ITEM, FinanceActionTypes.ADD_NEW_EXPENSE_SUCCESS, RanchesActionTypes.SET_SELECTED_RANCH_ID),
      map((action: LoadAllItemAction | AddNewExpenseSuccessAction | SetSelectedRanchIdAction) => {
        const ranchId = !!action.payload?.ranchId ? action.payload.ranchId : action.payload;
        return ranchId;
      }),
      switchMap((ranchId) => {
        return this.financeService.getAllItemsByRanch(ranchId).pipe(
          map((items: ItemEntity[]) => {
            return new LoadAllItemSuccessAction(items);
          }),
          catchError(error => {
            return of(new LoadAllItemFailedAction(error));
          })
        );
      })
    ));

    this.loadItemById = createEffect(() => this.actions.pipe(
      ofType(FinanceActionTypes.LOAD_ITEM_BY_ID),
      switchMap((itemId) => {
        return this.financeService.getItemById(itemId).pipe(
          map((item: ItemEntity) => {
            return new LoadItemByIdSuccessAction(item);
          }),
          catchError(error => {
            return of(new LoadItemByIdFailedAction(error));
          })
        );
      })
    ));

    this.loadAllProfitLoss = createEffect(() => this.actions.pipe(
      ofType(FinanceActionTypes.LOAD_ALL_EXPENSE, FinanceActionTypes.ADD_NEW_ITEM_SUCCESS, FinanceActionTypes.UPDATE_ITEM_SUCCESS, RanchesActionTypes.SET_SELECTED_RANCH_ID),
      map((action: LoadAllExpenseAction | AddNewItemSuccessAction | UpdateItemSuccessAction | SetSelectedRanchIdAction) => {
        const ranchId = !!action.payload?.ranchId ? action.payload.ranchId : action.payload;
        return ranchId;
      }),
      switchMap((ranchId) => {
        return this.financeService.getAllProfitLossesByRanch(ranchId).pipe(
          map(profitLosses => {
            return new LoadAllExpenseSuccessAction(profitLosses);
          }),
          catchError(error => {
            return of(new LoadAllExpenseFailedAction(error));
          })
        );
      })
    ));

    this.createItem = createEffect(() => this.actions.pipe(
      ofType(FinanceActionTypes.ADD_NEW_ITEM),
      map((action: AddNewItemAction) => {
        return action.payload
      }),
      switchMap((request) => {
        return this.financeService.createItem(request).pipe(
          map(item => {
            return new AddNewItemSuccessAction(item);
          }),
          catchError(error => {
            return of(new AddNewItemFailedAction(error));
          })
        );
      })
    ));

    this.updateItem = createEffect(() => this.actions.pipe(
      ofType(FinanceActionTypes.UPDATE_ITEM),
      map((action: UpdateItemAction) => {
        return action.payload
      }),
      switchMap((request) => {
        return this.financeService.updateItem(request).pipe(
          map(item => {
            return new UpdateItemSuccessAction(item);
          }),
          catchError(error => {
            return of(new UpdateItemFailedAction(error));
          })
        );
      })
    ));

    this.deleteItem = createEffect(() => this.actions.pipe(
      ofType(FinanceActionTypes.DELETE_ITEM),
      map((action: DeleteItemAction) => {
        return action.payload
      }),
      switchMap((itemId) => {
        return this.financeService.deleteItem(itemId).pipe(
          map(item => {
            return new DeleteItemSuccessAction(item);
          }),
          catchError(error => {
            return of(new DeleteItemFailedAction(error));
          })
        );
      })
    ));

    this.createProfitLoss = createEffect(() => this.actions.pipe(
      ofType(FinanceActionTypes.ADD_NEW_EXPENSE),
      map((action: AddNewExpenseAction) => {
        return action.payload
      }),
      switchMap((request) => {
        return this.financeService.createProfitLoss(request).pipe(
          map(item => {
            return new AddNewExpenseSuccessAction(item);
          }),
          catchError(error => {
            return of(new AddNewExpenseFailedAction(error));
          })
        );
      })
    ));

    this.updateProfitLoss = createEffect(() => this.actions.pipe(
      ofType(FinanceActionTypes.UPDATE_EXPENSE),
      map((action: UpdateExpenseAction) => {
        return action.payload
      }),
      switchMap((request) => {
        return this.financeService.updateProfitLoss(request).pipe(
          map(item => {
            return new UpdateExpenseSuccessAction(item);
          }),
          catchError(error => {
            return of(new UpdateExpenseFailedAction(error));
          })
        );
      })
    ));

    this.loadRecurringOptions = createEffect(() => this.actions.pipe(
      ofType(FinanceActionTypes.LOAD_RECURRING_OPTIONS),
      switchMap(() => {
        return this.financeService.getRecurringOptions().pipe(
          map(response => {
            return new LoadRecurringOptionsSuccessAction(response);
          }),
          catchError(error => {
            return of(new LoadRecurringOptionsFailedAction(error));
          })
        );
      })
    ));

    this.deleteExpense = createEffect(() => this.actions.pipe(
      ofType(FinanceActionTypes.DELETE_EXPENSE),
      map((action: DeleteExpenseAction) => action.payload),
      mergeMap((request) => {
        return this.financeService.deleteProfitLoss(request).pipe(
          map(animalId => {
            return new DeleteExpenseSuccessAction(animalId);
          }),
          catchError(error => {
            return of(new DeleteExpenseFailedAction(error));
          })
        );
      })
    ));
  }
}
