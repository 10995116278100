import { Action } from '@ngrx/store';
import { type } from "../types";
import {
  County,
  LRPCommodity,
  LRPEndorsementLength, LRPFinalizedEndorsementRequest, LRPPolicy, LRPPolicyRequest,
  LRPQuoteEndorsement,
  LRPRequest, LRPWatchListEndorsement, LRPWatchListQuote,
  State
} from "../../model/LRPModel";
import { UserDetail } from "../../model";

export const LRPActionTypes = {
  LOAD_ALL_COMMODITIES: type('[app] [LRP] Load all commodities'),
  LOAD_ALL_COMMODITIES_FAILED: type('[app] [LRP] Load all commodities failed'),
  LOAD_ALL_COMMODITIES_SUCCESS: type('[app] [LRP] Load all success'),

  LOAD_ALL_STATES: type('[app] [LRP] Load all states'),
  LOAD_ALL_STATES_FAILED: type('[app] [LRP] Load all states failed'),
  LOAD_ALL_STATES_SUCCESS: type('[app] [LRP] Load all states success'),

  LOAD_ALL_COUNTIES: type('[app] [LRP] Load all counties'),
  LOAD_ALL_COUNTIES_FAILED: type('[app] [LRP] Load all counties failed'),
  LOAD_ALL_COUNTIES_SUCCESS: type('[app] [LRP] Load all counties success'),

  LOAD_ALL_ENDORSEMENT_LENGTHS: type('[app] [LRP] Load all lengths'),
  LOAD_ALL_ENDORSEMENT_LENGTHS_FAILED: type('[app] [LRP] Load all lengths failed'),
  LOAD_ALL_ENDORSEMENT_LENGTHS_SUCCESS: type('[app] [LRP] Load all lengths success'),

  LOAD_ALL_COMMODITY_TYPES: type('[app] [LRP] Load all commodity types'),
  LOAD_ALL_COMMODITY_TYPES_FAILED: type('[app] [LRP] Load all commodity types failed'),
  LOAD_ALL_COMMODITY_TYPES_SUCCESS: type('[app] [LRP] Load all commodity types success'),

  LOAD_LRP_PRODUCERS_FOR_NAME: type('[app] [LRP] Load lrp producers'),
  RESET_LRP_PRODUCERS: type('[app] [LRP] Reset lrp producers'),

  RUN_QUOTE: type('[app] [LRP] Run quotes'),
  RUN_QUOTE_FAILED: type('[app] [LRP] Run quotes failed'),
  SET_ENDORSEMENT_QUOTES: type('[app] [LRP] Set quotes'),
  SET_SELECTED_ENDORSEMENT: type('[app] [LRP] Set selected endorsement'),
  CLEAR_ENDORSEMENT_QUOTES: type('[app] [LRP] CLEAR quotes'),

  UPDATE_LATEST_EFFECTIVE_DATE: type('[app] [LRP] Update latest effective date'),
  SET_LATEST_EFFECTIVE_DATE: type('[app] [LRP] SET latest effective date'),

  LOAD_ALL_WATCHLIST_ITEMS: type('[app] [LRP] Load all WL items'),
  LOAD_ALL_WATCHLIST_ITEMS_FAILED: type('[app] [LRP] Load all WL items failed'),

  SET_WATCHLIST_ITEMS: type('[app] [LRP] Set WL items'),
  LOAD_WATCHLIST_ENDORSEMENTS: type('[app] [LRP] Load WL endorsements'),
  LOAD_WATCHLIST_ENDORSEMENTS_FAILED: type('[app] [LRP] Load WL endorsements failed'),
  SET_WATCHLIST_ENDORSEMENTS: type('[app] [LRP] Set WL endorsements'),

  LOAD_ALL_LRP_USERS: type('[portal] [LRP] Load all LRP users'),
  SET_LRP_USERS: type('[portal] [LRP] Set all LRP users'),

  LOAD_ENDORSEMENT_REQUESTS: type('[portal] [LRP] Load LRP endorsement requests'),
  LOAD_ENDORSEMENT_REQUESTS_FAILED: type('[portal] [LRP] Load LRP endorsement requests failed'),
  SET_ENDORSEMENT_REQUESTS: type('[portal] [LRP] Set LRP endorsement requests'),
  SET_LRP_PRODUCERS: type('[portal] [LRP] Set LRP producers'),

  LOAD_LRP_POLICIES: type('[LRP] Load LRP policies'),
  SET_LRP_POLICIES: type('[LRP] Set LRP policies'),
  LOAD_LRP_POLICIES_FAILED: type('[LRP] Load LRP policies failed'),
  SET_SELECTED_LRP_POLICY: type('[LRP] Set selected LRP policy'),
}


export class LoadAllCommoditiesAction implements Action {
  type = LRPActionTypes.LOAD_ALL_COMMODITIES;

  constructor() {
  }
}

export class LoadAllCommoditiesFailedAction implements Action {
  type = LRPActionTypes.LOAD_ALL_COMMODITIES_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAllCommoditiesSuccessAction implements Action {
  type = LRPActionTypes.LOAD_ALL_COMMODITIES_SUCCESS;

  constructor(public payload: LRPCommodity[]) {
  }
}

export class LoadAllCommodityTypesAction implements Action {
  type = LRPActionTypes.LOAD_ALL_COMMODITY_TYPES;

  constructor() {
  }
}

export class LoadAllCommodityTypesFailedAction implements Action {
  type = LRPActionTypes.LOAD_ALL_COMMODITY_TYPES_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAllCommodityTypesSuccessAction implements Action {
  type = LRPActionTypes.LOAD_ALL_COMMODITY_TYPES_SUCCESS;

  constructor(public payload: LRPCommodity[]) {
  }
}


export class LoadAllStatesAction implements Action {
  type = LRPActionTypes.LOAD_ALL_STATES;

  constructor() {
  }
}

export class LoadAllStatesFailedAction implements Action {
  type = LRPActionTypes.LOAD_ALL_STATES_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAllStatesSuccessAction implements Action {
  type = LRPActionTypes.LOAD_ALL_STATES_SUCCESS;

  constructor(public payload: State[]) {
  }
}


export class LoadAllCountiesAction implements Action {
  type = LRPActionTypes.LOAD_ALL_COUNTIES;

  constructor() {
  }
}

export class LoadAllCountiesFailedAction implements Action {
  type = LRPActionTypes.LOAD_ALL_COUNTIES_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAllCountiesSuccessAction implements Action {
  type = LRPActionTypes.LOAD_ALL_COUNTIES_SUCCESS;

  constructor(public payload: County[]) {
  }
}


export class LoadAllEndorsementLengthsAction implements Action {
  type = LRPActionTypes.LOAD_ALL_ENDORSEMENT_LENGTHS;

  constructor() {
  }
}

export class LoadAllEndorsementLengthsFailedAction implements Action {
  type = LRPActionTypes.LOAD_ALL_ENDORSEMENT_LENGTHS_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAllEndorsementLengthsSuccessAction implements Action {
  type = LRPActionTypes.LOAD_ALL_ENDORSEMENT_LENGTHS_SUCCESS;

  constructor(public payload: LRPEndorsementLength[]) {
  }
}

export class ResetLRPProducersForNameAction implements Action {
  type = LRPActionTypes.RESET_LRP_PRODUCERS;

  constructor() {
  }
}


export class SetLRPPoliciesAction implements Action {
  type = LRPActionTypes.SET_LRP_POLICIES;

  constructor(public payload: { policies: LRPPolicy[] }) {
  }
}

export class SetSelectedLRPPolicyAction implements Action {
  type = LRPActionTypes.SET_SELECTED_LRP_POLICY;

  constructor(public payload: { policy: LRPPolicy }) {
  }
}

export class LoadLRPPoliciesAction implements Action {
  type = LRPActionTypes.LOAD_LRP_POLICIES;

  constructor(public payload: LRPPolicyRequest) {
  }
}

export class LoadLRPPoliciesFailedAction implements Action {
  type = LRPActionTypes.LOAD_LRP_POLICIES_FAILED;

  constructor(public payload: any) {
  }
}


export class LoadLRPProducersForNameAction implements Action {
  type = LRPActionTypes.LOAD_LRP_PRODUCERS_FOR_NAME;

  constructor(public payload: {
    company: 'base',
    primaryName: string
  }) {
  }
}

export class RunQuotesAction implements Action {
  type = LRPActionTypes.RUN_QUOTE;

  constructor(public payload: LRPRequest) {
  }
}

export class RunQuotesFailAction implements Action {
  type = LRPActionTypes.RUN_QUOTE_FAILED;

  constructor(public payload: { request: LRPRequest, error: any }) {
  }
}

export class SetQuoteEndorsementsAction implements Action {
  type = LRPActionTypes.SET_ENDORSEMENT_QUOTES;

  constructor(public payload: { quotes: Map<number, Map<number, LRPQuoteEndorsement[]>>, request: LRPRequest }) {

  }
}

export class SetSelectedEndorsementsAction implements Action {
  type = LRPActionTypes.SET_SELECTED_ENDORSEMENT;

  constructor(public payload: LRPQuoteEndorsement) {
  }
}

export class ClearQuoteEndorsementsAction implements Action {
  type = LRPActionTypes.CLEAR_ENDORSEMENT_QUOTES;

  constructor() {
  }
}

export class SetLatestEffectiveDateAction implements Action {
  type = LRPActionTypes.SET_LATEST_EFFECTIVE_DATE;

  constructor(public payload: String) {
  }
}

export class UpdateLatestEffectiveDateAction implements Action {
  type = LRPActionTypes.UPDATE_LATEST_EFFECTIVE_DATE;

  constructor() {
  }
}


export class LoadAllWatchlistItemsAction implements Action {
  type = LRPActionTypes.LOAD_ALL_WATCHLIST_ITEMS;

  constructor() {
  }
}

export class SetWatchListItemsAction implements Action {
  type = LRPActionTypes.SET_WATCHLIST_ITEMS;

  constructor(public payload: LRPWatchListQuote[]) {
  }
}

export class LoadAllWatchlistItemsFailedAction implements Action {
  type = LRPActionTypes.LOAD_ALL_WATCHLIST_ITEMS_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadWatchListEndorsementsAction implements Action {
  type = LRPActionTypes.LOAD_WATCHLIST_ENDORSEMENTS;

  constructor() {
  }
}

export class SetWatchListEndorsementsFailedAction implements Action {
  type = LRPActionTypes.LOAD_WATCHLIST_ENDORSEMENTS_FAILED;

  constructor(public payload: any) {
  }
}

export class SetWatchListEndorsementsAction implements Action {
  type = LRPActionTypes.SET_WATCHLIST_ENDORSEMENTS;

  constructor(public payload: { watchListItem: number, endorsements: Map<number, LRPWatchListEndorsement[]> }[]) {
  }
}

export class LoadAllLRPUsersAction implements Action {
  type = LRPActionTypes.LOAD_ALL_LRP_USERS;

  constructor() {
  }
}

export class SetAllLRPUsersAction implements Action {
  type = LRPActionTypes.SET_LRP_USERS;

  constructor(public payload: { users: UserDetail[] }) {
  }
}

export class LoadEndorsementRequestsAction implements Action {
  type = LRPActionTypes.LOAD_ENDORSEMENT_REQUESTS;

  constructor(public payload: { entityId: number, startDate: string, endDate: string }) {
  }
}

export class LoadEndorsementRequestsFailedAction implements Action {
  type = LRPActionTypes.LOAD_ENDORSEMENT_REQUESTS_FAILED;

  constructor(public payload: any) {
  }
}


export class SetEndorsementRequestsAction implements Action {
  type = LRPActionTypes.SET_ENDORSEMENT_REQUESTS;

  constructor(public payload: { endorsementRequests: LRPFinalizedEndorsementRequest[] }) {
  }
}

export class SetLRPProducersAction implements Action {
  type = LRPActionTypes.SET_LRP_PRODUCERS;

  constructor(public payload: any) {
  }
}


export declare type LrpActions =
  SetWatchListEndorsementsAction
  | LoadWatchListEndorsementsAction
  | LoadAllWatchlistItemsFailedAction
  | SetWatchListItemsAction
  | LoadAllWatchlistItemsAction
  | LoadAllCommoditiesAction
  | LoadAllCommoditiesFailedAction
  | LoadAllCommoditiesSuccessAction
  | LoadAllStatesAction
  | LoadAllStatesFailedAction
  | LoadAllStatesSuccessAction
  | LoadAllEndorsementLengthsAction
  | LoadAllEndorsementLengthsFailedAction
  | LoadAllEndorsementLengthsSuccessAction
  | LoadAllCommodityTypesAction
  | LoadAllCommodityTypesFailedAction
  | LoadAllCommodityTypesSuccessAction
  | ResetLRPProducersForNameAction
  | LoadLRPProducersForNameAction
  | LoadAllCountiesSuccessAction
  | LoadAllCountiesAction
  | LoadAllCountiesFailedAction
  | ClearQuoteEndorsementsAction
  | RunQuotesAction
  | RunQuotesFailAction
  | SetQuoteEndorsementsAction
  | SetSelectedEndorsementsAction
  | UpdateLatestEffectiveDateAction
  | SetLatestEffectiveDateAction
  | SetAllLRPUsersAction | LoadAllLRPUsersAction | SetLRPProducersAction
  | SetEndorsementRequestsAction | LoadEndorsementRequestsAction | LoadEndorsementRequestsFailedAction
  | SetLRPPoliciesAction | LoadLRPPoliciesAction | LoadLRPPoliciesFailedAction | SetSelectedLRPPolicyAction;

