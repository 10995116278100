import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageItem, RanchOfUser } from "common-lib";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {

  @Input('availableEntryPages') availableEntryPages: PageItem[];
  @Input('userLoggedIn') userLoggedIn: boolean;
  @Output('onLogoutClicked') onLogoutClicked = new EventEmitter();
  @Output('ranchChanged') ranchChanged = new EventEmitter();

  @Input('ranchesOfUser') ranchesOfUser: RanchOfUser[];
  @Input('selectedRanch') selectedRanch: number;

  constructor(
    public translateService: TranslateService,
  ) {
  }

  logout() {
    this.onLogoutClicked.emit();
  }

  onRanchChanged(event) {
    this.ranchChanged.emit(event.value);
  }
}
