import { Action } from '@ngrx/store';
import { AnimalBreed, AnimalSpecies, FinanceCategory, TaskEntity } from '../../model';
import { Animal, AnimalStatus, LivestockListItem, LivestockRequest, Lot } from '../../model/LivestockModel';
import { Boundary } from '../../model/MappingsModel';
import { type } from "../types";

export const LivestockActionTypes = {
  LOAD_ALL_ANIMALS: type('[app] [LIVESTOCK] Load all animals'),
  LOAD_ALL_ANIMALS_FAILED: type('[app] [LIVESTOCK] Load all animals FAILED'),
  LOAD_ALL_ANIMALS_SUCCESS: type('[app] [LIVESTOCK] Load all animals SUCCESS'),

  LOAD_ANIMAL_BY_ID: type('[app] [LIVESTOCK] Load animal by id'),
  LOAD_ANIMAL_BY_ID_SUCCESS: type('[app] [LIVESTOCK] Load animal by id success'),
  LOAD_ANIMAL_BY_ID_FAILED: type('[app] [LIVESTOCK] Load animal by id fail'),
  RELOAD_SELECTED_ANIMAL: type('[app] [LIVESTOCK] Reload selected animal'),

  LOAD_ALL_LOTS: type('[app] [LIVESTOCK] Load all lots'),
  LOAD_ALL_LOTS_FAILED: type('[app] [LIVESTOCK] Load all lots FAILED'),
  LOAD_ALL_LOTS_SUCCESS: type('[app] [LIVESTOCK] Load all lots SUCCESS'),

  ADD_NEW_ANIMAL: type('[app] [LIVESTOCK] Add new animal'),
  ADD_NEW_ANIMAL_FAILED: type('[app] [LIVESTOCK] Add new animal FAILED'),
  ADD_NEW_ANIMAL_SUCCESS: type('[app] [LIVESTOCK] Add new animal SUCCESS'),

  CREATE_NEW_LOT: type('[app] [LIVESTOCK] Create new lot'),
  CREATE_NEW_LOT_FAILED: type('[app] [LIVESTOCK] Create new lot FAILED'),
  CREATE_NEW_LOT_SUCCESS: type('[app] [LIVESTOCK] Create new lot SUCCESS'),

  UPDATE_ANIMAL: type('[app] [LIVESTOCK] Update animal'),
  UPDATE_ANIMAL_FAILED: type('[app] [LIVESTOCK] Update animal FAILED'),
  UPDATE_ANIMAL_SUCCESS: type('[app] [LIVESTOCK] Update animal SUCCESS'),

  DELETE_ANIMAL: type('[app] [LIVESTOCK] Delete animal'),
  DELETE_ANIMAL_FAILED: type('[app] [LIVESTOCK] Delete animal FAILED'),
  DELETE_ANIMAL_SUCCESS: type('[app] [LIVESTOCK] Delete animal SUCCESS'),
  DELETE_MULTIPLE_ANIMALS: type('[app] [LIVESTOCK] Delete multiple animals SUCCESS'),

  UPDATE_LOT: type('[app] [LIVESTOCK] Update lot'),
  UPDATE_LOT_FAILED: type('[app] [LIVESTOCK] Update lot FAILED'),
  UPDATE_LOT_SUCCESS: type('[app] [LIVESTOCK] Update lot SUCCESS'),

  DELETE_LOT: type('[app] [LIVESTOCK] Delete lot'),
  DELETE_LOT_FAILED: type('[app] [LIVESTOCK] Delete lot FAILED'),
  DELETE_LOT_SUCCESS: type('[app] [LIVESTOCK] Delete lot SUCCESS'),

  CREATE_NEW_BIRTHRECORD: type('[app] [LIVESTOCK] Create new birth record'),
  CREATE_NEW_BIRTHRECORD_FAILED: type('[app] [LIVESTOCK] Create new birth record FAILED'),
  CREATE_NEW_BIRTHRECORD_SUCCESS: type('[app] [LIVESTOCK] Create new birth record SUCCESS'),

  UPDATE_BIRTHRECORD: type('[app] [LIVESTOCK] Update birth record'),
  UPDATE_BIRTHRECORD_FAILED: type('[app] [LIVESTOCK] Update birth record FAILED'),
  UPDATE_BIRTHRECORD_SUCCESS: type('[app] [LIVESTOCK] Update birth record SUCCESS'),

  ADD_NEW_PROFIT_LOSS: type('[app] [LIVESTOCK] Add new sale / expense'),
  ADD_NEW_PROFIT_LOSS_FAILED: type('[app] [LIVESTOCK] Add new sale / expense FAILED'),
  ADD_NEW_PROFIT_LOSS_SUCCESS: type('[app] [LIVESTOCK] Add new sale / expense SUCCESS'),

  LOAD_ANIMAL_TYPES: type('[app] [LIVESTOCK] Load animal type list'),
  LOAD_ANIMAL_TYPES_FAILED: type('[app] [LIVESTOCK] Load animal type list FAILED'),
  LOAD_ANIMAL_TYPES_SUCCESS: type('[app] [LIVESTOCK] Load animal type list SUCCESS'),

  LOAD_ANIMAL_BREEDS: type('[app] [LIVESTOCK] Load animal breed list'),
  LOAD_ANIMAL_BREEDS_FAILED: type('[app] [LIVESTOCK] Load animal breed list FAILED'),
  LOAD_ANIMAL_BREEDS_SUCCESS: type('[app] [LIVESTOCK] Load animal breed list SUCCESS'),

  LOAD_TAG_COLORS: type('[app] [LIVESTOCK] Load tag colors list'),
  LOAD_TAG_COLORS_FAILED: type('[app] [LIVESTOCK] Load tag colors list FAILED'),
  LOAD_TAG_COLORS_SUCCESS: type('[app] [LIVESTOCK] Load tag colors list SUCCESS'),

  LOAD_ANIMAL_STATUSES: type('[app] [LIVESTOCK] Load animal status list'),
  LOAD_ANIMAL_STATUSES_FAILED: type('[app] [LIVESTOCK] Load animal status list FAILED'),
  LOAD_ANIMAL_STATUSES_SUCCESS: type('[app] [LIVESTOCK] Load animal status list SUCCESS'),

  LOAD_ANIMAL_SPECIES: type('[app] [LIVESTOCK] Load animal species'),
  LOAD_ANIMAL_SPECIES_FAILED: type('[app] [LIVESTOCK] Load animal species failed'),
  LOAD_ANIMAL_SPECIES_SUCCESS: type('[app] [LIVESTOCK] Load animal species success'),

  LOAD_BIRTH_RESULTS: type('[app] [LIVESTOCK] Load birth result list'),
  LOAD_BIRTH_RESULTS_FAILED: type('[app] [LIVESTOCK] Load birth result list FAILED'),
  LOAD_BIRTH_RESULTS_SUCCESS: type('[app] [LIVESTOCK] Load birth result list SUCCESS'),

  LOAD_BIRTH_METHODS: type('[app] [LIVESTOCK] Load birth method list'),
  LOAD_BIRTH_METHODS_FAILED: type('[app] [LIVESTOCK] Load birth method list FAILED'),
  LOAD_BIRTH_METHODS_SUCCESS: type('[app] [LIVESTOCK] Load birth method list SUCCESS'),

  LOAD_CALVING_EASE: type('[app] [LIVESTOCK] Load calving ease list'),
  LOAD_CALVING_EASE_FAILED: type('[app] [LIVESTOCK] Load calving ease list FAILED'),
  LOAD_CALVING_EASE_SUCCESS: type('[app] [LIVESTOCK] Load calving ease list SUCCESS'),

  LOAD_PASTURES: type('[app] [LIVESTOCK] Load All Pastures'),
  LOAD_PASTURES_FAILED: type('[app] [LIVESTOCK] Load All Pastures FAILED'),
  LOAD_PASTURES_SUCCESS: type('[app] [LIVESTOCK] Load All Pastures SUCCESS'),

  SET_SELECTED_PASTURE: type('[app] [LIVESTOCK] Set selected Pasture'),
  RELOAD_PASTURE_TASK_HISTORY: type('[app] [LIVESTOCK] Reload selected Pasture task history'),
  UPDATE_SELECTED_PASTURE: type('[app] [LIVESTOCK] Update selected Pasture'),
  SET_SELECTED_LOT: type('[app] [LIVESTOCK] Set selected Lot'),
  SET_SELECTED_ANIMAL: type('[app] [LIVESTOCK] Set selected Animal'),

  ADD_SUBSCRIPTION: type('[app] [LIVESTOCK] Add subscription'),
  ADD_SUBSCRIPTION_FAILED: type('[app] [LIVESTOCK] Add subscription failed'),
  ADD_SUBSCRIPTION_SUCCESS: type('[app] [LIVESTOCK] Add subscription success'),

  LOAD_AVAILABLE_MOVE_BOUNDARIES: type('[app] [LIVESTOCK] Load all available move boundaries'),
  LOAD_AVAILABLE_MOVE_BOUNDARIES_FAILED: type('[app] [LIVESTOCK] Load all available move boundaries FAILED'),
  LOAD_AVAILABLE_MOVE_BOUNDARIES_SUCCESS: type('[app] [LIVESTOCK] Load all available move boundaries SUCCESS'),

  SET_CHART_VIEW: type('[app] [LIVESTOCK] Set chart view'),

};

//LOAD_ALL_ANIMALS
export class LoadAllAnimalsAction implements Action {
  type = LivestockActionTypes.LOAD_ALL_ANIMALS;

  constructor(public payload: number | string) {
  }
}

export class LoadAllAnimalsFailedAction implements Action {
  type = LivestockActionTypes.LOAD_ALL_ANIMALS_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAllAnimalsSuccessAction implements Action {
  type = LivestockActionTypes.LOAD_ALL_ANIMALS_SUCCESS;

  constructor(public payload: Animal[]) {
  }
}


export class LoadAnimalByIdAction implements Action {
  type = LivestockActionTypes.LOAD_ANIMAL_BY_ID;

  constructor(public payload: string | number) {
  }
}

export class LoadAnimalByIdSuccessAction implements Action {
  type = LivestockActionTypes.LOAD_ANIMAL_BY_ID_SUCCESS;

  constructor(public payload: { animal: Animal, setSelected: boolean }) {
  }
}

export class LoadAnimalByIdFailedAction implements Action {
  type = LivestockActionTypes.LOAD_ANIMAL_BY_ID_FAILED;

  constructor(public payload: any) {
  }
}

export class ReloadSelectedAnimalAction implements Action {
  type = LivestockActionTypes.RELOAD_SELECTED_ANIMAL;

  constructor() {
  }
}


//LOAD_ALL_LOTS
export class LoadAllLotsAction implements Action {
  type = LivestockActionTypes.LOAD_ALL_LOTS;

  constructor() {
  }
}

export class LoadAllLotsFailedAction implements Action {
  type = LivestockActionTypes.LOAD_ALL_LOTS_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAllLotsSuccessAction implements Action {
  type = LivestockActionTypes.LOAD_ALL_LOTS_SUCCESS;

  constructor(public payload: Lot[]) {
  }
}


//ADD_NEW_ANIMAL
export class AddNewAnimalAction implements Action {
  type = LivestockActionTypes.ADD_NEW_ANIMAL;

  constructor(public payload: LivestockRequest) {
  }
}

export class AddNewAnimalFailedAction implements Action {
  type = LivestockActionTypes.ADD_NEW_ANIMAL_FAILED;

  constructor(public payload: any) {
  }
}

export class AddNewAnimalSuccessAction implements Action {
  type = LivestockActionTypes.ADD_NEW_ANIMAL_SUCCESS;

  constructor(public payload: Animal[]) {
  }
}


//CREATE_NEW_LOT
export class CreateNewLotAction implements Action {
  type = LivestockActionTypes.CREATE_NEW_LOT;

  constructor(public payload: LivestockRequest) {
  }
}

export class CreateNewLotFailedAction implements Action {
  type = LivestockActionTypes.CREATE_NEW_LOT_FAILED;

  constructor(public payload: any) {
  }
}

export class CreateNewLotSuccessAction implements Action {
  type = LivestockActionTypes.CREATE_NEW_LOT_SUCCESS;

  constructor(public payload: Lot) {
  }
}


//UPDATE_ANIMAL
export class UpdateAnimalAction implements Action {
  type = LivestockActionTypes.UPDATE_ANIMAL;

  constructor(public payload: LivestockRequest) {
  }
}

export class UpdateAnimalFailedAction implements Action {
  type = LivestockActionTypes.UPDATE_ANIMAL_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateAnimalSuccessAction implements Action {
  type = LivestockActionTypes.UPDATE_ANIMAL_SUCCESS;

  constructor(public payload: Animal[]) {
  }
}

//DELETE_ANIMAL
export class DeleteAnimalAction implements Action {
  type = LivestockActionTypes.DELETE_ANIMAL;

  constructor(public payload: number) {
  }
}

export class DeleteMultipleAnimalsAction implements Action {
  type = LivestockActionTypes.DELETE_MULTIPLE_ANIMALS;

  constructor(public payload: number[]) {
  }
}


export class DeleteAnimalFailedAction implements Action {
  type = LivestockActionTypes.DELETE_ANIMAL_FAILED;

  constructor(public payload: any) {
  }
}

export class DeleteAnimalSuccessAction implements Action {
  type = LivestockActionTypes.DELETE_ANIMAL_SUCCESS;

  constructor(public payload: number[]) {
  }
}

//UPDATE_LOT
export class UpdateLotAction implements Action {
  type = LivestockActionTypes.UPDATE_LOT;

  constructor(public payload: LivestockRequest) {
  }
}

export class UpdateLotFailedAction implements Action {
  type = LivestockActionTypes.UPDATE_LOT_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateLotSuccessAction implements Action {
  type = LivestockActionTypes.UPDATE_LOT_SUCCESS;

  constructor(public payload: Lot) {
  }
}


//DELETE_LOT
export class DeleteLotAction implements Action {
  type = LivestockActionTypes.DELETE_LOT;

  constructor(public payload: number) {
  }
}

export class DeleteLotFailedAction implements Action {
  type = LivestockActionTypes.DELETE_LOT_FAILED;

  constructor(public payload: any) {
  }
}

export class DeleteLotSuccessAction implements Action {
  type = LivestockActionTypes.DELETE_LOT_SUCCESS;

  constructor(public payload: number) {
  }
}


//CREATE_NEW_BIRTHRECORD
export class CreateNewBirthRecordAction implements Action {
  type = LivestockActionTypes.CREATE_NEW_BIRTHRECORD;

  constructor(public payload: LivestockRequest) {
  }
}

export class CreateNewBirthRecordFailedAction implements Action {
  type = LivestockActionTypes.CREATE_NEW_BIRTHRECORD_FAILED;

  constructor(public payload: any) {
  }
}

export class CreateNewBirthRecordSuccessAction implements Action {
  type = LivestockActionTypes.CREATE_NEW_BIRTHRECORD_SUCCESS;

  constructor(public payload: Animal) {
  }
}

//UPDATE_BIRTHRECORD
export class UpdateBirthRecordAction implements Action {
  type = LivestockActionTypes.UPDATE_BIRTHRECORD;

  constructor(public payload: LivestockRequest) {
  }
}

export class UpdateBirthRecordFailedAction implements Action {
  type = LivestockActionTypes.UPDATE_BIRTHRECORD_FAILED;

  constructor(public payload: any) {
  }
}

export class UpdateBirthRecordSuccessAction implements Action {
  type = LivestockActionTypes.UPDATE_BIRTHRECORD_SUCCESS;

  constructor(public payload: Animal) {
  }
}


//LOAD_ANIMAL_RACES
export class LoadAnimalSpeciesAction implements Action {
  type = LivestockActionTypes.LOAD_ANIMAL_SPECIES;

  constructor() {
  }
}

export class LoadAnimalSpeciesFailedAction implements Action {
  type = LivestockActionTypes.LOAD_ANIMAL_SPECIES_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAnimalSpeciesSuccessAction implements Action {
  type = LivestockActionTypes.LOAD_ANIMAL_SPECIES_SUCCESS;

  constructor(public payload: AnimalSpecies[]) {
  }
}

//LOAD_ANIMAL_TYPES
export class LoadAnimalTypesAction implements Action {
  type = LivestockActionTypes.LOAD_ANIMAL_TYPES;

  constructor() {
  }
}

export class LoadAnimalTypesFailedAction implements Action {
  type = LivestockActionTypes.LOAD_ANIMAL_TYPES_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAnimalTypesSuccessAction implements Action {
  type = LivestockActionTypes.LOAD_ANIMAL_TYPES_SUCCESS;

  constructor(public payload: LivestockListItem[]) {
  }
}

//LOAD_ANIMAL_BREEDS
export class LoadAnimalBreedsAction implements Action {
  type = LivestockActionTypes.LOAD_ANIMAL_BREEDS;

  constructor() {
  }
}

export class LoadAnimalBreedsFailedAction implements Action {
  type = LivestockActionTypes.LOAD_ANIMAL_BREEDS_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAnimalBreedsSuccessAction implements Action {
  type = LivestockActionTypes.LOAD_ANIMAL_BREEDS_SUCCESS;

  constructor(public payload: AnimalBreed[]) {
  }
}


//LOAD_TAG_COLORS
export class LoadTagColorsAction implements Action {
  type = LivestockActionTypes.LOAD_TAG_COLORS;

  constructor() {
  }
}

export class LoadTagColorsFailedAction implements Action {
  type = LivestockActionTypes.LOAD_TAG_COLORS_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadTagColorsSuccessAction implements Action {
  type = LivestockActionTypes.LOAD_TAG_COLORS_SUCCESS;

  constructor(public payload: LivestockListItem[]) {
  }
}

//LOAD_ANIMAL_STATUSES
export class LoadAnimalStatusesAction implements Action {
  type = LivestockActionTypes.LOAD_ANIMAL_STATUSES;

  constructor() {
  }
}

export class LoadAnimalStatusesFailedAction implements Action {
  type = LivestockActionTypes.LOAD_ANIMAL_STATUSES_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAnimalStatusesSuccessAction implements Action {
  type = LivestockActionTypes.LOAD_ANIMAL_STATUSES_SUCCESS;

  constructor(public payload: AnimalStatus[]) {
  }
}

//LOAD_BIRTH_RESULTS
export class LoadBirthResultsAction implements Action {
  type = LivestockActionTypes.LOAD_BIRTH_RESULTS;

  constructor() {
  }
}

export class LoadBirthResultsFailedAction implements Action {
  type = LivestockActionTypes.LOAD_BIRTH_RESULTS_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadBirthResultsSuccessAction implements Action {
  type = LivestockActionTypes.LOAD_BIRTH_RESULTS_SUCCESS;

  constructor(public payload: LivestockListItem[]) {
  }
}

//LOAD_BIRTH_METHODS
export class LoadBirthMethodsAction implements Action {
  type = LivestockActionTypes.LOAD_BIRTH_METHODS;

  constructor() {
  }
}

export class LoadBirthMethodsFailedAction implements Action {
  type = LivestockActionTypes.LOAD_BIRTH_METHODS_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadBirthMethodsSuccessAction implements Action {
  type = LivestockActionTypes.LOAD_BIRTH_METHODS_SUCCESS;

  constructor(public payload: LivestockListItem[]) {
  }
}

//LOAD_CALVING_EASE
export class LoadCalvingEasesAction implements Action {
  type = LivestockActionTypes.LOAD_CALVING_EASE;

  constructor() {
  }
}

export class LoadCalvingEasesFailedAction implements Action {
  type = LivestockActionTypes.LOAD_CALVING_EASE_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadCalvingEasesSuccessAction implements Action {
  type = LivestockActionTypes.LOAD_CALVING_EASE_SUCCESS;

  constructor(public payload: LivestockListItem[]) {
  }
}


//SET_SELECTED
export class SetSelectedPasture implements Action {
  type = LivestockActionTypes.SET_SELECTED_PASTURE;

  constructor(public payload: number | string) {
  }
}

export class ReloadPastureTaskHistoryAction implements Action {
  type = LivestockActionTypes.RELOAD_PASTURE_TASK_HISTORY;

  constructor() {
  }
}


export class SetSelectedLot implements Action {
  type = LivestockActionTypes.SET_SELECTED_LOT;

  constructor(public payload: number | string) {
  }
}

export class SetSelectedAnimal implements Action {
  type = LivestockActionTypes.SET_SELECTED_ANIMAL;

  constructor(public payload: number | string) {
  }
}

export class UpdateSelectedPasture implements Action {
  type = LivestockActionTypes.UPDATE_SELECTED_PASTURE;

  constructor(public payload: { id: number | string, tasks: TaskEntity[] }) {
  }
}

export class AddLivestockSubscriptionAction implements Action {
  type = LivestockActionTypes.ADD_SUBSCRIPTION;

  constructor(public payload: LivestockRequest) {
  }
}

export class AddLivestockSubscriptionFailedAction implements Action {
  type = LivestockActionTypes.ADD_SUBSCRIPTION_FAILED;

  constructor(public payload: any) {
  }
}

export class AddLivestockSubscriptionSuccessAction implements Action {
  type = LivestockActionTypes.ADD_SUBSCRIPTION_SUCCESS;

  constructor() {
  }
}

export class LoadAvailableMoveBoundariesAction implements Action {
  type = LivestockActionTypes.LOAD_AVAILABLE_MOVE_BOUNDARIES;

  constructor(public payload: string) {
  }
}

export class LoadAvailableMoveBoundariesFailedAction implements Action {
  type = LivestockActionTypes.LOAD_AVAILABLE_MOVE_BOUNDARIES_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadAvailableMoveBoundariesSuccessAction implements Action {
  type = LivestockActionTypes.LOAD_AVAILABLE_MOVE_BOUNDARIES_SUCCESS;

  constructor(public payload: Map<number, Boundary[]>) {
  }
}

export class SetChartViewAction implements Action {
  type = LivestockActionTypes.SET_CHART_VIEW;

  constructor(public payload: boolean) {
  }
}

export class LoadPasturesAction implements Action {
  type = LivestockActionTypes.LOAD_PASTURES;

  constructor(public payload: boolean) {
  }
}

export class LoadPasturesSuccessAction implements Action {
  type = LivestockActionTypes.LOAD_PASTURES_SUCCESS;

  constructor(public payload: boolean) {
  }
}

export class LoadPasturesFailedAction implements Action {
  type = LivestockActionTypes.LOAD_PASTURES_FAILED;

  constructor(public payload: boolean) {
  }
}


export declare type LivestockActions =
  LoadAllAnimalsAction
  | LoadAllAnimalsFailedAction
  | LoadAnimalByIdAction
  | LoadAnimalByIdFailedAction
  | LoadAnimalByIdSuccessAction
  | LoadAllAnimalsSuccessAction
  | LoadAllLotsAction
  | LoadAllLotsFailedAction
  | LoadAllLotsSuccessAction
  | AddNewAnimalAction
  | AddNewAnimalFailedAction
  | AddNewAnimalSuccessAction
  | CreateNewLotAction
  | CreateNewLotFailedAction
  | CreateNewLotSuccessAction
  | UpdateAnimalAction
  | UpdateAnimalFailedAction
  | UpdateAnimalSuccessAction
  | DeleteAnimalAction
  | DeleteAnimalFailedAction
  | DeleteAnimalSuccessAction
  | UpdateLotAction
  | UpdateLotFailedAction
  | UpdateLotSuccessAction
  | DeleteLotAction
  | DeleteLotFailedAction
  | DeleteLotSuccessAction
  | CreateNewBirthRecordAction
  | CreateNewBirthRecordFailedAction
  | CreateNewBirthRecordSuccessAction
  | UpdateBirthRecordAction
  | UpdateBirthRecordFailedAction
  | UpdateBirthRecordSuccessAction
  | LoadAnimalTypesAction
  | LoadAnimalTypesFailedAction
  | LoadAnimalTypesSuccessAction
  | LoadAnimalBreedsAction
  | LoadAnimalBreedsFailedAction
  | LoadAnimalBreedsSuccessAction
  | LoadTagColorsAction
  | LoadTagColorsFailedAction
  | LoadTagColorsSuccessAction
  | LoadAnimalStatusesAction
  | LoadAnimalStatusesFailedAction
  | LoadAnimalStatusesSuccessAction
  | LoadBirthResultsAction
  | LoadBirthResultsFailedAction
  | LoadBirthResultsSuccessAction
  | LoadBirthMethodsAction
  | LoadBirthMethodsFailedAction
  | LoadBirthMethodsSuccessAction
  | LoadCalvingEasesAction
  | LoadCalvingEasesFailedAction
  | LoadCalvingEasesSuccessAction
  | SetSelectedPasture
  | UpdateSelectedPasture
  | SetSelectedLot
  | SetSelectedAnimal
  | AddLivestockSubscriptionAction
  | AddLivestockSubscriptionFailedAction
  | AddLivestockSubscriptionSuccessAction
  | LoadAvailableMoveBoundariesAction
  | LoadAvailableMoveBoundariesFailedAction
  | LoadAvailableMoveBoundariesSuccessAction
  | SetChartViewAction
  | ReloadPastureTaskHistoryAction
  | ReloadSelectedAnimalAction
  | LoadPasturesAction
  | LoadPasturesSuccessAction
  | LoadPasturesFailedAction
  | DeleteMultipleAnimalsAction
  | LoadAnimalSpeciesAction | LoadAnimalSpeciesSuccessAction | LoadAnimalSpeciesFailedAction;
