<ng-container *ngIf="ranchesOfUser?.length > 1">
  <mat-form-field>
    <mat-label>Selected Ranch</mat-label>
    <mat-select (selectionChange)="onRanchChanged($event)" [value]="selectedRanch">
      <mat-option *ngFor="let ranch of ranchesOfUser" [value]="ranch.ranch.id">
        {{ranch.ranch.title}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>

<div class="menu">
    <a mat-button [routerLink]="entryPage.url" *ngFor="let entryPage of availableEntryPages">
      {{ entryPage.title | translate }}
    </a>

    <a *ngIf="userLoggedIn" mat-button (click)="logout()" [routerLink]="'/'">
      Logout
    </a>
    <a *ngIf="!userLoggedIn" mat-button [routerLink]="'/login'">
      Login
    </a>
</div>
