import { Pipe, PipeTransform } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CommonState } from "../../store/reducer";
import { CurrentState } from "../../store/current-state";
import { map } from "rxjs/operators";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Observable } from "rxjs";

@Pipe({
  name: 'secure'
})
export class SecurePipe implements PipeTransform {

  constructor(
    readonly sanitizer: DomSanitizer,
    private http: HttpClient,
    protected currentState: CurrentState<CommonState>
  ) {
  }

  transform(url: string): Observable<SafeUrl> {
    console.log('transform called');
    const token = this.currentState.snapshot.user.token;
    const headers = new HttpHeaders({'Authorization': `Bearer ${token}`});
    return this.http
      .get(url, {headers, responseType: 'blob'}).pipe(
        map(val => {
            return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))
          }
        ));
  }
}
