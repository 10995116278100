import { EquipmentService } from '../../services/equipments.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType} from '@ngrx/effects';
import { equipmentActionTypes } from './equipments.actions';
import { Observable } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { CommonFeBaseEffects } from '../common-fe-base.effects';
import { CurrentState } from '../current-state';
import { CommonState } from '../reducer';
import * as eqActions from './equipments.actions';
import { Action } from '@ngrx/store';


@Injectable()
export class EquipmentEffects extends CommonFeBaseEffects {

    loadSubsriptionTypes$: Observable<Action>;

    loadSubsriptionPackages$: Observable<Action>;

    loadCellPlans$: Observable<Action>;

    loadCellProviders$: Observable<Action>;

    loadEquipmentTypes$: Observable<Action>;

    addSubscription$: Observable<Action>;

    addEquipment$: Observable<Action>;

    loadUserEquipments$: Observable<Action>;

    loadUserSubscriptions$: Observable<Action>;

    deleteUserSubscription$: Observable<Action>;

    deleteUserEquipment$: Observable<Action>;

    constructor(
        protected currentState: CurrentState<CommonState>,
        private action$: Actions,
        private equipmentService: EquipmentService
    ) {
        super();
        this.loadSubsriptionTypes$ = createEffect(() => this.action$.pipe(
            ofType(equipmentActionTypes.LOAD_SUBSCRIPTION_TYPES),
            switchMap(() => this.equipmentService.getSubscriptionTypes().pipe(
                map(subscriptionTypes => new eqActions.LoadSubscriptionTypesSuccessAction(subscriptionTypes)),
                catchError(this.handleHttpError)
            )),
        ));

        this.loadSubsriptionPackages$ = createEffect(() => this.action$.pipe(
            ofType(equipmentActionTypes.LOAD_SUBSCRIPTION_PACKAGES),
            switchMap(() => this.equipmentService.getSubscriptionPackages().pipe(
                map(subscriptionPackages => new eqActions.LoadSubscriptionPackagesSuccessAction(subscriptionPackages)),
                catchError(this.handleHttpError)
            )),
        ));

        this.loadCellPlans$ = createEffect(() => this.action$.pipe(
            ofType(equipmentActionTypes.LOAD_CELL_PLANS),
            switchMap(() => this.equipmentService.getCellPlans().pipe(
                map(cellPlans => new eqActions.LoadCellPlansSuccessAction(cellPlans)),
                catchError(this.handleHttpError)
            )),
        ));

        this.loadCellProviders$ = createEffect(() => this.action$.pipe(
            ofType(equipmentActionTypes.LOAD_CELL_PROVIDERS),
            switchMap(() => this.equipmentService.getCellProviders().pipe(
                map(cellProviders => new eqActions.LoadCellProvidersSuccessAction(cellProviders)),
                catchError(this.handleHttpError)
            )),
        ));

        this.loadEquipmentTypes$ = createEffect(() => this.action$.pipe(
            ofType(equipmentActionTypes.LOAD_EQUIPMENT_TYPES),
            switchMap(() => this.equipmentService.getEquipmentTypes().pipe(
                map(equipmentTypes => new eqActions.LoadEquipmentTypesSuccessAction(equipmentTypes)),
                catchError(this.handleHttpError)
            )),
        ));

        this.loadUserEquipments$ = createEffect(() => this.action$.pipe(
            ofType(equipmentActionTypes.LOAD_USER_EQUIPMENTS),
            map((action: eqActions.LoadUserEquipmentsAction) => action.payload),
            switchMap(({ userId }) => this.equipmentService.getUserEquipments(userId).pipe(
                map(equipments => new eqActions.LoadUserEquipmentsSuccessAction(equipments)),
                catchError(this.handleHttpError)
            )),
        ));

        this.loadUserSubscriptions$ = createEffect(() => this.action$.pipe(
            ofType(equipmentActionTypes.LOAD_USER_SUBSCRIPTIONS),
            map((action: eqActions.LoadUserSubscriptionsAction) => action.payload),
            switchMap(({ userId }) => this.equipmentService.getUserSubscriptions(userId).pipe(
                map(subscriptions => new eqActions.LoadUserSubscriptionsSuccessAction(subscriptions)),
                catchError(this.handleHttpError)
            )),
        ));

        this.addSubscription$ = createEffect(() => this.action$.pipe(
            ofType(equipmentActionTypes.ADD_SUBSCRIPTION),
            map((action: eqActions.AddSubscriptionAction) => action.payload),
            switchMap(({ subscriptionPackageId, subscriptionTypeId, userId, activationDate }) =>
                this.equipmentService.submitSubscription(subscriptionPackageId, subscriptionTypeId, userId, activationDate).pipe(
                    map(id => new eqActions.AddSubscriptionSuccessAction(
                        { subscription: { subscriptionPackageId, subscriptionTypeId, id, activationDate } })),
                    catchError(this.handleHttpError)
                ))
        ));

        this.addEquipment$ = createEffect(() => this.action$.pipe(
            ofType(equipmentActionTypes.ADD_EQUIPMENT),
            map((action: eqActions.AddEquipmentAction) => action.payload),
            switchMap(({ equipment, userId }) =>
                this.equipmentService.submitEquipment(equipment, userId).pipe(
                    map(id => new eqActions.AddEquipmentSuccessAction({ equipment: { ...equipment, id } })),
                    catchError(this.handleHttpError)
                ))
        ));

        this.deleteUserEquipment$ = createEffect(() => this.action$.pipe(
            ofType(equipmentActionTypes.DELETE_USER_EQUIPMENT),
            map((action: eqActions.DeleteUserEquipmentAction) => action.payload),
            switchMap(({ equipmentId, userId }) =>
                this.equipmentService.deleteUserEquipment(userId, equipmentId).pipe(
                    map(() => new eqActions.DeleteUserEquipmentSuccessAction({ equipmentId })),
                    catchError(this.handleHttpError)
                ))
        ));

        this.deleteUserSubscription$ = createEffect(() => this.action$.pipe(
            ofType(equipmentActionTypes.DELETE_USER_SUBSCRIPTION),
            map((action: eqActions.DeleteUserSubscriptionAction) => action.payload),
            switchMap(({ subscriptionId, userId }) =>
                this.equipmentService.deleteUserSubscription(userId, subscriptionId).pipe(
                    map(() => new eqActions.DeleteUserSubscriptionSuccessAction({ subscriptionId })),
                    catchError(this.handleHttpError)
                ))
        ));
    }
}
