import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent {

  @Output('sideMenuToggleClicked') sideMenuToggleClicked = new EventEmitter();
  @Output('onLogoutClicked') onLogoutClicked = new EventEmitter();

  constructor(
    public translateService: TranslateService,
    private router: Router
  ) {
  }

  sideMenuToggle() {
    this.sideMenuToggleClicked.emit();
  }

  toHome() {
    this.router.navigateByUrl('/home');
  }

  logout() {
    this.onLogoutClicked.emit();
  }
}
