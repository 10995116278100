import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { DialogComponent } from "../../../components/dialog/dialog.component";
import { UserRequest } from "common-lib";

@Component({
    selector: 'admin-user-form-page',
    templateUrl: './user-form.page.html',
    styleUrls: [],
})
export class UserFormPage {

    userForm: FormGroup;

    showlivestockPermission: boolean = false;

    constructor(
      protected formBuilder: FormBuilder,
      public dialog: MatDialog,
      public dialogRef: MatDialogRef<UserFormPage>,
      private translateService: TranslateService,
      @Inject(MAT_DIALOG_DATA) public data: {
          isNew: boolean,
          showPasswordCheckbox: boolean,
          showRanchManager: boolean,
          ranchOwner: boolean,
          ranchId: number,
          moduleSubscriptions: any[],
          user: any,
          canEdit: boolean,
      },
    ) {

        console.log(this.data)

        this.buildForm();

        if (this.data.moduleSubscriptions) {
            if (this.data.moduleSubscriptions.find(p => !!p.active)) {
              this.showlivestockPermission = true;
            }
            const profitlossSub = this.data.moduleSubscriptions.find(p => p.name == 'ls_profit_loss');
            if (profitlossSub && profitlossSub.active) {
                this.userForm.get('canSeeProfitloss').setValue(true);
            }
        }

        if (this.data.user?.moduleSubscriptions) {
            const subscriptionsForActiveRanch = this.data.user.moduleSubscriptions[this.data.ranchId];
            if (subscriptionsForActiveRanch) {
              const activePermissionsWithProfitLoss = this.data.user.moduleSubscriptions[this.data.ranchId].filter(p => p.active && p.profitLossEnabled);
              if (!!activePermissionsWithProfitLoss && activePermissionsWithProfitLoss.length > 0) {
                this.userForm.get('canSeeProfitloss').setValue(true);
              } else {
                this.userForm.get('canSeeProfitloss').setValue(true);
              }
            }
          }
        
    }

    showDeleteConfirmationAlert() {
      const dialogRef = this.dialog.open(DialogComponent, {
        panelClass: "confirm-dialog",
        width: '350px',
        data: {
          header: "Are you sure?",
          content: `Do you wish to delete this user?`,
          primaryBtnLabel: this.translateService.instant('BUTTON.YES'),
          secondaryBtnLabel: this.translateService.instant('BUTTON.NO'),
        }
      });
      dialogRef.afterClosed().subscribe(r => {
        if (!!r) {
          this.deleteUser();
        }
      });
    }
    
    private deleteUser() {
      let user = {
        id: this.data.user.id
      };
      this.dialogRef.close({result: 'delete', data: user})
    }

    save() {
      let user: UserRequest = {
        userId: this.data.user?.id,
        firstName: this.userForm.get('firstName').value,
        lastName: this.userForm.get('lastName').value,
        email: this.userForm.get('email').value,
        phoneNumber: this.userForm.get('phoneNumber').value,
        userName: this.userForm.get('userName').value,
        password: this.userForm.get('password').value,
        ranchManager: this.userForm.get('ranchManager').value,
        canSeeProfitloss: this.userForm.get('canSeeProfitloss').value ? 1 : 0,
      };

      this.dialogRef.close({result: (this.data.isNew ? 'save': 'update'), data: user})
    }

    private buildForm() {
        this.userForm = this.formBuilder.group({
            firstName: [this.data.user?.firstName],
            lastName: [this.data.user?.lastName],
            email: [this.data.user?.email,[Validators.required]],
            phoneNumber: [this.data.user?.phoneNumber],
            userName: [this.data.user?.userName,[Validators.required]],
            password: [null],
            showPassword: [this.data.canEdit],
            ranchManager: [this.data.user?.ranchManager],
            canSeeProfitloss: [false]
        });
    }
}
