import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  AdminService,
  Boundary,
  CreateErrorMessage,
  CreateUserFailedAction,
  CurrentState,
  DeleteUserAction,
  DeleteUserFailAction,
  ErrorHandler,
  LoadRanchUsersAction,
  LoadUserBoundariesAction,
  LoadUserCamerasAction,
  ModuleSubscription,
  Ranch,
  RanchRequest,
  RanchService,
  RanchUser,
  ShareBoundariesWithRanchAction,
  UpdateUserAction,
  UpdateUserFailUserAction,
  UpdateUserSuccessUserAction,
  UserRequest,
  UserService
} from 'common-lib';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators';
import { PortalAppState } from '../../../store/reducer';
import { RanchBasePage } from '../ranch-base.page';
import * as _ from "lodash";
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { UserFormPage } from '../../user/user-form/user-form.page';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-ranch-users',
  templateUrl: './ranch-home.page.html',
  styleUrls: ['./ranch-home.page.scss'],
})
export class RanchHomePage extends RanchBasePage implements OnInit, OnDestroy {

  private boundariesObs: Observable<Boundary[]>;
  private usersOfRanchesSubscription: Subscription;
  usersOfRanches: Observable<{ ranch: Ranch, users: RanchUser[] }[]>;
  currentUserObs: Observable<any>;
  isShowNewRancObs: Observable<boolean>;

  ownedModuleSubscriptionsObs: Observable<Map<number, ModuleSubscription[]>>;
  ranchId: any;

  constructor(public store: Store<PortalAppState>,
              protected ngZone: NgZone,
              protected ranchService: RanchService,
              protected adminService: AdminService,
              protected currentState: CurrentState<PortalAppState>,
              protected router: Router,
              protected translateService: TranslateService,
              protected userService: UserService,
              public dialog: MatDialog,
              public bottomSheet: MatBottomSheet,
  ) {
    super(store,router,ngZone,ranchService,currentState,translateService,adminService, dialog, userService)
  }
  ngOnDestroy(): void {
    this.usersOfRanchesSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadUserCamerasAction({
      userId: this.currentState.snapshot.user.id,
      loadSharedCameras: false
    }));

    this.ownedModuleSubscriptionsObs = this.store.select(state => state.user.userDetails).pipe(
      filter(ud => !!ud),
      map(ud => {
        return ud.moduleSubscriptions;
      }));

    const ranches = this.store.select(state => state.ranches.ranchesOfUser).pipe(filter(ranches => !!ranches), map(ranches => {
      return ranches.map(ranch => {
        return ranch.ranch;
      });
    }));
    this.store.dispatch(new LoadUserBoundariesAction(this.currentState.snapshot.user.id));
    const currentUserId = this.currentState.snapshot.user.userDetails.userId;
    this.boundariesObs = this.store.select(state => state.mappings.boundaries.boundaries)
      .pipe(
        filter(boundaries => !!boundaries),
        map(boundaries => {
          const canEditBoundaries = boundaries.filter(b => {
            //FIXME szabi_2024_nov_16, shared flag is removed. add new rule when the user can edit boundaries
            return !b.deleted && (currentUserId == b.ownerId);
          });
          return canEditBoundaries;
        }));

    this.isShowNewRancObs = this.store.select(state => state.user.userDetails.ownedRanch).pipe(
      distinctUntilChanged(_.isEqual), map(ownedRanch => {
        return !ownedRanch;
      }));

    this.usersOfRanchesSubscription = ranches.pipe(map(ranches => {
      return ranches.map(ranch => {
        return ranch.id + '';
      });
    })).subscribe(ranches => {
      this.store.dispatch(new LoadRanchUsersAction(ranches));
    });
    this.usersOfRanches = combineLatest(this.store.select(state => state.ranches.userOfRanches), ranches, this.boundariesObs)
      .pipe(
        map(([userOfRanches, ranches, boundaries]) => {
          let enrichedArray: any[] = [];
          const keys = Array.from(userOfRanches.keys());
          keys.forEach(key => {
            ranches.forEach(ranch_ => {
              let ranch = {...ranch_};
              if (ranch.id == key) {
                ranch.sharedBoundaries = ranch.sharedBoundaries.map(sharedBoundary_ => {
                  let sharedBoundary = {...sharedBoundary_};
                  if ((boundaries.map(b => b.id + '').indexOf(sharedBoundary.id + '') < 0) && !sharedBoundary.deleted) {
                    sharedBoundary.visible = true;
                  }
                  return sharedBoundary;
                });
                ranch['selectedBoundaries'] = ranch.sharedBoundaries.map(b => b.id);
                let ranchWithUser = {
                  ranch: ranch,
                  users: userOfRanches.get(key)
                };
                enrichedArray.push(ranchWithUser);
              }
            });
          });
          const sortedAray = enrichedArray.sort((a, b) => {
            const titleA = a.ranch.title;
            const titleB = b.ranch.title;
            return titleA.toLowerCase() < titleB.toLowerCase() ? -1 : 1;
          });
          return sortedAray;
        }));
    this.currentUserObs = this.store.select(state => state.user.userDetails);
    this.usersOfRanches.pipe(withLatestFrom([this.boundariesObs])
      , map(([urs, boundariesOfUser]) => {
        return urs.forEach(u => {
          u.ranch.sharedBoundaries.map(
            sharedBoundaryOfRanch => {
              // boundariesOfUser.map(bou=>bou.id +'').indexOf()
              console.log(sharedBoundaryOfRanch);
            }
          )
        });
      }))
  }

  getPageName() {
    return 'RanchHomePage';
  }

  addNewUserToRanch(ranch) {
    this.bottomSheet.open(RanchNewUserBottomSheetComponent, {
      panelClass: "bottom-sheet"
    }).afterDismissed().subscribe((r) => {
      switch (r) {
        case 'newUser':
          this.presentNewUserDialog(ranch);
          break;
        case 'existingUser':
          this.presentExistingSearchDialog(ranch);
          break;
      }
    });
  }

  openUserDetails(user, ranch) {

    const dialogRef = this.dialog.open(UserFormPage, {
      panelClass: "form-dialog",
      height: '500px',
      width: '600px',
      data: {
        canEdit: ranch.ranch.owner.id == user.id,
        isNew: false,
        showPasswordCheckbox: false,
        showRanchManager: true,
        ranchId: ranch.ranch.id,
        ranchOwner: ranch.ranch.owner.id == user.id,
        moduleSubscriptions: this.currentState.snapshot.user.userDetails.moduleSubscriptions[ranch.ranch.id],
        user: user,
        // ranchId: ranch.ranch.id,
        // owner: ranch.ranch.owner.id == user.id
      },
    });
    dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          console.log(result)

          if (result.result == 'delete') {
            this.deleteUser(result.data.id);
          } else if (result.result == 'update') {
            this.updateUser(result.data);
          } else if (result.result == 'save') {
            this.registerUser(result.data);
          }
        }
    });

  }

  deleteUser(userId) {
    this.adminService.deleteUser(userId).pipe(catchError(error => {
      return new Observable<Action>((observer) => {
        observer.next(new DeleteUserFailAction(ErrorHandler.getErrorEntry(error)));
        observer.complete()
      });
    })).subscribe(response => {
      if (response instanceof HttpErrorResponse || response instanceof DeleteUserFailAction) {
        console.error("Error during user delete");
        console.error(response);
        if (response instanceof DeleteUserFailAction) {
          this.store.dispatch(new CreateErrorMessage(response.payload));
        }
      } else {
        this.store.dispatch(new LoadRanchUsersAction([this.ranchId]));
      }
    });
  }

  registerUser(user: UserRequest) {
    user.ranchId = this.ranchId;
    this.adminService.createNewUser(user).pipe(
      catchError(error => {
        return new Observable<Action>((observer) => {
          observer.next(new CreateUserFailedAction(ErrorHandler.getErrorEntry(error)));
          observer.complete()
        });
      })).subscribe(response => {
      if (response instanceof HttpErrorResponse || response instanceof CreateUserFailedAction) {
        console.error("Error during create user");
        console.error(response);
        if (response instanceof CreateUserFailedAction) {
          // this.store.dispatch(new CreateErrorMessage(response.payload));
        }
      } else {
        // toast ?
        // this.addUserToRanch()
        console.log(response)

        this.store.dispatch(new LoadRanchUsersAction([this.ranchId]));
      }
    });
  }

  updateUser(user: UserRequest) {

    user.changerUserId = this.currentState.snapshot.user.id;

    this.store.dispatch(new UpdateUserAction(user));

    this.userService.updateUser(user).pipe(catchError(error => {
      return new Observable<Action>((observer) => {
        observer.next(new UpdateUserFailUserAction(ErrorHandler.getErrorEntry(error)));
        observer.complete()
      });
    })).subscribe(response => {
      if (response instanceof UpdateUserFailUserAction) {
        // this.store.dispatch(new CreateErrorMessage(response.payload));
      } else {
        // toast ?
        this.store.dispatch(new UpdateUserSuccessUserAction(user.userId));
      }
    });
  }

  protected getNewRanchRequest(ranchName) {
    const userId = this.currentState.snapshot.user.userDetails.userId;
    let ranchRequest = <RanchRequest>{
      userId: userId,
      title: ranchName
    };
    return ranchRequest;
  }


  selectedBoundaryChanged(event, ranch) {
    if (event.source.value) {
      let b: number[] = [...ranch.ranch['selectedBoundaries']];
      let i = b.findIndex(id => id == event.source.value);
      if (i > -1) {
        b.splice(i, 1);
      } else {
        b.push(event.source.value);
      }

      let request = {
        boundaryIds: b as [],
        ranchId: ranch.ranch.id
      };
      this.store.dispatch(new ShareBoundariesWithRanchAction(request));
    }
  }

}



@Component({
  selector: 'ranch-new-user-bottom-sheet-component',
  templateUrl: './ranch-new-user-bottomsheet.component.html',
  styleUrls: [],
})
export class RanchNewUserBottomSheetComponent {

  constructor(
      private bottomSheetRef: MatBottomSheetRef<RanchNewUserBottomSheetComponent>,
  ) {}

  title = "Add user to ranch";
  subtitle = "Do you want to create a new user or do you want to search for existing users?";
  newUserButton = "New user";
  existingUserButton = "Existing user";

  openLink(value): void {
    this.bottomSheetRef.dismiss(value);
  }
}