export interface LRPCommodity {
  commodityCode: string;
  title: string;
}

export interface LRPEndorsementLength {
  count: number;
  title: string;
  longTitle: string;
}

export interface LRPCommodityType {
  minWeight: number,
  maxWeight: number,
  commodityId: number;
  commodityCode: string;
  title: string;
  abbrTitle: string;
  description: string;
  typeCode: string;
}

export interface State {
  stateKey: number;
  title: string;
  postalCode: string;
  ncisCode: string;
  fipsCode: string;
}

export interface County {
  code: string;
  name: string;
  abrev: string;
  fipsCode: string;
  countyKey: string;
  stateKey: number;
}

export interface BaseEndorsement {
  stateFipsCode?: string;
  countyFipsCode?: string;
  commodityCode?: string;
}

export interface LRPRequest extends BaseEndorsement {
  quoteId?: number;
  fileName?: string;
  effectiveDate?: string;
  numberOfHead?: number;
  targetWeight1?: number;
  targetWeight2?: number;
  insuredShare?: number;
  endorsementLengthCounts?: number[];
  userId?: number;
  typeCodes?: string[];
  triggerPrice?: number;
  watchListId?: number;
  dailyReportSubscribed?: boolean;
  membershipId?: number;
  finalizedRequestId?: number;
  selectedAssociationId?: number;
  emails?: string[];
  ccEmails?: string[];
  commodityCodes?: string[];
  reinsuranceYears?: string[];
}

export interface LRPQuoteEndorsement extends BaseEndorsement {
  id?: number;
  endorsementLengthCount: number;
  endDate: string
  expectedMarketPrice: number;
  costPerHead: number;
  coveragePrice: number;
  coverageLevel: number;
  costPerCWT: number;
  producerPremium: number;
  commodityTypeCode?: string;
  typeCode?: string;
  insuredValue?: number;
  rate?: number;
  totalPremium?: number
  subsidy?: number;
  subsidyPercentage?: number;
  status?: number;
}

export interface LRPPolicy extends LRPQuoteEndorsement {
  numberOfHead: number;
  todayPrice: number;
  net: number;
  entityName: string;
  targetWeight: number;
  effectiveDate: string;
  coverageStatus: 'ACTIVE' | 'COMPLETED';
  endingValue?: number;
  expectedEndingValue?: number;
  reinsuranceYear: string;
  stateCode?: number;
  countyCode?: number;
  policyId?: number;
}

export interface LRPFinalizedEndorsementRequest extends LRPQuoteEndorsement {
  senderFirstName: string;
  senderLastName: string;
  senderEmail: string;
  senderPhone: string;
  requestCreated: string;
  senderUserName: string;
  senderId: number;
  entityName: string;
  accepted: boolean;
  submitted: boolean;
  rejectReason: string;
  numberOfHead: number;
  targetWeight: number;
}

export interface LRPPolicyRequest {
  company: string,
  associationIds: number[],
  commodityCodes: string[],
  typeCodes: string[],
  years: number[]
}

export function createInfoStringFromLRPFinalizedEndorsementRequest(r: LRPFinalizedEndorsementRequest | LRPPolicy, currencyFormatter, commodities: any[], commodityTypes: any[], states: any[] = [], policy = false) {
  console.log('createInfoStringFromLRPFinalizedEndorsementRequest  called');
  let commodity = commodities.find(c => {
    return c.commodityCode == r.commodityCode
  })?.title;
  let commodityType = commodityTypes.find(c => {
    return c.typeCode == r.typeCode
  })?.title;
  let result = '';
  if (policy) {
    result += 'Entity: ' + (r as LRPPolicy).entityName;
    let state = states.find(c => c.fipsCode == (r as LRPPolicy).stateCode)?.title;
    result += '\nState: ' + state;
    result += '\n';
  }
  result += 'Commodity: ' + commodity;
  result += '\nNumber of head: ' + r.numberOfHead;
  result += '\nType: ' + commodityType;
  result += '\nTarget Weight (cwt): ' + r.targetWeight;
  result += '\nEndorsement Length: ' + r.endorsementLengthCount;
  result += '\n-----------------------------';
  if (policy) {
    result += '\nEffective Date: ' + (r as LRPPolicy).effectiveDate;
  }
  result += '\nEnd Date: ' + r.endDate;
  result += '\nExpected Market Price: ' + currencyFormatter(r.expectedMarketPrice, 'en-US', '$', 'USD');
  result += '\nCoverage Level: ' + r.coverageLevel;
  result += '\nCoverage Price: ' + currencyFormatter(r.coveragePrice, 'en-US', '$', 'USD');
  result += '\nRate: ' + r.rate;
  result += '\nSubsidy: ' + currencyFormatter(r.subsidy, 'en-US', '$', 'USD');
  result += '\nInsured Value (Liability): ' + currencyFormatter(r.insuredValue, 'en-US', '$', 'USD');
  result += '\n-----------------------------';
  result += '\nProducer Premium: ' + currencyFormatter(r.producerPremium, 'en-US', '$', 'USD');
  result += '\nProducer Cost/CWT: ' + currencyFormatter(r.costPerCWT, 'en-US', '$', 'USD');
  result += '\nProducer Cost/Hd: ' + currencyFormatter(r.costPerHead, 'en-US', '$', 'USD');
  return result;
}


export interface LRPWatchListQuote {
  id: number;
  stateFipsCode?: string;
  commodityCode: string;
  triggerPrice: number;
  typeCode?: string;
  endorsementLengthCounts: number[];
}

export interface LRPWatchListEndorsement {
  endorsementLengthCount: number;
  status: number;
  todayPrice: number;
  watchListQuoteItemId: number;
  userId: number;
}
