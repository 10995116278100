import { Injectable } from '@angular/core';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import { getCenter } from 'ol/extent';
import { defaults as defaultControls, ScaleLine } from 'ol/control';
import { fromLonLat, transform } from 'ol/proj';
import { Subject } from 'rxjs';
import { MapEvent, MapOptions } from '../../model';
import { MappingsService } from '../mappings.service';
import zIndexConfig from './layer-zindex-config';
import { LocationService } from './location-service';
import { MapDataService } from './map-data.service';
import { CommonConstants } from '../../app.constants';
import * as Cesium from 'cesium'
import { Ion } from 'cesium'
import OLCesium from 'olcs/OLCesium.js';
import { MapBubbleService } from "./map-bubble-service";

const DEFAULT_RESOLUTION = 1200;

@Injectable({
  providedIn: 'root'
})
export class MapOperationService {

  public map: OlMap;
  private ol3d: any;
  private threeDEnabled: boolean = false;
  targetId = 'map';

  protected satelliteLayer: any;
  protected labelsLayer: any;
  protected transportLayer: any;

  protected mapEventSubject = new Subject<MapEvent>();
  public events = this.mapEventSubject.asObservable();

  protected isFreezed = false;
  public maxZIndex = 0;

  protected mapOptions: MapOptions = {
    hidden: false,
    resolution: DEFAULT_RESOLUTION,
    centerPosition: this.locationService.defaultCenter
  };
  private currentLayerState: Map<string, boolean> = new Map();

  constructor(
    private mappingService: MappingsService,
    private locationService: LocationService,
    private mapDataService: MapDataService,
  ) {

  }

  initBaseLayers() {

    const tileUrlEnd = 'tile/{z}/{y}/{x}';

    this.satelliteLayer = new TileLayer({
      source: new XYZ({
        attributions: `Tiles © <a href="${CommonConstants.MAPPING.WORLD_IMAGE_URL}">ArcGIS</a>`,
        url: CommonConstants.MAPPING.WORLD_IMAGE_URL + tileUrlEnd,
        wrapX: true,
      })
    });
    this.satelliteLayer.layerName = 'satellite';
    this.satelliteLayer.baseUrl = CommonConstants.MAPPING.WORLD_IMAGE_URL + 'tile';
    this.satelliteLayer.setProperties({baseUrl: CommonConstants.MAPPING.WORLD_IMAGE_URL + 'tile'});

    this.labelsLayer = new TileLayer({
      source: new XYZ({
        attributions: `Tiles © <a href="${CommonConstants.MAPPING.WORLD_BOUNDARIES_PLACES}">ArcGIS</a>`,
        url: CommonConstants.MAPPING.WORLD_BOUNDARIES_PLACES + tileUrlEnd,
        wrapX: true,
      })
    });
    this.labelsLayer.layerName = 'places';
    this.labelsLayer.baseUrl = CommonConstants.MAPPING.WORLD_BOUNDARIES_PLACES + 'tile';

    this.transportLayer = new TileLayer({
      source: new XYZ({
        attributions: `Tiles © <a href="${CommonConstants.MAPPING.WORLD_TRANSPORTATION}">ArcGIS</a>`,
        url: CommonConstants.MAPPING.WORLD_TRANSPORTATION + tileUrlEnd,
        wrapX: true,
      })
    });
    this.transportLayer.layerName = 'transport';
    this.transportLayer.baseUrl = CommonConstants.MAPPING.WORLD_TRANSPORTATION + 'tile';
  }

  removeSatelliteTileLayers() {
    const tileLayers = [this.satelliteLayer, this.labelsLayer, this.transportLayer];
    tileLayers.forEach(layer => this.map.removeLayer(layer));
  }

  initMap(target = 'map') {
    Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1MjJkN2FmNi03N2QxLTQwNmMtYTFkNy02MzJjNDhhNDZlNmEiLCJpZCI6MjI1NzAyLCJpYXQiOjE3MTk4NDY4Mzd9.s6bftkpzB_s5elwULwtj0_mWTG730vO5M7AayLrmiTE';
    this.mapDataService.loadReferenceData();
    this.initBaseLayers();
    this.map = new OlMap({
      view: new OlView({
        center: [860434.6266531206, 6029479.0044273855],
        minResolution: 0,
        zoom: 6
      }),
      layers: [
        this.satelliteLayer,
        this.labelsLayer,
        this.transportLayer
      ],
      target: target,

      controls: defaultControls({
        attribution: false,
        zoom: false,
        rotate: false,
      })
    });

    this.map.addControl(new ScaleLine({units: 'us'}));

    this.map.once('postcompose', event => {
      this.mapEventSubject.next({
        name: 'post-compose',
        eventData: null,
        originalEvent: event
      });
    });

    const map = this.map;
    this.map.on('click', event => {
      let position = 0;
      let featureClick = false;
      if (!this.isFreezed) {
        map.forEachFeatureAtPixel(event.pixel, (feature, layer: any) => {
          if (layer) {
            featureClick = true;
            this.mapEventSubject.next({
              name: 'feature-clicked',
              eventData: {
                layerName: layer.layerName,
                selectedFeature: feature,
                position: position++
              },
              originalEvent: event
            });
          }
        });
      }
      const lonlat = transform(event.coordinate, 'EPSG:3857', 'EPSG:4326');
      this.mapEventSubject.next({
        name: 'click',
        eventData: {
          longitude: lonlat[0],
          latitude: lonlat[1],
          featureClick
        },
        originalEvent: event
      });
    });

    this.map.getView().on('change:resolution', event => {
      this.mapEventSubject.next({
        name: 'resolution-changed',
        eventData: this.map.getView().getResolution(),
        originalEvent: event
      });
    });

    this.map.on('pointerdrag', event => {
      this.mapEventSubject.next({
        name: 'pointer-drag',
        eventData: null,
        originalEvent: event
      });
    });

  }

  addLayer(layer: any, layerName: string) {
    layer.layerName = layerName;
    this.map.addLayer(layer);
    this.refreshLayerZIndexes();
  }

  getLayer(name: string) {
    return this.map.getLayers().getArray().find((l: any) => l.layerName === name);
  }

  public toggleThreeD() {
    if (!this.ol3d) {
      this.ol3d = new OLCesium({
        map: this.map
      }); // ol2dMap is the ol.Map instance
    }
    this.threeDEnabled = !this.threeDEnabled;
    let scene = this.ol3d.getCesiumScene();
    Cesium.createWorldTerrainAsync({
      requestWaterMask: false,
      requestVertexNormals: false,
    }).then(tp => scene.terrainProvider = tp);
    scene.globe.depthTestAgainstTerrain = true;
    this.ol3d.setEnabled(this.threeDEnabled);
  }

  public getAllNonBaseLayers() {
    const baseLayers = ['satellite', 'places', 'transport'];
    let layers = this.map.getLayers().getArray().filter((layer: any) => {
      if (baseLayers.indexOf(layer.layerName) < 0) {
        return true;
      }
    });
    return layers;
  }

  public hideAllLayer() {
    let layers = this.getAllNonBaseLayers();
    layers.forEach((layer: any) => {
      layer.setVisible(false);
    })
  }

  public persistCurrentLayerState() {
    let layers = this.map.getLayers().getArray();
    layers.forEach((layer: any) => {
      const layerName = layer.layerName;
      const visible = layer.getVisible();
      this.currentLayerState.set(layerName, visible);
    })
  }

  public restorePersistedLayerState() {
    let layers = this.map.getLayers().getArray();
    layers.forEach((layer: any) => {
      const layerName = layer.layerName;
      let visible = this.currentLayerState.get(layerName);
      layer.setVisible(visible);
    })
  }

  refreshLayerZIndexes() {
    this.map.getLayers().getArray().forEach((layer: any) => {
      const zIndex = zIndexConfig[layer.layerName];
      if (zIndex) {
        layer.setZIndex(zIndex);
      }
    });
  }

  fireEvent(name, eventData, originalEvent = null) {
    this.mapEventSubject.next({
      name,
      eventData,
      originalEvent
    });
  }

  zoomTo(resolution, duration = 1000) {
    const zoom = this.resolutionToZoomLevel(resolution);
    this.map.getView().animate({
      zoom,
      duration
    });
  }

  zoomToMax(resolution, duration = 1000) {
    const zoom = this.resolutionToZoomLevel(resolution);
    if (zoom > this.map.getView().getZoom()) {
      this.zoomTo(resolution, duration);
    }
    this.showLoading(false);
  }

  zoomToCoords(coords, resolution, duration = 1000) {
    const zoom = this.resolutionToZoomLevel(resolution);
    this.map.getView().animate({
      zoom,
      duration,
      center: coords
    });
    this.showLoading(false);
  }

  zoomToExtent(extent, zoom, duration = 1000) {
    const center = getCenter(extent.map(n => +n));
    this.map.getView().animate({
      zoom,
      duration,
      center: center
    });
    this.showLoading(false);
  }

  zoomToLocation(lat, lng, resolution, duration = 1000) {
    const zoom = this.resolutionToZoomLevel(resolution);
    let coords = fromLonLat([lng, lat]);
    console.log('Zoom to location: ');
    console.log(coords);
    this.map.getView().animate({
      zoom,
      duration,
      center: coords
    });
    this.showLoading(false);
  }

  centerToCoords(coords, duration = 500) {
    this.map.getView().animate({
      duration,
      center: coords
    });
    this.showLoading(false);
  }

  showLoading(loading: boolean) {
    this.setContainerClass('loading-map', loading);
  }

  updateSize = () => {
    this.map?.updateSize();
  }

  resolutionToZoomLevel(resolution): number {
    return this.mappingService.getDefaultZoom(resolution);
  }

  getCurrentResolution() {
    return this.map.getView().getResolution();
  }

  freezeMap(freeze: boolean) {
    setTimeout(() => this.isFreezed = freeze, 100);
  }

  setContainerClass(className, add) {
    const map = document.getElementById(this.targetId);
    if (map) {
      const classes = map.parentElement.className.replace(className, '');
      map.parentElement.className = classes;
      if (add) {
        map.parentElement.className = classes + ' ' + className;
      }
    }
  }

  showMap(show: boolean) {
    console.log("showMap called with: " + show);
    this.setContainerClass('hidden-map', !show);
    if (show) {
      setTimeout(this.updateSize, 300);
    }
  }

  desconstructMap() {
    this.map.setTarget(null);
    this.map = null;
  }
}
