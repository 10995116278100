import { Injectable } from '@angular/core';
import { CommonConstants } from "../app.constants";

@Injectable()
export class CommonService {

  constructor() {
  }

  public sharedMethod() {
    console.log('sharedMethod called!')
  }

  public validateEmail(email: string) {
    if (!email || email.length === 0) {
      return true;
    }
    const matchEmailRegexp: boolean = CommonConstants.emailRegexp.test(email);
    if (matchEmailRegexp) {
      return true;
    } else {
      return false;
    }
  }

  public validateUserTypedSomething(texts: string[]) {
    let result = false;
    texts.forEach(t => {
      if (!!t && t.length > 0) {
        result = true;
      }
    });
    return result;
  }
}
