import { LivestockActionTypes } from "./livestock.actions";
import { ActionWithPayload } from "../types";
import {
  Animal, AnimalBreed,
  AnimalSpecies,
  AnimalStatus,
  AnimalType,
  LivestockListItem,
  Lot,
  Pasture,
  TagColor
} from "../../model/LivestockModel";
import { Boundary } from "../../model/MappingsModel";
import { Action } from "@ngrx/store";


export interface LivestockState {
  animals: Animal[],
  pastures: Pasture[],
  lots: Lot[],
  animalStatuses: AnimalStatus[],
  animalTypes: AnimalType[],
  animalSpecies: AnimalSpecies[],
  animalBreeds: AnimalBreed[],
  birthResults: LivestockListItem[],
  birthMethods: LivestockListItem[],
  calvingEases: LivestockListItem[],
  tagColors: TagColor[],
  selectedPasture: number | string,
  selectedAnimal: number | string,
  selectedLot: number | string,
  // expenseCategories: any[],
  boundariesForRanches: Map<number, Boundary[]>,
  chartView: boolean,
}

export const livestockInitialState: LivestockState = {
  animals: [],
  pastures: [],
  lots: [],
  animalTypes: [],
  animalSpecies: [],
  animalBreeds: [],
  animalStatuses: [],
  birthResults: [],
  birthMethods: [],
  calvingEases: [],
  tagColors: [],
  selectedPasture: null,
  selectedAnimal: null,
  selectedLot: null,
  // expenseCategories: [],
  boundariesForRanches: new Map(),
  chartView: false,
}

export function reducer(state = livestockInitialState, action: Action): LivestockState {
  const payload = (action as ActionWithPayload<any>).payload;
  let updatedAnimal;
  let updatedAnimals;
  let updatedLots;
  switch (action.type) {
    case LivestockActionTypes.LOAD_ALL_ANIMALS_SUCCESS:
      return {
        ...state,
        animals: payload
      };

    case LivestockActionTypes.LOAD_PASTURES_SUCCESS:
      return {
        ...state,
        pastures: payload
      };

    case LivestockActionTypes.ADD_NEW_ANIMAL_SUCCESS:
      if (payload) {
        const animals = state.animals.concat(payload);
        if (state.selectedLot) {
          let lots = state.lots.map(l => {
            let lot = {...l};
            if (lot.id == state.selectedLot) {
              const newAnimalIds = payload.map(a => a.id);
              if (!lot.animalIds) {
                lot.animalIds = [];
              }
              lot.animalIds = lot.animalIds.concat(newAnimalIds);
            }
            return lot;
          })
          return {
            ...state,
            animals,
            lots
          };
        }
        return {...state, animals};
      }
      return {...state};

    case LivestockActionTypes.CREATE_NEW_BIRTHRECORD_SUCCESS:
      if (payload) {
        let linkedCalf = state.animals.find(a => a.id == payload.id);
        if (linkedCalf) {
          updatedAnimals = state.animals.map(animal => {
            if (animal.id == payload.id) {
              return payload;
            }
            return animal;
          });
          return {...state, animals: updatedAnimals};
        }
        const animals = [...state.animals, payload];
        return {...state, animals, selectedAnimal: payload.id};
      }
      return {...state};

    case LivestockActionTypes.UPDATE_ANIMAL_SUCCESS:
      if (payload) {
        updatedAnimals = state.animals.map(animal => {
          updatedAnimal = payload.find(a => a.id == animal.id)
          if (updatedAnimal) {
            return updatedAnimal;
          }
          return animal;
        });
        return {...state, animals: updatedAnimals};
      }
      return {...state};
    case LivestockActionTypes.LOAD_ANIMAL_BY_ID_SUCCESS:
      const setSelected = payload.setSelected;
      updatedAnimal = payload.animal;
      let existingAnimal = false;
      updatedAnimals = state.animals.map(animal => {
        if (animal.id == updatedAnimal.id) {
          existingAnimal = true;
          return updatedAnimal;
        }
        return animal;
      });
      //there can be cases  when an animal is not yet loaded (eg from different racnh). in that case insert the animal
      if (!existingAnimal && !!updatedAnimal.id && updatedAnimal.id >= 0) {
        updatedAnimals.push((updatedAnimal));
      }
      updatedLots = state.lots;
      if (!!updatedAnimal.lotId) {
        updatedLots = state.lots.map(lot => {
          if (lot.id == updatedAnimal.lotId) {
            const found = !!lot.animalIds ? lot.animalIds.indexOf(updatedAnimal.id) > -1 : false;
            if (!found) {
              return {
                ...lot,
                animalIds: !!lot.animalIds ? lot.animalIds.concat([updatedAnimal.id]) : [updatedAnimal.id]
              };
            }
          }
          return lot;
        })
      }
      if (setSelected) {
        return {
          ...state,
          animals: updatedAnimals,
          selectedAnimal: !!updatedAnimal.id ? updatedAnimal.id : updatedAnimal,
          lots: updatedLots
        };
      } else {
        return {
          ...state,
          animals: updatedAnimals,
          lots: updatedLots
        };
      }
    case LivestockActionTypes.UPDATE_BIRTHRECORD_SUCCESS:
      updatedAnimal = payload;
      updatedAnimals = state.animals.map(animal => {
        if (animal.id == updatedAnimal.id) {
          return updatedAnimal;
        }
        return animal;
      });
      return {
        ...state,
        animals: updatedAnimals,
        selectedAnimal: updatedAnimal,
      };
    case LivestockActionTypes.DELETE_ANIMAL_SUCCESS:
      const removedAnimalIds: number[] = payload;
      const animals = state.animals.filter(a => !removedAnimalIds.includes(a.id));
      return {
        ...state,
        animals: animals,
      };
    case LivestockActionTypes.LOAD_ALL_LOTS_SUCCESS:
      return {
        ...state,
        lots: payload
      };

    case LivestockActionTypes.CREATE_NEW_LOT_SUCCESS:
      if (payload) {
        const lots = [...state.lots, payload];
        return {...state, lots, selectedLot: !!payload.id ? payload.id : payload};
      }
      return {...state};

    case LivestockActionTypes.UPDATE_LOT_SUCCESS:
      const updatedLot = payload;
      updatedLots = state.lots.map(lot => {
        if (lot.id == updatedLot.id) {
          return updatedLot;
        }
        return lot;
      })
      return {
        ...state,
        lots: updatedLots,
        selectedLot: updatedLot
      };
    case LivestockActionTypes.DELETE_LOT_SUCCESS:
      const removedLotId = payload;
      const lots = state.lots.filter(l => l.id != removedLotId);
      return {
        ...state,
        lots: lots,
      };

    case LivestockActionTypes.LOAD_ANIMAL_TYPES_SUCCESS:
      return {
        ...state,
        animalTypes: payload
      };
    case LivestockActionTypes.LOAD_ANIMAL_SPECIES_SUCCESS:
      return {
        ...state,
        animalSpecies: payload
      };
    case LivestockActionTypes.LOAD_ANIMAL_BREEDS_SUCCESS:
      return {
        ...state,
        animalBreeds: payload
      };
    case LivestockActionTypes.LOAD_ANIMAL_STATUSES_SUCCESS:
      return {
        ...state,
        animalStatuses: payload
      };
    case LivestockActionTypes.LOAD_BIRTH_RESULTS_SUCCESS:
      return {
        ...state,
        birthResults: payload
      };
    case LivestockActionTypes.LOAD_BIRTH_METHODS_SUCCESS:
      return {
        ...state,
        birthMethods: payload
      };
    case LivestockActionTypes.LOAD_CALVING_EASE_SUCCESS:
      return {
        ...state,
        calvingEases: payload
      };
    case LivestockActionTypes.LOAD_TAG_COLORS_SUCCESS:
      return {
        ...state,
        tagColors: payload
      };
    // case LivestockActionTypes.LOAD_EXPENSE_CATEGORIES_SUCCESS:
    //   return {
    //     ...state,
    //     expenseCategories: payload
    //   };
    case LivestockActionTypes.UPDATE_SELECTED_PASTURE:
      if (!!payload) {
        const tasks = (action as ActionWithPayload<any>).payload.tasks;
        const selectedPastureId = (action as ActionWithPayload<any>).payload.id;
        const animalCount = (state.animals.filter(a => a.locationId == selectedPastureId && !a.sold && !a.dead)).length
        let allPastures: Pasture[] = [];

        if (!!state.pastures && state.pastures.length) {
          allPastures = [...state.pastures];
        }
        let pasture = allPastures.find(p => p.id == selectedPastureId);
        if (!pasture) {
          allPastures.push({
            id: selectedPastureId,
            tasks: tasks,
            animalCount: animalCount,
          })
        } else {
          allPastures = allPastures.map(p => {
            let pasture = {...p};
            if (pasture.id == selectedPastureId) {
              pasture.tasks = tasks;
              pasture.animalCount = animalCount;
            }
            return pasture;
          });
        }

        return {
          ...state,
          selectedPasture: selectedPastureId,
          pastures: allPastures
        };
      } else {
        return {
          ...state,
          selectedPasture: null,
        };
      }
    case LivestockActionTypes.SET_SELECTED_LOT:
      return {
        ...state,
        selectedLot: payload
      };
    case LivestockActionTypes.SET_SELECTED_ANIMAL:
      return {
        ...state,
        selectedAnimal: payload
      };

    case LivestockActionTypes.LOAD_AVAILABLE_MOVE_BOUNDARIES_SUCCESS:
      return {
        ...state,
        boundariesForRanches: payload
      };
    case LivestockActionTypes.SET_CHART_VIEW:
      return {
        ...state,
        chartView: payload
      };
    default:
      return state;
  }
}
