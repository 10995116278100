
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { CommonState } from "../store/reducer";
import { CurrentState } from "../store/current-state";
import { ENVIRONMENT_TOKEN } from "../../environment/token-variables";



@Injectable()
export class TutorialsService {
  constructor(private currentState: CurrentState<CommonState>,
              @Inject(ENVIRONMENT_TOKEN) private readonly environment,
              protected http: HttpClient,
  ) {

  }

  getTasksTutorial() {
    const path = '/private/tutorials';
    const token = this.currentState.snapshot.user.token;
    const url = this.environment.backend + path;
    let headers = new HttpHeaders();

    headers = headers.append('Authorization', token);

    const options = {
      headers: headers,
    };

    // this.ga.trackServiceCall('getTasks', ApplicationConstants.GA.LABEL.USER_CRUD);
    return this.http.get(url, options);
  }


}

