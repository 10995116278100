import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CommonState } from '../store/reducer';
import { CurrentState } from '../store/current-state';
import { ServicesCommon } from './services-common.service';
import {
  Boundary,
  BoundaryCreateRequest,
  CLUServiceRequest,
  MapEvent,
  MapOptions,
  MappingRequest,
  UserMarker,
  UserMarkerType
} from '../model';
import { ENVIRONMENT_TOKEN } from '../../environment/token-variables';


const radiusPercentages = [25, 50, 75, 100];
const colors = ['#05182b', '#2b8de9', '#b5d7f7', 'white'];
const opacity = .3;

@Injectable()
export class MappingsService {

  constructor(
    private currentState: CurrentState<CommonState>,
    protected http: HttpClient,
    private servicesCommon: ServicesCommon,
    @Inject(ENVIRONMENT_TOKEN) private readonly environment) {
  }

  getHXGNCredentials() {
    return this.servicesCommon.get<any>(
      `/private/mapping/hxgn/credentials`);
  }

  createMarker(request: MappingRequest) {
    const userId = this.currentState.snapshot.user.id;
    const taskId = request.relatedTaskId;
    return this.http.post<UserMarker>(
      this.environment.backend + `/private/mapping/user/${userId}/markers`,
      request,
      {headers: this.servicesCommon.getAuthHeaders()});
  }

  createBoundaryFromCLUInfo(request: CLUServiceRequest) {
    return this.http.post<Boundary[]>(
      this.environment.backend + `/private/mapping/clu/boundary`,
      request,
      {headers: this.servicesCommon.getAuthHeaders()});
  }

  mergePolygons(request: MappingRequest[]) {
    const userId = this.currentState.snapshot.user.id;
    return this.http.post<Boundary>(
      this.environment.backend + `/private/mapping/boundaries/user/${userId}/merge`,
      request,
      {headers: this.servicesCommon.getAuthHeaders()});
  }

  unMergeBoundary(request: MappingRequest) {
    const userId = this.currentState.snapshot.user.id;
    return this.http.post<Boundary[]>(
      this.environment.backend + `/private/mapping/boundaries/user/${userId}/unmerge`,
      request,
      {headers: this.servicesCommon.getAuthHeaders()});
  }

  updateMarker(request: MappingRequest) {
    const userId = this.currentState.snapshot.user.id;
    return this.http.put<UserMarker>(
      this.environment.backend + `/private/mapping/user/${userId}/markers/${request.markerId}`,
      request,
      {headers: this.servicesCommon.getAuthHeaders()});
  }

  getAllUserMarkers(userId: number | string = null) {
    return this.servicesCommon.getAll<UserMarker>(
      `/private/mapping/user/${userId}/markers`);
  }

  getUserMarker(markerId: number) {
    return this.servicesCommon.get<UserMarker>(
      `/private/mapping/markers/${markerId}`);
  }

  getMarkerTypes() {
    return this.servicesCommon.getAll<UserMarkerType>(
      `/private/mapping/markers/types`);
  }

  addTaskToMarker(taskId: number | string, markerId: number | string) {
    const userId = this.currentState.snapshot.user.id;
    return this.http.post<UserMarker>(
      this.environment.backend + `/private/mapping/user/${userId}/markers/${markerId}/task`,
      {relatedTaskId: taskId},
      {headers: this.servicesCommon.getAuthHeaders()});
  }

  removeTaskFromMarker(taskId: number | string, markerId: number | string) {
    const userId = this.currentState.snapshot.user.id;
    return this.http.delete<UserMarker>(
      this.environment.backend + `/private/mapping/user/${userId}/markers/${markerId}/task/${taskId}`,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  removeMarker(markerId: number | string) {
    return this.http.delete<number | string>(
      this.environment.backend + `/private/mapping/markers/${markerId}`,
      {headers: this.servicesCommon.getAuthHeaders()}
    );
  }

  getAllBoundaries(userId: number | string = null) {
    if (!userId) {
      userId = this.currentState.snapshot.user.id;
    }
    return this.servicesCommon.getAll<Boundary>(
      `/private/mapping/boundaries/user/${userId}`);
  }

  getBoundaries(boundaryIdsString: string, userId: number | string = null) {
    if (!userId) {
      userId = this.currentState.snapshot.user.id;
    }
    return this.servicesCommon.getAll<Boundary>(
      `/private/mapping/boundaries/user/${userId}/boundaries/${boundaryIdsString}`);
  }

  createNewBoundary(request: MappingRequest) {
    const userId = this.currentState.snapshot.user.id;
    return this.http.post<any>(
      this.environment.backend + `/private/mapping/boundaries/user/${userId}`,
      request,
      {headers: this.servicesCommon.getAuthHeaders()});
  }

  createNewBoundariesFromParent(request: BoundaryCreateRequest) {
    const userId = this.currentState.snapshot.user.id;
    return this.http.post<any>(
      this.environment.backend + `/private/mapping/boundaries/${request.parent.id}/user/${userId}`,
      request.boundaries,
      {headers: this.servicesCommon.getAuthHeaders()});
  }

  updateBoundary(request: MappingRequest) {
    const userId = this.currentState.snapshot.user.id;
    return this.http.put<any>(
      this.environment.backend + `/private/mapping/boundaries/${request.boundaryId}`,
      request,
      {headers: this.servicesCommon.getAuthHeaders()});
  }

  deleteBoundary(boundaryId: string | number) {
    if (!!boundaryId) {
      const userId = this.currentState.snapshot.user.id;
      return this.http.delete<any>(
        this.environment.backend + `/private/mapping/boundaries/${boundaryId}`,
        {headers: this.servicesCommon.getAuthHeaders()});
    }
  }


  convertCLUBoundaryToBoundary(cluBoundary: Boundary) {
    const userId = this.currentState.snapshot.user.id;
    return this.http.post<any>(
      this.environment.backend + `/private/mapping/clu/boundary/${cluBoundary.id}/convert/boundary`, cluBoundary, {
        headers: this.servicesCommon.getAuthHeaders()
      }
    );
  }

  getRadius(index) {
    const width = this.getStrokeWidth(index);
    return radiusPercentages[index] - width / 2;
  }

  getStrokeWidth(index) {
    return radiusPercentages[index] - (index === 0 ? 0 : radiusPercentages[index - 1]);
  }

  getRingTextX(idx: number) {
    return 200 - this.getRadius(idx) + this.getStrokeWidth(idx) / 2 - 4;
  }


  getGrazingRings = (ringIncrement = null) => `<svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg"
    viewBox="0,0,200,200"
      preserveAspectRatio="xMidYMid meet" width="200" height="200">
    <circle cx="100" cy="100" r="${this.getRadius(0)}" style="fill:none;
    stroke-width:${this.getStrokeWidth(0)}; stroke:${colors[0]}; stroke-opacity:${opacity}" />
    <circle cx="100" cy="100" r="${this.getRadius(1)}" style="fill:none;
    stroke-width:${this.getStrokeWidth(1)}; stroke:${colors[1]}; stroke-opacity:${opacity}" />
    <circle cx="100" cy="100" r="${this.getRadius(2)}" style="fill:none;
    stroke-width:${this.getStrokeWidth(2)}; stroke:${colors[2]}; stroke-opacity:${opacity}" />
    <circle cx="100" cy="100" r="${this.getRadius(3)}" style="fill:none;
    stroke-width:${this.getStrokeWidth(3)}; stroke:${colors[3]}; stroke-opacity:${opacity}" />
    ${ringIncrement ? `
    <text x="${this.getRingTextX(0)}" y="100" font-family="Roboto, sans-serif"
    font-size="6" text-anchor="end" fill="white">${radiusPercentages[3] / 100 * (ringIncrement * 4)}</text>
    <text x="${this.getRingTextX(1)}" y="100" font-family="Roboto, sans-serif"
    font-size="6" text-anchor="end" fill="white">${radiusPercentages[2] / 100 * (ringIncrement * 4)}</text>
    <text x="${this.getRingTextX(2)}" y="100" font-family="Roboto, sans-serif"
    font-size="6" text-anchor="end" fill="white">${radiusPercentages[1] / 100 * (ringIncrement * 4)}</text>
    <text x="${this.getRingTextX(3)}" y="100" font-family="Roboto, sans-serif"
    font-size="6" text-anchor="end" fill="white">${radiusPercentages[0] / 100 * (ringIncrement * 4)}</text>` : ''}
    </svg>`

  // getUserOfflineMaps() {
  //   const userId = this.currentState.snapshot.user.id;
  //   return this.servicesCommon.getAll<OfflineMap>(
  //     `/private/mapping/cache/map/user/${userId}`);
  // }

  // addOfflineMap(offlineMap: OfflineMap) {
  //   return this.http.post<number>(
  //     this.environment.backend + `/private/mapping/cache/map`,
  //     offlineMap,
  //     { headers: this.servicesCommon.getAuthHeaders() });
  // }

  // deleteOfflineMap(offlineMapId: number) {
  //   return this.http.delete<number>(
  //     this.environment.backend + `/private/mapping/cache/map/${offlineMapId}`,
  //     { headers: this.servicesCommon.getAuthHeaders() });
  // }

  getDefaultZoom(resolution): number {
    return Math.ceil((Math.log(resolution) - Math.log(156543.03390625)) / Math.log(0.5))
  }


}

