import { Injectable } from '@angular/core';
import Overlay from 'ol/Overlay';
import { MapOperationService } from './map-operation.service';
import { Feature } from 'ol';
import { BubbleConfig } from '../../model';


@Injectable({
  providedIn: 'root'
})
export class MapBubbleService {

  private maxZIndex = 300;
  private readonly defaultConfig: BubbleConfig = {
    align: 'center'
  };

  constructor(private mapOperationService: MapOperationService) {
  }

  bubbleContent = (contentHtml: string) => {
    return `<a id="popup-closer" class="ol-popup-closer"></a>
        <div class="popup-content">${contentHtml}</div>
        `;
  }


  getCenter(extent: number[]) {
    const x = extent[0] + (extent[2] - extent[0]) / 2;
    const y = extent[1] + (extent[3] - extent[1]) / 2;
    return [x, y];
  }

  getTop(extent: number[]) {
    const x = extent[0] + (extent[2] - extent[0]) / 2;
    return [x, extent[1]];
  }

  hide(feature) {
    if (feature.bubbleOverlay) {
      feature.bubbleOverlay.setPosition(undefined);
    } else if (feature.getProperties().bubbleOverlay) {
      feature.getProperties().bubbleOverlay.setPosition(undefined);
    }
  }

  hideAll() {
    let layers = this.mapOperationService.getAllNonBaseLayers();
    layers.forEach((layer: any) => {
      if (!!layer.getSource()?.getFeatures && !!layer.getSource()?.getFeatures()) {
        layer.getSource().getFeatures().forEach(
          feature => {
            this.hide(feature);
          });
      }
    })
  }

  show(feature) {
    if (feature.bubbleOverlay) {
      feature.bubbleOverlay.setPosition(this.getCenter(feature.getGeometry().getExtent()));
    } else if (feature.getProperties().bubbleOverlay) {
      feature.getProperties().bubbleOverlay.setPosition(this.getCenter(feature.getGeometry().getExtent()));
    }
  }

  remove(feature: Feature) {

    let props = feature.getProperties();
    let bubbleOverlay = props.bubbleOverlay;

    if (bubbleOverlay && !!this.mapOperationService.map) {
      bubbleOverlay.setPosition(undefined);
      props.bubbleOverlay = bubbleOverlay;
      feature.setProperties(props);
      this.mapOperationService.map.removeOverlay(bubbleOverlay);
    }
  }

  popup(contentHtml: string, feature: Feature, layerName: string, config: BubbleConfig = {}) {
    const bubble = document.createElement('div');
    bubble.innerHTML = this.bubbleContent(contentHtml);
    bubble.className = 'ol-popup ' + (config.class ? config.class : '');
    bubble.style.zIndex = (++this.maxZIndex).toString();
    const id = Math.round(Math.random() * 100000);
    bubble.setAttribute('id', `bubble-${id}`);

    const overlay = new Overlay({
      element: bubble,
      autoPan: {
        animation: {
          duration: 250
        }
      },
    });
    this.mapOperationService.map.addOverlay(overlay);
    config = {...this.defaultConfig, ...config};
    const position = config.align === 'center' ? this.getCenter(feature.getGeometry().getExtent())
      : this.getTop(feature.getGeometry().getExtent());
    overlay.setPosition(position);
    let props = feature.getProperties();
    props.bubbleOverlay = overlay;
    feature.setProperties(props);

    setTimeout(() => { // prevent instant click on bubble
      bubble.onclick = event => {
        // console.log('MapBubbleService --> bubble-clicked happened');
        this.mapOperationService.fireEvent('bubble-clicked', {layerName, selectedFeature: feature}, event);
      }
    }, 500);
  }

}
