import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { AuthActionTypes, reducers as coreReducer } from 'common-lib'
import { PortalAppState, reducers as portalReducer } from "./store/reducer";
import { storeLogger } from 'ngrx-store-logger';

export function resetStoreOnLogout(wrappedReducer: ActionReducer<PortalAppState>) {
  return function (state, action) {
    if (AuthActionTypes.LOGOUT_SUCCESS === action.type) {
      let dev = state.dev;
      let newState = undefined;
      newState = {...newState, dev};
      state = newState;
    }
    return wrappedReducer(state, action);
  };
}

const prodEnv = false;
export const commonEnhancers = [resetStoreOnLogout];

// const devEnhancers = [logStore, devTools]; //letting it here as empty to demonstrate how we should add dev ench if needed
export function logStore(state) {
  return storeLogger({collapsed: true})(state);
}
export const devEnhancers = [logStore];


// export const devEnhancers = [];

// export const metaReducers: MetaReducer<MobileAppState>[] = environment.production ? commonEnhancers : [...devEnhancers, ...commonEnhancers];
export const metaReducers: MetaReducer<PortalAppState>[] = prodEnv ? commonEnhancers : [...devEnhancers, ...commonEnhancers];

export const reducers: ActionReducerMap<any> = {...coreReducer, ...portalReducer};

export const REDUCERS = new InjectionToken<ActionReducerMap<any, Action>>('Reducers');

export function getReducers() {
  return reducers;
}
