import { TutorialEntity } from '../../model';
import { ActionWithPayload } from '../types';
import { TutorialActions, TutorialActionTypes } from './tutorial.actions';


export interface TutorialState {
  tutorials: TutorialEntity[];
}


export const  tutorialsInitialState: TutorialState = {
  tutorials: []
};


export function reducer(state = tutorialsInitialState, action: TutorialActions): TutorialState {
  switch (action.type) {
    case TutorialActionTypes.LOAD_TUTORIALS_SUCCESS:
      return {
        ...state,
        tutorials: (action as ActionWithPayload<any>).payload,
      };
    default:
      return state;
  }
}
