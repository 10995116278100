import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Action } from "@ngrx/store";
import { CommonState } from "../reducer";
import { CommonFeBaseEffects } from "../common-fe-base.effects";
import { CurrentState } from "../current-state";

import { NotificationsService } from "../../services/notifications.service";
import { catchError, map, switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import {
  LoadSurveyQuestionsAction,
  SetSurveyCategoriesAction,
  SetSurveyQuestionAction,
  SurveyActionTypes
} from "./survey.actions";
import { SurveyService } from "../../services/survey.service";

@Injectable()
export class SurveyEffects extends CommonFeBaseEffects {

  loadSurveyQuestions: Observable<Action>;
  loadSurveyCategories: Observable<Action>;

  constructor(
    protected currentState: CurrentState<CommonState>,
    private surveyService: SurveyService,
    private actions: Actions, private notificationService: NotificationsService) {
    super();

    this.loadSurveyQuestions = createEffect(() => this.actions.pipe(
      ofType(SurveyActionTypes.LOAD_SURVEY_QUESTION),
      map((action: LoadSurveyQuestionsAction) => action.payload),
      switchMap((payload: {
        userId: number,
        ranchId: number
      }) => this.surveyService.getSurveyQuestions({userId: payload.userId, ranchId: payload.ranchId}).pipe(
        map(questions => new SetSurveyQuestionAction(questions)),
        catchError(this.handleHttpError)
      )),
    ));

    this.loadSurveyCategories = createEffect(() => this.actions.pipe(
      ofType(SurveyActionTypes.LOAD_SURVEY_CATEGORIES),
      switchMap((payload: number) => this.surveyService.getSurveyCategories(payload).pipe(
        map(categories => new SetSurveyCategoriesAction(categories)),
        catchError(this.handleHttpError)
      )),
    ));
  }
}
