import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { ENVIRONMENT_TOKEN, OFFLINE_CACHE_SERVICE } from "../../../environment/token-variables";
import { TaskAction, TaskCategory, TaskEntity, TaskStatus } from "../../model";
import { CacheInterface } from "../../services/CacheInterface";
import { TasksService } from "../../services/tasks.service";
import { CommonFeBaseEffects } from "../common-fe-base.effects";
import { CurrentState } from "../current-state";
import { CommonState } from "../reducer";
import {
  LoadAllTaskActionsAction,
  LoadAllTaskActionsFailedAction,
  LoadAllTaskActionsSuccessAction,
  LoadAllTaskCategoriesAction,
  LoadAllTaskCategoriesFailedAction,
  LoadAllTaskCategoriesSuccessAction,
  LoadAllTasksAction,
  LoadAllTasksFailedAction,
  LoadAllTasksNextPageAction,
  LoadAllTasksSuccessAction,
  LoadAllTaskStatusesAction,
  LoadAllTaskStatusesFailedAction,
  LoadAllTaskStatusesSuccessAction,
  LoadTaskDetailFailedAction,
  LoadTaskDetailSuccessAction,
  LoadUpcomingNextPageAction,
  LoadUpcomingTasksAction,
  LoadUpcomingTasksFailedAction,
  LoadUpcomingTasksSuccessAction,
  ReloadTaskAction,
  SelectTaskAction,
  SetTaskFilterAction,
  TasksActionTypes,
  UpdateAvailableAllTasksPageAction,
  UpdateAvailableUpcomingTasksPageAction,
  UpdateTaskSuccessAction
} from "./tasks.actions";


@Injectable()
export class TasksEffects extends CommonFeBaseEffects {

  loadAllTasksNextPage: Observable<Action>;

  loadAllUpcomingTasksNextPage: Observable<Action>;

  loadTaskDetail: Observable<Action>;

  loadAllCategories: Observable<Action>;

  loadAllActions: Observable<Action>;

  loadAllStatuses: Observable<Action>;

  constructor(protected currentState: CurrentState<CommonState>,
              private taskService: TasksService,
              private store: Store<CommonState>,
              @Inject(OFFLINE_CACHE_SERVICE) private readonly cacheService: CacheInterface,
              @Inject(ENVIRONMENT_TOKEN) private readonly environment,
              private actions: Actions) {
    super();

    this.loadAllTasksNextPage = createEffect(() => this.actions.pipe(ofType(TasksActionTypes.LOAD_ALL_TASKS,
        TasksActionTypes.SET_FILTER,
        TasksActionTypes.LOAD_ALL_TASKS_NEXT_PAGE)
      , map((action: LoadAllTasksAction | SetTaskFilterAction | LoadAllTasksNextPageAction) => {
        return action.payload;
      })
      , switchMap((payload) => {
        const currentAllTasks = this.store.select(state => {
          return state.tasks.allTasks.tasks
        });
        const getTasksObs = this.getTasks().pipe(
          catchError(error => {
            console.error('Error during loading allTasks', error);
            const key = 'allTasks';
            return new Observable<Action>((observer) => {
              observer.next(new LoadAllTasksFailedAction(error));
              observer.complete()
            });
            // }
          }));
        const complex = getTasksObs.pipe(withLatestFrom(currentAllTasks));
        return complex.pipe(map(([loadedData, currentTasks]) => {
          // console.log(' loadedData:');
          // console.log(loadedData);
          // console.log(' currentTasks:');
          // console.log(currentTasks);
          if (!loadedData || (loadedData as any).length < 1) {
            return new UpdateAvailableAllTasksPageAction(false);
          }
          const taskEntities = !!currentTasks ? currentTasks.concat(loadedData as any) : loadedData;
          return new LoadAllTasksSuccessAction(taskEntities);
        }));
      })));

    this.loadAllUpcomingTasksNextPage = createEffect(() => this.actions.pipe(ofType(
        TasksActionTypes.LOAD_UPCOMING_TASKS,
        TasksActionTypes.LOAD_UPCOMING_TASKS_NEXT_PAGE)
      , map((action: LoadUpcomingTasksAction | LoadUpcomingNextPageAction) => {
        return action.payload;
      })
      , switchMap(() => {
        const currentUpcomingTasks = this.store.select(state => {
          return state.tasks.upcomingTasks.tasks
        });
        return this.getUpcomingTasks().pipe(
          withLatestFrom(currentUpcomingTasks)
          , map(([newTasks, currentTasks]) => {
            if (!newTasks || (newTasks as any).length < 1) {
              return new UpdateAvailableUpcomingTasksPageAction(false);
            }
            const taskEntities = !!currentTasks ? currentTasks.concat(newTasks as any) : newTasks;
            return new LoadUpcomingTasksSuccessAction(taskEntities);
          }), catchError(error => {
            console.error('Error during loading upcomingTasks', error);
            return new Observable<Action>((observer) => {
              observer.next(new LoadUpcomingTasksFailedAction(error));
              observer.complete()
            });

          }))
      })));

    this.loadTaskDetail = createEffect(() => this.actions.pipe(ofType(
        TasksActionTypes.UPDATE_TASK_SUCCESS,
        TasksActionTypes.RELOAD_TASK
        // tasks.MobileAuthActionTypes.DELETE_TASK_ATTACHMENT_SUCCESS
      )
      , map((action: UpdateTaskSuccessAction | SelectTaskAction | ReloadTaskAction) => {
        if (action instanceof ReloadTaskAction) {
          return {task: action.payload, reload: true};
        }
        return {task: action.payload, reload: false};
      })
      , switchMap(((payload: { task: any, reload: boolean }) => {
        return this.getTaskDetail(payload.task, payload.reload).pipe(map((task: TaskEntity) => {
          return new LoadTaskDetailSuccessAction(task);
        }), catchError(error => {
          console.error('Error during loading task detail');
          console.error(error);
          return new Observable<Action>((observer) => {
            observer.next(new LoadTaskDetailFailedAction(error));
            observer.complete()
          });
        }))
      }))));

    this.loadAllCategories = createEffect(() => this.actions.pipe(ofType(TasksActionTypes.LOAD_ALL_TASK_CATEGORIES)
      , map((action: LoadAllTaskCategoriesAction) => {
        return action;
      })
      , switchMap(() => {
        return this.getAllCategories().pipe(map((categories_: TaskCategory[]) => {
          const sortedCategories = [].concat(categories_).sort((a, b) => {
            return a.title < b.title ? -1 : 1;
          });
          return new LoadAllTaskCategoriesSuccessAction(sortedCategories);
        }), catchError(error => {
          console.error('Error during loading task_categories', error);
          return new Observable<Action>((observer) => {
            observer.next(new LoadAllTaskCategoriesFailedAction(error));
            observer.complete()
          });
        }))
      })));

    this.loadAllActions = createEffect(() => this.actions.pipe(ofType(TasksActionTypes.LOAD_ALL_TASK_ACTIONS)
      , map((action: LoadAllTaskActionsAction) => {
        return action;
      })
      , switchMap(() => {
        return this.getAllActions().pipe(map((actions: TaskAction[]) => {
          return new LoadAllTaskActionsSuccessAction(actions);
        }), catchError(error => {
          console.error('Error during loading task_actions', error);
          return new Observable<Action>((observer) => {
            observer.next(new LoadAllTaskActionsFailedAction(error));
            observer.complete()
          });
        }))
      })));

    this.loadAllStatuses = createEffect(() => this.actions.pipe(ofType(TasksActionTypes.LOAD_ALL_TASK_STATUSES)
      , map((action: LoadAllTaskStatusesAction) => {
        return action;
      })
      , switchMap(() => {
        return this.getAllStatuses().pipe(map((statuses: TaskStatus[]) => {
          return new LoadAllTaskStatusesSuccessAction(statuses);
        }), catchError(error => {
          console.error('Error during loading task_statuses', error);
          return new Observable<Action>((observer) => {
            observer.next(new LoadAllTaskStatusesFailedAction(error));
            observer.complete()
          });
        }))
      })));

  }

  getTasks() {
    return this.taskService.getTasks();
  }

  getUpcomingTasks() {
    return this.taskService.getUpcomingTasks();
  }

  getTaskDetail(taskId, reload = false) {
    let foundTask;
    if (!reload) {
      if (!!this.currentState.snapshot.tasks.allTasks.tasks) {
        foundTask = this.currentState.snapshot.tasks.allTasks.tasks.filter(t => t.id == taskId);
      }
      if ((!foundTask || foundTask.length < 1) && !!this.currentState.snapshot.tasks.upcomingTasks.tasks) {
        foundTask = this.currentState.snapshot.tasks.upcomingTasks.tasks.filter(t => t.id == taskId);
      }
      if (!foundTask || foundTask.length < 1) {
        return this.taskService.getTaskById(taskId);
      }
    } else {
      return this.taskService.getTaskById(taskId);
    }

    return of(foundTask[0]);
  }

  getAllCategories() {
    if (this.currentState.snapshot.tasks.allCategories != null && this.currentState.snapshot.tasks.allCategories.length > 0) {
      // console.log('TaskEffects#getAllCategories returns values from store currentstate');
      let cachedEntriesObs = new Observable<any>((observer) => {
        observer.next(this.currentState.snapshot.tasks.allCategories);
        observer.complete()
      });
      return cachedEntriesObs;
    }
    // console.log('TaskEffects#getAllCategories returns values from service');
    return this.taskService.getAllCategories();
  }

  getAllActions() {
    if (this.currentState.snapshot.tasks.allActions != null && this.currentState.snapshot.tasks.allActions.length > 0) {
      // console.log('TaskEffects#getAllActions returns values from store currentstate');
      let cachedEntriesObs = new Observable<any>((observer) => {
        observer.next(this.currentState.snapshot.tasks.allActions);
        observer.complete()
      });
      return cachedEntriesObs;
    }
    // console.log('TaskEffects#getAllActions returns values from service');
    return this.taskService.getAllActions();
  }

  getAllStatuses() {
    if (this.currentState.snapshot.tasks.allStatuses != null && this.currentState.snapshot.tasks.allStatuses.length > 0) {
      // console.log('TaskEffects#getAllStatuses returns values from store currentstate');
      let cachedEntriesObs = new Observable<any>((observer) => {
        observer.next(this.currentState.snapshot.tasks.allStatuses);
        observer.complete()
      });
      return cachedEntriesObs;
    }
    // console.log('TaskEffects#getAllStatuses returns values from service');
    return this.taskService.getAllStatuses();
  }

  deleteTaskAttachment(id) {
    return this.taskService.deleteAttachment(id);
  }
}
