import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usdCurrency'
})
export class USDCurrencyPipe implements PipeTransform {

  transform(value: number | string, decimal: number = 0): string {
    let convertedValue = +value;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: decimal,
    });
    return formatter.format(convertedValue);
  }
}

